import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import CommentsSection from './CommentsSection'

// TODO:
// 1. Enter to submit comment
// 2. mouseenter textarea remove keydown listener for photo gallery nav
const DescriptionPanel = ({ title, refId }) => {
    return (
        <div className={`pt-4 pb-12 px-6 lg:ml-2 leading-loose bg-neutral-800
            lg:min-w-[400px] lg:max-w-[500px] lg:max-h-[600px] overflow-y-auto
            text-white shadow-lg`}>
            <p className="capitalize text-xl pb-3 border-b border-b-white text-ellipsis overflow-hidden select-none">{title}</p>
            <p className="serif mt-4 select-none">{ Translations.get('gallery.description') }</p>
            <CommentsSection refId={refId} />
        </div>
    )
}

DescriptionPanel.propTypes = {
    title: PropTypes.string.isRequired,
    refId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(DescriptionPanel)
