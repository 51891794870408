import ENV from '../config'
import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import freeze from 'redux-freeze'
import rootReducer from './reducers'
import _ from 'lodash'

const logger = createLogger()
const commonMiddlewares = [thunk, freeze]

const middlewares = ENV.DEBUG_MODE
    ? _.compact([...commonMiddlewares, logger])
    : _.compact(commonMiddlewares)
const store = createStore(rootReducer, applyMiddleware(...middlewares))

export default store
