import config from '../config'

const RegentHotelGoogleMap = () => (
    config.LOAD_GOOGLE_MAP
        ?
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2149.1228619376843!2d121.52398263203438!3d25.055126099356034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a968f68729e7%3A0x6e3f6d2374968eaa!2sRegent%20Taipei!5e0!3m2!1sen!2stw!4v1654607061913!5m2!1sen!2stw"
            className="w-full h-full border-0"
            allowFullScreen=""
            loading="lazy"
            title="Regent Hotel Taipei"
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        :
        <div className="w-full h-full h-full bg-white flex justify-center items-center">
            <p className="text-primary">Google Map Placeholder</p>
        </div>
)

export default RegentHotelGoogleMap
