import { RsvpDataPropType } from './ducks/prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const RsvpDetails = ({ rsvpData }) => (
    <div>
        <p className="mb-2">
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.name') }:
            </span> { rsvpData.data.name }
        </p>
        <p className="mb-2">
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.email') }:
            </span> { rsvpData.data.email }
        </p>
        <p className="mb-2">
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.contact_number') }:
            </span> { rsvpData.data.contactNumber }
        </p>
        <p className="mb-2">
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.is_attending') }:
            </span>
            { (rsvpData.data.isAttending &&
                Translations.get('attendees_page.rsvp_history.historic.labels.is_attending.yes')) ||
                Translations.get('attendees_page.rsvp_history.historic.labels.is_attending.no')
            }
        </p>
        <p className="mb-2">
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.can_edit_rsvp') }:
            </span>
            { (rsvpData.data.canEditRsvp &&
                Translations.get('attendees_page.rsvp_history.historic.labels.can_edit_rsvp.yes')) ||
                Translations.get('attendees_page.rsvp_history.historic.labels.can_edit_rsvp.no')
            }
        </p>
        <div>
            <span className="font-bold text-secondary inline-block w-40">
                { Translations.get('attendees_page.rsvp_history.historic.labels.additional_guests') }:
            </span>
            <div className="inline-block align-top">
                {
                    rsvpData.data.additionalGuests.map((a, i) =>
                        <div key={i}>
                            <p>{i + 1}. {a.name}{a.childSeatRequired &&
                                Translations.get('attendees_page.rsvp_history.historic.additional_guest.requires_child_seat')}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
)

RsvpDetails.propTypes = {
    rsvpData: RsvpDataPropType.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(RsvpDetails)
