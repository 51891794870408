import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfirmationModal from '../modals/ConfirmationModal'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { selectDeleteComment, selectCommentIsDeleting, selectDeleteCommentErrors,
    selectDeleteCommentUncaughtError } from './ducks/selectors'
import { cancelDeleteConfirmation, confirmDeleteComment } from './ducks/actions'

// TODO: ADD ERROR DISPLAYING HERE
// NOT in COMMENT.jsx

const DeleteCommentConfirmation = ({ comment, onConfirm, onCancel, isDeleting,
    errors, uncaughtError }) => (
    <ConfirmationModal isProcessing={ isDeleting } onCancel={ onCancel } onConfirm={ onConfirm }>
        <p className="mb-4 text-lg">
            { Translations.get('on_the_day.users.comments.delete_confirmation') }
        </p>
        <div className="text-black">
            <p>{ comment && comment.commenterName }</p>
            <p>"{ comment && comment.text }"</p>
        </div>
        {
            uncaughtError &&
            <p className="text-red-500">{ uncaughtError.message }</p>
        }
        {
            !_.isEmpty(errors) &&
                Object.keys(errors).map(k =>
                    errors[k].map(v =>
                        <p key={`${k}.${v}`} className="text-red-600 text-center">
                            { Translations.get(`on_the_day.users.comments_section.comments.delete_errors.${k}.${v.error}`) }
                        </p>
                )
            )
        }
    </ConfirmationModal>
)

DeleteCommentConfirmation.propTypes = {
    comment: PropTypes.shape({
        id: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
        commenterName: PropTypes.string.isRequired,
        commenterOnceOffId: PropTypes.string.isRequired,
    }).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    uncaughtError: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state),
    comment: selectDeleteComment(state),
    isDeleting: selectCommentIsDeleting(state),
    errors: selectDeleteCommentErrors(state),
    uncaughtError: selectDeleteCommentUncaughtError(state)
})

const mapDispatchToProps = (dispatch) => ({
    onConfirm: () => { dispatch(confirmDeleteComment()) },
    onCancel: () => { dispatch(cancelDeleteConfirmation()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCommentConfirmation)
