/**
* Credits to https://codepen.io/nfj525/pen/rVBaab
*/

// ios cannot run AudioContext
const isIos = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

let audioNode
let canvasNode

export const setAudioNode = (node) => audioNode = node

export const setCanvasNode = (node) => canvasNode = node

export const unsetAudioNode = () => {
    audioNode = undefined
}

export const unsetCanvasNode = () => {
    canvasNode = undefined
}

export const draw = () => {
    return new Promise((rs, rj) => {
        // skip already connected audio nodes
        if (!(audioNode && canvasNode) || isIos())
            return

        const context = new AudioContext()
        const src = context.createMediaElementSource(audioNode)
        const analyser = context.createAnalyser()

        canvasNode.width = window.innerWidth
        canvasNode.height = window.innerHeight
        const ctx = canvasNode.getContext("2d")

        src.connect(analyser)
        analyser.connect(context.destination)

        analyser.fftSize = 256

        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        let WIDTH = canvasNode.width
        let HEIGHT = canvasNode.height

        const barWidth = (WIDTH / bufferLength) * 2 //2.5
        const minBarWidth = 100
        let barHeight
        let x = 0

        function renderFrame() {
            requestAnimationFrame(renderFrame)
            x = 0
            analyser.getByteFrequencyData(dataArray)

            ctx.fillStyle = "rgba(255,255,255,0.01)";
            ctx.fillRect(0, 0, WIDTH, HEIGHT)

            for (let i = 0; i < bufferLength; i++) {
                barHeight = minBarWidth + dataArray[i] * 2.5

                let r = 183 + (1 * (i / bufferLength)) // + barHeight
                let g = 153 // * (i / bufferLength)
                let b = 100

                ctx.fillStyle = `rgb(${r},${g},${b})`
                ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight)

                x += barWidth + 1
            }
        }

        renderFrame()
    })
    .catch(console.error)
}
