import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProjectedComment from './ProjectedComment'
import { selectComments, selectCurrentImage, selectPlaylistIsVisible } from './ducks/selectors'
import { fetchComments } from './ducks/actions'
import NowPlaying from './NowPlaying'

// poll for new comments
const pollingInterval = 3500

const ProjectedCommentsContainer = ({ currentImage, comments, onFetch, playlistIsVisible }) => {
    let [lastRefId, setLastRefId] = useState(null)

    // only update when changing to another image
    if (lastRefId !== currentImage.refId) {
        setLastRefId(currentImage.refId)
        onFetch()
    }

    useEffect(() => {
        let poller = setInterval(onFetch, pollingInterval)

        return () => {
            clearInterval(poller)
            poller = null
        }
    })

    const selectedComments = comments[currentImage.refId] || []

    return (
        <div className={`w-full sm:h-[100vh] bg-texture-[asfalt-light] overflow-y-auto`}>
            <NowPlaying />
            { !playlistIsVisible &&
                <div className={`pt-2
                    border-l border-secondary`}>
                    {
                        selectedComments.map(c =>
                            <ProjectedComment
                                key={c.id}
                                comment={c} />
                        )
                    }
                </div>
            }
        </div>
    )
}

ProjectedCommentsContainer.propTypes = {
    currentImage: PropTypes.shape({
        refId: PropTypes.string
    }).isRequired,
    comments: PropTypes.shape({
        [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            commenterName: PropTypes.string.isRequired
        }))
    }).isRequired,
    onFetch: PropTypes.func.isRequired,
    playlistIsVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    currentImage: selectCurrentImage(state),
    comments: selectComments(state),
    playlistIsVisible: selectPlaylistIsVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFetch: () => dispatch(fetchComments())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectedCommentsContainer)
