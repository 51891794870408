import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { deleteCommentConfirmed, deleteCommentCancelled } from './ducks/actions'
import { selectDeleteCommentErrors, selectDeleteComment,
    selectCommentIsDeleting, selectDeleteCommentUncaughtError } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import ConfirmationModal from '../modals/ConfirmationModal'

const DeleteCommentsConfirmationModal = ({ deleteComment, deleteCommentErrors,
    onDeleteCommentConfirmed, onDeleteCommentCancelled,
    isDeleting, uncaughtError }) => {

    return (
        <ConfirmationModal isProcessing={ isDeleting }
            onConfirm={ onDeleteCommentConfirmed }
            onCancel={ onDeleteCommentCancelled }>

            <p className="mb-4 text-lg text-black">
                { Translations.get('gallery.comments.delete_confirmation') }
            </p>
            <div className="mb-4 text-black">
                <p>{ deleteComment.commenter.name }</p>
                <p>"{ deleteComment.text }"</p>
            </div>
            {
                uncaughtError &&
                <p className="text-red-400 mb-4">
                    { uncaughtError.message }
                </p>
            }
            { !_.isEmpty(deleteCommentErrors) &&
                <div className="text-red-400">
                    { Object.keys(deleteCommentErrors).map((k, i) =>
                        <div key={i} className="text-sm">
                            { deleteCommentErrors[k].map((v, j) =>
                                <p key={j}>
                                    { Translations.get(`gallery.comments.delete.error.${k}.${v.error}`) }
                                </p>
                            )}
                        </div>
                    )}
                </div>
            }
        </ConfirmationModal>
    )
}

DeleteCommentsConfirmationModal.propTypes = {
    onDeleteCommentConfirmed: PropTypes.func.isRequired,
    onDeleteCommentCancelled: PropTypes.func.isRequired,
    deleteCommentErrors: PropTypes.object.isRequired,
    deleteComment: PropTypes.object.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    uncaughtError: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state),
    deleteCommentErrors: selectDeleteCommentErrors(state),
    deleteComment: selectDeleteComment(state),
    isDeleting: selectCommentIsDeleting(state),
    uncaughtError: selectDeleteCommentUncaughtError(state)
})

const mapDispatchToProps = (dispatch) => ({
    onDeleteCommentConfirmed: () => dispatch(deleteCommentConfirmed()),
    onDeleteCommentCancelled: () => dispatch(deleteCommentCancelled())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCommentsConfirmationModal)
