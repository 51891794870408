import { createAction } from '@reduxjs/toolkit'
import config from '../../config'
// import imageSources from '../../on_the_day_projection/ducks/image_sources'

const namespace = 'on_the_day_users'

export const FETCH_COMMENTS_DID_SUCCEED = `${namespace}/FETCH_COMMENTS_DID_SUCCEED`
export const FETCH_COMMENTS_DID_FAIL = `${namespace}/FETCH_COMMENTS_DID_FAIL`

export const UPDATE_COMMENT_TEXT = `${namespace}/UPDATE_COMMENT_TEXT`
export const UPDATE_COMMENTER_NAME = `${namespace}/UPDATE_COMMENTER_NAME`

export const UPDATE_CURRENT_IMAGE_INDEX = `${namespace}/UPDATE_CURRENT_IMAGE_INDEX`

export const SUBMIT_COMMENT_DID_START = `${namespace}/SUBMIT_COMMENT_DID_START`
export const SUBMIT_COMMENT_DID_END = `${namespace}/SUBMIT_COMMENT_DID_END`
export const SUBMIT_COMMENT_DID_ERROR = `${namespace}/SUBMIT_COMMENT_DID_ERROR`
export const SUBMIT_COMMENT_DID_SUCCEED = `${namespace}/SUBMIT_COMMENT_DID_SUCCEED`
export const SUBMIT_COMMENT_DID_FAIL = `${namespace}/SUBMIT_COMMENT_DID_FAIL`

export const DELETE_COMMENT = `${namespace}/DELETE_COMMENT`
export const CANCEL_DELETE_COMMENT = `${namespace}/CANCEL_DELETE_COMMENT`
export const DELETE_COMMENT_DID_SUCCEED = `${namespace}/DELETE_COMMENT_DID_SUCCEED`
export const DELETE_COMMENT_DID_FAIL = `${namespace}/DELETE_COMMENT_DID_FAIL`
export const DELETE_COMMENT_DID_ERROR = `${namespace}/DELETE_COMMENT_DID_ERROR`
export const DELETE_COMMENT_DID_START = `${namespace}/DELETE_COMMENT_DID_START`
export const DELETE_COMMENT_DID_END = `${namespace}/DELETE_COMMENT_DID_END`

export const cancelDeleteConfirmation = createAction(
    CANCEL_DELETE_COMMENT
)

export const deleteComment = createAction(
    DELETE_COMMENT,
    (comment) => ({
        payload: { comment }
    })
)

export const confirmDeleteComment = () => (dispatch, getState) => {
    const { deleteComment: comment, commenterOnceOffId } = getState().onTheDayUsers
    const { invitationCode } = getState().invitationScreening

    dispatch({
        type: DELETE_COMMENT_DID_START
    })

    fetch(`${config.API_BASE_URL}/projected_comments/${
        comment.id}?commenterOnceOffId=${commenterOnceOffId}&invitationCode=${invitationCode}`,
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.status === 0) {
                dispatch({
                    type: DELETE_COMMENT_DID_SUCCEED,
                    payload: { comment }
                })
            } else {
                console.log('delete comment errors', json.data.errors)
                dispatch({
                    type: DELETE_COMMENT_DID_FAIL,
                    payload: {
                        errors: json.data.errors
                    }
                })
            }
        })
        .catch(err => {
            dispatch({
                type: DELETE_COMMENT_DID_ERROR,
                payload: {
                    error: err
                }
            })
        })
        .finally(() => {
            dispatch({
                type: DELETE_COMMENT_DID_END
            })
        })
}

export const submitComment = () => (dispatch, getState) => {
    const { refId, newComment } = getState().onTheDayUsers

    dispatch({
        type: SUBMIT_COMMENT_DID_START
    })

    fetch(`${config.API_BASE_URL}/projected_comments`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            projectedComment: {
                ...newComment,
                refId
            }
        })
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: SUBMIT_COMMENT_DID_SUCCEED,
                payload: {
                    refId,
                    comment: json.data.projectedComment
                }
            })
        } else {
            console.error('Error posting comment', json)
            dispatch({
                type: SUBMIT_COMMENT_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
    .catch(err =>
        dispatch({
            type: SUBMIT_COMMENT_DID_ERROR,
            payload: {
                error: err
            }
        })
    )
    .finally(() => {
        dispatch({
            type: SUBMIT_COMMENT_DID_END
        })

        // perform refresh in case other comments came before this
        dispatch(fetchComments())
    })
}

export const fetchComments = () => (dispatch, getState) => {
    const { refId = '' } = getState().onTheDayUsers

    fetch(`${config.API_BASE_URL}/projected_comments?ref_id=${refId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_COMMENTS_DID_SUCCEED,
                payload: {
                    refId: json.data.refId,
                    comments: json.data.projectedComments
                }
            })
        } else {
            dispatch({
                type: FETCH_COMMENTS_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
}

export const updateCommentText = createAction(
    UPDATE_COMMENT_TEXT,
    (text) => ({
        payload: {
            text
        }
    })
)

export const updateCommenterName = createAction(
    UPDATE_COMMENTER_NAME,
    (commenterName) => ({
        payload: {
            commenterName
        }
    })
)

export const updateCurrentImageIndex = createAction(
    UPDATE_CURRENT_IMAGE_INDEX,
    (i, refId) => {
        window.history.replaceState(null, null, `?ref_id=${refId}`)
        window.scrollTo(0,0)

        return {
            payload: {
                imageIndex: i,
                refId
            }
        }
    }
)
