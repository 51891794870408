import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import Spinner from '../loaders/Spinner'

const ConfirmationModal = ({ children, isProcessing, onConfirm, onCancel }) => (
    <div className={`fixed z-50 top-0 left-0 right-0 bottom-0 bg-black/80
        w-full h-[100vh] flex items-center justify-center touch-none`}>
        <div className="w-[90vw] max-w-[500px] min-w-[300px] p-8 bg-white/90">
            <div className="text-center mb-8">
                { children }
            </div>
            <div className="flex justify-center text-white">
                <button className="bg-red-400 mr-2 px-4 py-2 w-1/2" onClick={onConfirm}>
                    { isProcessing
                        ?
                        <span className="flex justify-center">
                            <Spinner />
                        </span>
                        :
                        Translations.get('confirmation_modal.confirm')
                    }
                </button>
                <button className="bg-neutral-600 px-4 py-2 w-1/2" onClick={onCancel}>
                    { Translations.get('confirmation_modal.cancel') }
                </button>
            </div>
        </div>
    </div>
)

ConfirmationModal.propTypes = {
    isProcessing: PropTypes.bool, // optional
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(ConfirmationModal)
