// Australian English

// for translations that depend on input values
export const dynamic = (key) => ({
    'attendees_page.table.details.total_attending': (n) => `Total ${n} attendees`,
    'attendees_page.table.details.total_child_seats': (n) => `Require ${n} child seats`,
    'attendees_page.rsvp_history.historic.header': (n) => `Edit #${n}`,
    'on_the_day.admin.comments.delete_confirmation': (name) => `Are you sure want to delete this comment by ${name}?`,
    'gallery.admin.comments.delete_confirmation': (name) => `Are you sure want to delete this comment by ${name}?`,
    'rsvp_form.submit_confirmation.line_1': (count) => `Total guests attending: ${count}`
})[key]

// for fixed translations
const dict = {
    'invitation_page.errors.code.invalid': 'Invalid invitation code, please provide your complete invitation code',
    'invitation_page.errors.code.blank': 'Invitation code must be present',
    'invitation_page.opening_text.dear': 'Dear',
    'invitation_page.opening_text.guests': 'family and friends',
    'invitation_page.opening_text.text.line_1': 'Learn more about the event by entering your invitation code below',
    'invitation_page.opening_text.text.line_2': ' ',
    'invitation_page.reminders.invitation_code_location': 'Your code can be found in your invitation envelope',
    'invitation_page.reminders.personal_privacy': 'In order to protect your own privacy, please refrain from sharing your personalised invitation code with others',
    'invitation_page.form.code': 'Please enter your code here',
    'rsvp_form.labels.name': '*Name (required)',
    'rsvp_form.labels.email': 'Email',
    'rsvp_form.labels.mobile': '*Mobile (required)',
    'rsvp_form.labels.is_attending': 'Will you be attending?',
    'rsvp_form.is_attending.options.yes': 'Yes! Count me in',
    'rsvp_form.is_attending.options.no': 'Sorry, I have other plans',
    'rsvp_form.placeholders.name': 'Please enter your full name',
    'rsvp_form.placeholders.email': 'Please enter your email',
    'rsvp_form.placeholders.mobile': 'Please enter your mobile number',
    'rsvp_form.placeholders.is_attending': 'Please select',
    'rsvp_form.guests.description': 'Please indicate below any additional guests (besides yourself) whom you will be bringing to the event',
    'rsvp_form.guests.placeholders.name': 'Guest\'s full name',
    'rsvp_form.guests.labels.child_seat': 'Child Seat',
    'rsvp_form.guests.child_seat.options.yes': 'Yes',
    'rsvp_form.guests.child_seat.options.no': 'No',
    'rsvp_form.guests.remove': 'Remove',
    'rsvp_form.guests.add': 'Add',
    'rsvp_form.submit': 'Submit',
    'rsvp_form.errors.name.blank': 'Name must be present',
    'rsvp_form.errors.email.blank': 'Email must be present',
    'rsvp_form.errors.contact_number.blank': 'Contact number must be present',
    'rsvp_form.errors.is_attending.inclusion': 'Please indicate whether you will be attending our wedding',
    'rsvp_confirmation.guest.name': 'Name',
    'rsvp_confirmation.guest.email': 'Email',
    'rsvp_confirmation.guest.contact_number': 'Contact Number',
    'rsvp_confirmation.guest.attendees': 'Attendees',
    'rsvp_confirmation.guest.requires_child_seat': ' (requires child seat)',
    'rsvp_confirmation.header': 'Thank you for your response!',
    'rsvp_confirmation.subheader': 'Your R.S.V.P details are as follows:',
    'rsvp_confirmation.footer': 'Upon submission, please contact Rita or Eddy directly for changes to your R.S.V.P status',
    'rsvp_confirmation.button.view_gallery': 'View Wedding Photos',
    'rsvp_confirmation.other_todos': 'Next up, we invite you to...',
    'rsvp_confirmation.guest.attendees.none': 'None',
    'rsvp_form.submit_confirmation.line_2': 'Is this correct?',
    'home_page.banner.button.view_gallery': 'View Gallery',
    'home_page.banner.button.rsvp': 'R.S.V.P',
    'home_page.event_details.date': 'Date — 2022.11.25',
    'home_page.event_details.time': 'Time — 6:30 PM',
    'home_page.event_details.add_to_calendar': 'Please Save The Date',
    'home_page.event_details.address.line_1': 'Regent Taipei',
    'home_page.event_details.address.line_2': '4th Floor, Junior Ballroom',
    'home_page.event_details.address.line_3': 'No. 3, Lane 39, Section 2, Zhongshan N. Rd',
    'home_page.event_details.address.line_4': 'Zhongshan District, Taipei City',
    'home_page.links.header': 'Links',
    'home_page.links.rsvp': 'R.S.V.P',
    'home_page.links.rsvp.view_only': 'Review R.S.V.P',
    'home_page.links.view_gallery': 'View Gallery',
    'home_page.links.attendees': 'View Attendees',
    'home_page.links.projection': 'View Projection',
    'home_page.links.manage_projected_comments': 'Projected Comments',
    'home_page.links.manage_wedding_photographs_comments': 'Gallery Comments',
    'home_page.admin_tools.header': 'Admin Tools',
    'home_page.footer': 'Made with ❤ by Rita & Eddy',
    'router.home_button': 'Home',
    'router.attendees_button': 'Attendees',
    'router.back_button': 'Back',
    'posts.rsvp_button': 'R.S.V.P',
    'posts.view_gallery_button': 'View Gallery',
    'gallery.description': 'Click on the photograph to view at a higher resolution!',
    'gallery.comments.count': 'Comments',
    'gallery.comments.submit_button': 'Submit',
    'gallery.comments.placeholder': 'Please leave a comment here',
    'gallery.comments.delete': 'Delete',
    'gallery.comments.delete_confirmation': 'Are you sure you want to delete this comment?',
    'gallery.comments.delete.error.base.unauthorised_access': 'You do not have access to deleting this comment!',
    'confirmation_modal.confirm': 'Confirm',
    'confirmation_modal.cancel': 'Cancel',
    'attendees_page.fetch_attendees.error.base.unauthorised_access': 'You do not have access to view attendees!',
    'attendees_page.table.title': 'Attendees List',
    'attendees_page.table.header.name': 'Name',
    'attendees_page.table.header.is_attending': 'Attending?',
    'attendees_page.table.header.is_child_seat_required': 'Child Seat?',
    'attendees_page.table.header.contact': 'Contact',
    'attendees_page.table.header.can_edit_rsvp': 'Can Edit?',
    'attendees_page.table.row.is_attending.yes': 'Yes',
    'attendees_page.table.row.is_attending.no': 'No',
    'attendees_page.table.row.is_child_seat_required.yes': 'Yes',
    'attendees_page.table.row.is_child_seat_required.no': 'No',
    'attendees_page.table.row.can_edit_rsvp.yes': 'Yes',
    'attendees_page.table.row.can_edit_rsvp.no': 'No',
    'attendees_page.rsvp_history.historic.additional_guest.requires_child_seat': ', requires child seat',
    'attendees_page.rsvp_history.historic.labels.name': 'Name',
    'attendees_page.rsvp_history.historic.labels.email': 'Email',
    'attendees_page.rsvp_history.historic.labels.contact_number': 'Contact',
    'attendees_page.rsvp_history.historic.labels.is_attending': 'Attending?',
    'attendees_page.rsvp_history.historic.labels.additional_guests': 'Additional Guests',
    'attendees_page.rsvp_history.historic.labels.is_attending.yes': 'Yes',
    'attendees_page.rsvp_history.historic.labels.is_attending.no': 'No',
    'attendees_page.rsvp_history.current.header': 'Current Response',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp': 'Can Edit Rsvp?',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp.yes': 'Yes',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp.no': 'No',
    'attendees_page.filters.show_absent_guests': 'Show absent guests',
    'attendees_page.edit_attendee_button.edit': 'Edit Rsvp',
    'attendees_page.edit_attendee_button.close': 'Close Edit Form',
    'on_the_day.users.new_comment.submitted': 'Thank you!',
    'on_the_day.users.new_comment.placeholders.text': 'Leave a comment here ...',
    'on_the_day.users.new_comment.placeholders.commenter_name': 'Your name',
    'on_the_day.users.new_comment.submit_button': 'Submit',
    'on_the_day.users.new_comment.submit_uncaught_error': 'Oops! An error has occurrred.',
    'on_the_day.users.new_comment.errors.text.blank': 'Please type some comments',
    'on_the_day.users.new_comment.errors.commenter_name.blank': 'Please fill in your name',
    'on_the_day.users.photograph_selector.header': 'Would you like to comment on other photographs?',
    'on_the_day.users.photograph_selector.subheader': 'Feel free to click on another below.',
    'on_the_day.users.comments_section.empty_comments.line_1': 'There are no comments for this photograph yet.',
    'on_the_day.users.comments_section.empty_comments.line_2': 'We invite you to leave a comment below.',
    'on_the_day.users.comments_section.comments.header': 'What others have said ...',
    'on_the_day.users.comments.delete_confirmation': 'Are you sure you want to delete this comment?',
    'on_the_day.users.comments_section.comments.delete_button': 'Delete',
    'on_the_day.users.comments_section.comments.delete_errors.base.unauthorised_access': 'You do not have delete privileges for this comment.',
    'on_the_day.admin.comments.header.has_comments': 'Projected Comments',
    'on_the_day.admin.comments.header.no_comments': '☹ There are no comments ☹',
    'on_the_day.admin.comments.delete_button': 'Delete',
    'on_the_day.admin.comments.delete_errors.base.unauthorised_access': 'You do not have delete privileges for this comment.',
    'delete_button': 'Delete',
    'sign_out': 'Sign Out',
    'gallery.admin.comments_header': 'Photograph Comments',
    'gallery.admin.comments.delete_errors.base.unauthorised_access': 'You do not have delete privileges for this comment.',
}

export default dict
