import _ from 'lodash'

import {
    CANCEL_RESPOND_TO_SURVEY,
    RESPOND_TO_SURVEY,
    RESPOND_TO_SURVEY_DID_START,
    RESPOND_TO_SURVEY_DID_END,
    RESPOND_TO_SURVEY_DID_SUCCEED,
    RESPOND_TO_SURVEY_DID_FAIL,
    FETCH_SURVEY_RESPONSES_DID_SUCCEED,
    FETCH_SURVEY_RESPONSES_DID_FAIL,
} from './actions'

const initialState = {
    eddySurveyVisible: false,
    ritaSurveyVisible: false,
    surveyIsProcessing: false,
    eddySurveyResponses: {},
    ritaSurveyResponses: {},
    surveyErrors: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case RESPOND_TO_SURVEY_DID_START: {
            return {
                ...state,
                surveyIsProcessing: true,
                surveyErrors: {}
            }
        }
        case RESPOND_TO_SURVEY_DID_END: {
            return {
                ...state,
                surveyIsProcessing: false
            }
        }
        case RESPOND_TO_SURVEY_DID_SUCCEED: {
            const targetSurvey = _.camelCase(action.payload.targetSurvey)
            const { response } = action.payload

            return {
                ...state,
                eddySurveyVisible: false,
                ritaSurveyVisible: false,
                [`${targetSurvey}Responses`]: {
                    ...state[`${targetSurvey}Responses`],
                    [response.invitationCodeId]: response
                }
            }
        }
        case RESPOND_TO_SURVEY_DID_FAIL: {
            return {
                ...state,
                surveyErrors: {}
            }
        }
        case FETCH_SURVEY_RESPONSES_DID_SUCCEED: {
            const { eddySurveyResponses, ritaSurveyResponses } = action.payload

            return {
                ...state,
                eddySurveyResponses: eddySurveyResponses.reduce((dict, r) => ({
                    ...dict, [r.invitationCodeId]: r
                }), {}),
                ritaSurveyResponses: ritaSurveyResponses.reduce((dict, r) => ({
                    ...dict, [r.invitationCodeId]: r
                }), {})
            }
        }
        case FETCH_SURVEY_RESPONSES_DID_FAIL: {
            console.log(action.payload.errors)
            return state
        }
        case RESPOND_TO_SURVEY: {
            const { targetSurvey } = action.payload

            return {
                ...state,
                [`${targetSurvey}Visible`]: true
            }
        }
        case CANCEL_RESPOND_TO_SURVEY: {
            return {
                ...state,
                eddySurveyVisible: false,
                ritaSurveyVisible: false
            }
        }
        default:
            return state
    }
}

export default reducer
