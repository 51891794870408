import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ProjectedGallery from './ProjectedGallery'
import ProjectedAudio from './ProjectedAudio'
import ProjectedCommentsContainer from './ProjectedCommentsContainer'
import ProjectionHelp from './ProjectionHelp'
import { selectProjectionHelpVisible } from './ducks/selectors'
import { showProjectionHelp } from './ducks/actions'
import '../scheduled_tasks/hide_audio_visualiser'

const AUTO_HIDE_CURSOR_INTERVAL = 1500

const ProjectionPage = ({ projectionHelpVisible, onShowProjectionHelpClick }) => {
    let [cursorMovedAt, setCursorMovedAt] = useState(Date.now())
    let [cursorVisible, setCursorVisible] = useState(true)
    let autoHideCursorInterval = null

    // 3 second delay
    const onAutoHideCursor = (e) => {
        if (Date.now() - cursorMovedAt > AUTO_HIDE_CURSOR_INTERVAL) {
            setCursorVisible(false)
        }
    }

    const updateCursorMovedAt = (e) => {
        setCursorMovedAt(Date.now())
        if (!cursorVisible) setCursorVisible(true)
    }

    useEffect(() => {
        document.title = 'Projection | Rita & Eddy 2022'

        window.addEventListener('mousemove', updateCursorMovedAt)
        clearInterval(autoHideCursorInterval)
        autoHideCursorInterval = setInterval(onAutoHideCursor, AUTO_HIDE_CURSOR_INTERVAL)

        return () => {
            window.removeEventListener('mousemove', updateCursorMovedAt)
            clearInterval(autoHideCursorInterval)
            autoHideCursorInterval = null
        }
    })

    const handleClick = (e) => {
        e.preventDefault()
        onShowProjectionHelpClick()
    }

    return (
        <div className={`relative flex flex-wrap sm:flex-nowrap ${cursorVisible ? '' : 'cursor-none'}`}>
            <ProjectedAudio />
            <div className="w-full sm:w-3/4 select-none relative">
                <ProjectedGallery />
            </div>
            <div className="w-full sm:w-1/4 min-w-[250px] select-none">
                <ProjectedCommentsContainer />
            </div>
            {
                projectionHelpVisible
                    ?
                    <div className="bg-black/90 fixed top-0 left-0 w-full h-[100vh] z-50 flex items-center justify-center">
                        <ProjectionHelp />
                    </div>
                    :
                    <div className="bg-black/25 absolute bottom-0 left-0 p-2 z-50 select-none">
                        <button onClick={ handleClick }>
                            <span className="text-white">&#9432;</span>
                        </button>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    projectionHelpVisible: selectProjectionHelpVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onShowProjectionHelpClick: () => dispatch(showProjectionHelp())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionPage)
