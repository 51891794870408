import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectCurrentAudio, selectAudioIsPaused, selectPlaylistIsVisible,
    selectAudioIsMuted, selectNowPlayingIsVisible, selectAudioIsFetching
} from './ducks/selectors'
import { playAudio, pauseAudio, hideNowPlaying, showNowPlaying,
    showPlaylist, hidePlaylist } from './ducks/actions'
import { setCanvasNode as setVisualiserCanvasNode,
    draw as drawVisualiser,
    unsetCanvasNode as unsetVisualiserCanvasNode
} from './ducks/audio_visualiser'
import Spinner from '../loaders/Spinner'
import Playlist from './Playlist'

// close now playing at commencement time

const NowPlaying = ({ currentAudio, onPlay, onPause, isPaused, isMuted,
    isVisible, onHide, onShow, isLoading, playlistIsVisible,
    onShowPlaylist, onHidePlaylist }) => {
    const [drewVisualiserOnce, setDrewVisualiserOnce] = useState(false)

    let canvasNode

    const setCanvasNode = (el) => {
        canvasNode = el
        setVisualiserCanvasNode(canvasNode)

        if (!isPaused) {
            if (!drewVisualiserOnce) {
                setDrewVisualiserOnce(true)
                drawVisualiser()
            }
        }
    }

    useEffect(() => {
        const hideToggle = (e) => {
            if (e.key === 'h' || e.code === 'KeyH')
                isVisible ? onHide() : onShow()
        }

        window.addEventListener('keydown', hideToggle)

        return () => {
            window.removeEventListener('keydown', hideToggle)
            unsetVisualiserCanvasNode()
        }
    })

    return (
        <div className={`${ isVisible ? '' : 'hidden' }`}>
            <div className={`group relative flex justify-center items-end sticky top-0`}>
                <div className="w-full text-black text-center py-2 px-4 truncate relative bg-white/20 z-20">
                    <div className="text-sm truncate">
                        { isPaused
                            ?
                            <div className="inline-block">
                                <span className="pause-symbol" />
                            </div>
                            :
                            <div className="inline-block relative">
                                { isLoading
                                    ? <span className="relative top-1 scale-75 block"><Spinner color="black" /></span>
                                    : <span className="animate-pulse">♫</span>
                                }
                                { isMuted &&
                                    <span className={`inline-block border-b
                                        border-red-600 w-6 absolute top-[12px] -left-[7px]
                                        -rotate-45
                                        `} />
                                }
                            </div>
                        }
                        <span className="ml-2">{ currentAudio.title }</span>
                    </div>
                    <p className="text-xs truncate text-neutral-800">
                        { currentAudio.artist }
                    </p>
                </div>
                <div className={`absolute top-0 left-0 right-0 bottom-0
                    flex justify-between items-center z-30 flex-wrap
                    bg-black/60 text-white opacity-0 group-hover:opacity-100`}>
                    <div className="text-center w-full pt-2">
                        <button onClick={ isPaused ? onPlay : onPause }>
                            { isPaused
                                ? '▶'
                                :
                                <div className="h-[14px]">
                                    <span className="pause-symbol" />
                                </div>
                            }
                        </button>
                    </div>
                    <div className="text-xs w-full text-right pr-2">
                        <button onClick={ playlistIsVisible ? onHidePlaylist : onShowPlaylist }>
                            { playlistIsVisible ? '▲' : '▼' }
                        </button>
                    </div>
                </div>
                <div className="absolute top-0 left-0 right-0 bottom-0 z-10">
                    <canvas className="w-full h-[52px]" ref={setCanvasNode} />
                </div>
            </div>
            { playlistIsVisible &&
                <div className="border-b-2 border-secondary">
                    <Playlist />
                </div>
            }
        </div>
    )
}

NowPlaying.propTypes = {
    currentAudio: PropTypes.shape({
        playOrder: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        artist: PropTypes.string.isRequired
    }).isRequired,
    isPaused: PropTypes.bool.isRequired,
    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onShow: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    playlistIsVisible: PropTypes.bool.isRequired,
    onShowPlaylist: PropTypes.func.isRequired,
    onHidePlaylist: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentAudio: selectCurrentAudio(state),
    isPaused: selectAudioIsPaused(state),
    isMuted: selectAudioIsMuted(state),
    isVisible: selectNowPlayingIsVisible(state),
    isLoading: selectAudioIsFetching(state),
    playlistIsVisible: selectPlaylistIsVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onPlay: () => dispatch(playAudio()),
    onPause: () => dispatch(pauseAudio()),
    onHide: () => dispatch(hideNowPlaying()),
    onShow: () => dispatch(showNowPlaying()),
    onShowPlaylist: () => dispatch(showPlaylist()),
    onHidePlaylist: () => dispatch(hidePlaylist())
})

export default connect(mapStateToProps, mapDispatchToProps)(NowPlaying)
