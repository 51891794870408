import config from '../config'
import PropTypes from 'prop-types'
import { selectSearchParams } from '../router/ducks/selectors'

const projectHighResolution = selectSearchParams()
    .get('highResolution') === 'true'

const PROJECT_HIGH_RESOLUTION_IMAGES = projectHighResolution ||
    config.PROJECT_HIGH_RESOLUTION_IMAGES

const ProjectedImage = ({ image, visible }) => (
    <div className={`w-full h-full absolute top-0
        transition-opacity duration-1000 ease-in-out
        ${visible ? 'left-0 opacity-100' : '-left-[100vw] opacity-0'}`}>

        <img alt={ image.title }
            src={ PROJECT_HIGH_RESOLUTION_IMAGES
                ? image.highResolutionUrl : image.url }
            draggable={ false }
            className={`w-full h-full object-contain`} />
    </div>
)

ProjectedImage.propTypes = {
    image: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        highResolutionUrl: PropTypes.string.isRequired
    }).isRequired,
    visible: PropTypes.bool.isRequired
}

export default ProjectedImage
