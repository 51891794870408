import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteComment } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const Comment = ({ comment, onDelete }) => (
    <div className="pt-1 pb-2 mb-2 border-b border-l border-secondary">
        <div className="pl-4 inline-block">
            <p className="text-secondary">{ comment.commenter.name }</p>
            <p className="text-primary">{ comment.text }</p>
        </div>
        <div className="text-right">
            <button className="bg-red-500 text-white px-2 py-1 min-w-[50px]"
                onClick={() => onDelete(comment) }>
                { Translations.get('delete_button') }
            </button>
        </div>
    </div>
)

Comment.propTypes = {
    comment: PropTypes.shape({
        id: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
        commenter: PropTypes.shape({
            name: PropTypes.string.isRequired,
            invitationCodeId: PropTypes.any.isRequired
        }).isRequired
    }),
    onDelete: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onDelete: (c) => dispatch(deleteComment(c))
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
