import _ from 'lodash'
import EN_TRANSLATIONS, { dynamic as dynamicEnTranslations } from './en-AU'
import ZH_TRANSLATIONS, { dynamic as dynamicZhTranslations } from './zh-TW'

// no need for full language codes for now,
// only supporting 2 general languages
export const KNOWN_LANGUAGE_CODES = ['en', 'zh']

let userPreferredLanguage = 'zh'
let translations = EN_TRANSLATIONS
let dynamicTranslations = dynamicEnTranslations

const setTranslations = (code) => {
    switch(code) {
        case 'en': {
            translations = EN_TRANSLATIONS
            dynamicTranslations = dynamicEnTranslations
            break
        }
        case 'zh': {
            translations = ZH_TRANSLATIONS
            dynamicTranslations = dynamicZhTranslations
            break
        }
        default: {
            // unknown language code
            translations = {}
            dynamicTranslations = {}
            break
        }
    }
}

const getTranslations = () => ({ ...translations })

const setPreferredLanguage = (code) => {
    if (!KNOWN_LANGUAGE_CODES.includes(code)) {
        console.error(`language code ${code} is not a supported code`)
    }

    userPreferredLanguage = code
    // store preference locally
    storeUserPreferredLanguage(code)
    setTranslations(code)
}

const getPreferredLanguage = () => {
    return userPreferredLanguage
}

// get translation from a requested language
const getFrom = (languageCode, key) => {
    switch(languageCode) {
        case 'en':
            return EN_TRANSLATIONS[key]
        case 'zh':
            return ZH_TRANSLATIONS[key]
        default:
            console.error(`Unknown language code ${languageCode} in getFrom`)
            break
    }
}

/**
 * @note temp workaround for rest parameters in esbuild
 * is to avoid named functions
 */
const get = (key,...dynamicArgs) => {
    if (dynamicArgs.length > 0) {
        return getDynamic(key, dynamicArgs)
    }

    if (! (key in translations) || _.isEmpty(translations[key])) {
        console.error(`translations[${userPreferredLanguage}]: Missing translations for ${key}`)
        return key
    }

    return translations[key]
}

const getDynamic = (key,...dynamicArgs) => {
    if (!_.isFunction(dynamicTranslations(key))) {
        console.error(`translations[${userPreferredLanguage}]: Missing dynamic translations for ${key}`)
        return key
    }

    return dynamicTranslations(key)(dynamicArgs)
}

const storeUserPreferredLanguage = (code) => {
    localStorage && localStorage.setItem('userPreferredLanguage', code)
}

const getStoredUserPreferredLanguage = () => {
    return localStorage && localStorage.getItem('userPreferredLanguage')
}

// set translations once when module loads
const setInitialLanguage = () => {
    const storedUserPreferredLanguage = getStoredUserPreferredLanguage()

    if (storedUserPreferredLanguage) {
        userPreferredLanguage = storedUserPreferredLanguage
    } else if (navigator.language) {
        userPreferredLanguage = navigator.language.split('-')[0]
    }
}

const main = () => {
    setInitialLanguage()
    setTranslations(userPreferredLanguage)
}

main()

const moduleExports = {
    get,
    getFrom,
    getTranslations,
    setPreferredLanguage,
    getPreferredLanguage
}

export default moduleExports
