import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const PostCommentMessage = () => (
    <div className="px-3 my-3 text-white text-lg text-center">
        <span>&#128518; &#128518; &#128518; </span>
        { Translations.get('on_the_day.users.new_comment.submitted') }
        <span> &#128518; &#128518; &#128518;</span>
    </div>
)

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(PostCommentMessage)
