import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import HomeButton from '../router/HomeButton'
import { fetchComments } from './ducks/actions'
import { selectComments, selectDeleteConfirmationVisible } from './ducks/selectors'
import Comment from './Comment'
import CommentImageThumbnail from './CommentImageThumbnail'
import DeleteCommentConfirmation from './DeleteCommentConfirmation'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const CommentsManagementPage = ({ comments, onFetchComments,
    deleteConfirmationVisible }) => {
    let [fetchedOnce, setFetchedOnce] = useState(false)

    if (!fetchedOnce) {
        setFetchedOnce(true)
        onFetchComments()
    }

    useEffect(() => {
        document.title = 'Manage Comments | Rita & Eddy 2022'
    })

    return (
        <div className="p-4">
            <HomeButton />
            <div>
                <h1 className="text-center text-2xl text-secondary">
                    { Translations.get('gallery.admin.comments_header') }
                </h1>
                <p className="text-center">(Wedding Photographs)</p>
                {
                    Object.keys(comments).map(refId =>
                        <div key={refId} className="mb-12">
                            <div className="flex items-stretch">
                                <div>
                                    <CommentImageThumbnail refId={ refId } />
                                </div>
                                <div className="flex items-center pl-2 border-l-2 border-secondary">
                                    <p className="text-secondary">{ refId }</p>
                                </div>
                            </div>
                            <div className="flex space-between flex-wrap items-end">
                                {comments[refId].map(c =>
                                    <div key={c.id} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2">
                                        <Comment comment={c} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            </div>
            { deleteConfirmationVisible && <DeleteCommentConfirmation /> }
        </div>
    )
}

CommentsManagementPage.propTypes = {
    comments: PropTypes.shape({
        [PropTypes.string.isRequired]: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
            commenter: PropTypes.shape({
                name: PropTypes.string.isRequired,
                invitationCodeId: PropTypes.any.isRequired
            }).isRequired
        }))
    }),
    onFetchComments: PropTypes.func.isRequired,
    deleteConfirmationVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    comments: selectComments(state),
    deleteConfirmationVisible: selectDeleteConfirmationVisible(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFetchComments: () => dispatch(fetchComments())
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentsManagementPage)
