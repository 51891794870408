import config from '../../config'

import {
    UPDATE_INVITATION_CODE,
    VERIFY_INVITATION_CODE_DID_FAIL,
    VERIFY_INVITATION_CODE_DID_ERROR,
    // VERIFY_INVITATION_CODE_DID_BEGIN,
    // VERIFY_INVITATION_CODE_DID_END,
    VERIFY_INVITATION_CODE_DID_SUCCEED
} from './actions'

const initialState = {
    invitationCode: (config.DEBUG_MODE && config.TEST_INVITATION_CODE) || null,
    invitationCodeErrors: {},
    isAdmin: (config.DEBUG_MODE && config.IS_ADMIN) || false,
    invitationCodeId: (config.DEBUG_MODE && config.TEST_INVITATION_CODE_ID) || null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case VERIFY_INVITATION_CODE_DID_ERROR: {
            console.log(action)
            return state
        }
        case VERIFY_INVITATION_CODE_DID_FAIL: {
            return {
                ...state,
                invitationCodeErrors: action.payload.errors
            }
        }
        case VERIFY_INVITATION_CODE_DID_SUCCEED: {
            return {
                ...state,
                invitationCodeErrors: {},
                isAdmin: action.payload.isAdmin,
                invitationCodeId: action.payload.invitationCodeId
            }
        }
        case UPDATE_INVITATION_CODE: {
            return {
                ...state,
                invitationCode: action.payload.code
            }
        } default:
            return state
    }
}

export default reducer
