import { createSelector } from 'reselect'

export const selectComments = createSelector(
    state => state.weddingPhotographsAdmin.comments,
    comments => comments
)

export const selectDeleteComment = createSelector(
    state => state.weddingPhotographsAdmin.deleteComment,
    comment => comment
)

export const selectCommentIsDeleting = createSelector(
    state => state.weddingPhotographsAdmin.isDeleting,
    isDeleting => isDeleting
)

export const selectDeleteErrors = createSelector(
    state => state.weddingPhotographsAdmin.deleteErrors,
    errors => errors
)

export const selectDeleteUncaughtError = createSelector(
    state => state.weddingPhotographsAdmin.deleteUncaughtError,
    error => error
)

export const selectDeleteConfirmationVisible = createSelector(
    state => state.weddingPhotographsAdmin.deleteConfirmationVisible,
    visible => visible
)
