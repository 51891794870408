import { createSelector } from 'reselect'

export const selectComments = createSelector(
    state => state.onTheDayAdmin.comments,
    comments => comments
)

export const selectDeleteComment = createSelector(
    state => state.onTheDayAdmin.deleteComment,
    comment => comment
)

export const selectDeleteConfirmationVisible = createSelector(
    state => state.onTheDayAdmin.deleteConfirmationVisible,
    visible => visible
)

export const selectCommentIsDeleting = createSelector(
    state => state.onTheDayAdmin.commentIsDeleting,
    isDeleting => isDeleting
)

export const selectDeleteErrors = createSelector(
    state => state.onTheDayAdmin.deleteErrors,
    errors => errors
)

export const selectDeleteUncaughtError = createSelector(
    state => state.onTheDayAdmin.deleteUncaughtError,
    error => error
)
