import PropTypes from 'prop-types'

const HoverUnderlineButton = ({ children, text, onClick,
    buttonClassNames, underlineClassNames, textClassNames }) => (
    <div className="group inline">
        <div className={`w-full cursor-pointer ${ buttonClassNames }`}
            onClick={ onClick }>
            <div className={ textClassNames }>
                { children ? children : text }
            </div>
            <span className={`block w-0 group-hover:w-full transition-[width]
                duration-300 ease-in-out block border-b-2
                ${ underlineClassNames }`} />
        </div>
    </div>
)

HoverUnderlineButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    buttonClassNames: PropTypes.string.isRequired, // background
    underlineClassNames: PropTypes.string.isRequired, // border color
    textClassNames: PropTypes.string.isRequired // text padding and color
}

export default HoverUnderlineButton
