import { createSelector } from 'reselect'

export const selectIsAdmin = createSelector(
    state => state.invitationScreening.isAdmin,
    isAdmin => isAdmin
)

export const selectInvitationCodeErrors = createSelector(
    state => state.invitationScreening.invitationCodeErrors,
    invitationCodeErrors => invitationCodeErrors
)

export const selectInvitationCodeId = createSelector(
    state => state.invitationScreening.invitationCodeId,
    codeId => codeId
)
