import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectContacts } from './ducks/selectors'
import { fetchRsvpHistory } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const MainGuestTableRow = ({ contacts, guest, rowNumber, onViewRsvpHistory }) => (
    <div className={`table-row text-white border-b cursor-pointer
            bg-secondary hover:opacity-100 ${!guest.isAttending && 'bg-red-400 opacity-40'}`}
        onClick={() => onViewRsvpHistory(guest.id)}>
        <div className="table-cell p-4">{ rowNumber }</div>
        <div className="table-cell p-4">{ guest.name }</div>
        <div className="table-cell p-4">
            { (guest.isAttending && Translations.get('attendees_page.table.row.is_attending.yes')) ||
                Translations.get('attendees_page.table.row.is_attending.no')
            }
        </div>
        <div className="table-cell p-4">-</div>
        <div className="table-cell p-4">{ guest.contactNumber }</div>
        <div className="table-cell p-4">
            { guest.canEditRsvp
                ? Translations.get('attendees_page.table.row.can_edit_rsvp.yes')
                : Translations.get('attendees_page.table.row.can_edit_rsvp.no')
            }
        </div>
    </div>
)

MainGuestTableRow.propTypes = {
    guest: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        contactNumber: PropTypes.string.isRequired,
        isAttending: PropTypes.bool.isRequired
    }),
    rowNumber: PropTypes.number, // blank if not attending
    contacts: PropTypes.object.isRequired,
    onViewRsvpHistory: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    contacts: selectContacts(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onViewRsvpHistory: (guestId) => dispatch(fetchRsvpHistory(guestId))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainGuestTableRow)
