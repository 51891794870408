import {
    FETCH_COMMENTS_DID_SUCCEED,
    FETCH_COMMENTS_DID_FAIL,
    CYCLE_TO_NEXT_IMAGE,
    CYCLE_TO_NEXT_AUDIO,
    CLOSE_PROJECTION_HELP,
    SHOW_PROJECTION_HELP,
    PLAY_AUDIO,
    PAUSE_AUDIO,
    MUTE_AUDIO,
    UNMUTE_AUDIO,
    SHOW_NOW_PLAYING,
    HIDE_NOW_PLAYING,
    FETCH_AUDIO_DID_START,
    FETCH_AUDIO_DID_END,
    JUMP_TO_AUDIO,
    SHOW_PLAYLIST,
    HIDE_PLAYLIST,
    TOGGLE_BALLOONS
} from './actions'

import { selectSearchParams } from '../../router/ducks/selectors'

import audioSources from './audio_sources'
import imageSources from './image_sources'

const totalAudioSources = audioSources.length
const totalImageSources = imageSources.length

let initialAudioIndex = parseInt(selectSearchParams().get('audioIndex'))
let initialImageIndex = parseInt(selectSearchParams().get('imageIndex'))

if (initialAudioIndex) {
    if (initialAudioIndex < audioSources.length) {
        console.log(`User specified audio to begin at index [${
            initialAudioIndex}], ${audioSources[initialAudioIndex].fileName}`)
    } else {
        console.log(`${initialAudioIndex
            } exceeds audio sources count. Commencing from default audio index.`)
        initialAudioIndex = 0
    }
}

if (initialImageIndex) {
    if (initialImageIndex < imageSources.length) {
        console.log(`User specified image to begin at index [${
            initialImageIndex}], ${imageSources[initialImageIndex].fileName}`)
    } else {
        console.log(`${initialImageIndex
            } exceeds total image sources count Commencing from default image index.`)
        initialImageIndex = 0
    }
}

const initialState = {
    currentImageIndex: initialImageIndex || 0,
    currentAudioIndex: initialAudioIndex || 0,
    comments: {},
    projectionHelpVisible: false,
    audioPlaybackCounts: audioSources.reduce((counts, src, i) => ({
        ...counts,
        [i]: 0
    }), {}),
    audioIsPaused: true, // must start with paused for visualiser to work
    audioIsMuted: false,
    nowPlayingIsVisible: true,
    audioIsFetching: false,
    playlistIsVisible: false,
    animatedBalloonsVisible: true
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_BALLOONS: {
            return {
                ...state,
                animatedBalloonsVisible: !state.animatedBalloonsVisible
            }
        }
        case SHOW_PLAYLIST: {
            return {
                ...state,
                playlistIsVisible: true
            }
        }
        case HIDE_PLAYLIST: {
            return {
                ...state,
                playlistIsVisible: false
            }
        }
        case JUMP_TO_AUDIO: {
            return {
                ...state,
                currentAudioIndex: action.payload.index,
                audioIsPaused: false // autoplay
            }
        }
        case FETCH_AUDIO_DID_START: {
            return {
                ...state,
                audioIsFetching: true
            }
        }
        case FETCH_AUDIO_DID_END: {
            return {
                ...state,
                audioIsFetching: false
            }
        }
        case HIDE_NOW_PLAYING: {
            return {
                ...state,
                nowPlayingIsVisible: false
            }
        }
        case SHOW_NOW_PLAYING: {
            return {
                ...state,
                nowPlayingIsVisible: true
            }
        }
        case MUTE_AUDIO: {
            return {
                ...state,
                audioIsMuted: true
            }
        }
        case UNMUTE_AUDIO: {
            return {
                ...state,
                audioIsMuted: false
            }
        }
        case PAUSE_AUDIO: {
            return {
                ...state,
                audioIsPaused: true
            }
        }
        case PLAY_AUDIO: {
            return {
                ...state,
                audioIsPaused: false
            }
        }
        case SHOW_PROJECTION_HELP: {
            return {
                ...state,
                projectionHelpVisible: true
            }
        }
        case CLOSE_PROJECTION_HELP: {
            return {
                ...state,
                projectionHelpVisible: false
            }
        }
        case FETCH_COMMENTS_DID_SUCCEED: {
            const { refId, comments } = action.payload

            const nextComments = refId
                ? { ...state.comments, [refId]: comments }
                : comments.reduce((obj, c) => ({
                    ...obj,
                    [c.refId]: [
                        ...(obj[c.refId] || []),
                        c
                    ]
                }), {})

            return {
                ...state,
                comments: nextComments
            }
        }
        case FETCH_COMMENTS_DID_FAIL: {
            return {
                ...state
            }
        }
        case CYCLE_TO_NEXT_AUDIO: {
            const { audioPlaybackCounts, currentAudioIndex } = state
            const currentAudioPlaybackCount = audioPlaybackCounts[currentAudioIndex] || 0

            const nextAudioPlaybackCounts = {
                ...audioPlaybackCounts,
                [currentAudioIndex]: currentAudioPlaybackCount + 1
            }

            const canRepeat = (i) => audioSources[i].repeat

            // NOTE: must have at least 1 repeatable audio source to prevent infinite stack
            const getNextAudioIndex = (i) => {
                const nextAudioIndex = i === totalAudioSources - 1
                    ? 0 : i + 1

                const notPlayedOnce = nextAudioPlaybackCounts[nextAudioIndex] === 0

                if (canRepeat(nextAudioIndex) || notPlayedOnce) {
                    return nextAudioIndex
                } else {
                    return getNextAudioIndex(nextAudioIndex)
                }
            }

            return {
                ...state,
                audioPlaybackCounts: nextAudioPlaybackCounts,
                currentAudioIndex: getNextAudioIndex(currentAudioIndex)
            }
        }
        case CYCLE_TO_NEXT_IMAGE: {
            return {
                ...state,
                currentImageIndex: state.currentImageIndex === totalImageSources - 1
                    ? 0
                    : state.currentImageIndex + 1
            }
        }
        default:
            return state
    }
}

export default reducer
