import { createAction } from '@reduxjs/toolkit'
import config from '../../config'

const namespace = 'on_the_day_projection'

export const FETCH_COMMENTS_DID_SUCCEED = `${namespace}/FETCH_COMMENTS_DID_SUCCEED`
export const FETCH_COMMENTS_DID_FAIL = `${namespace}/FETCH_COMMENTS_DID_FAIL`

// autoplay
export const CYCLE_TO_NEXT_IMAGE = `${namespace}/CYCLE_TO_NEXT_IMAGE`
export const CYCLE_TO_NEXT_AUDIO = `${namespace}/CYCLE_TO_NEXT_AUDIO`

export const SHOW_PROJECTION_HELP = `${namespace}/SHOW_PROJECTION_HELP`
export const CLOSE_PROJECTION_HELP = `${namespace}/CLOSE_PROJECTION_HELP`

export const PAUSE_AUDIO = `${namespace}/PAUSE_AUDIO`
export const PLAY_AUDIO = `${namespace}/PLAY_AUDIO`

export const MUTE_AUDIO = `${namespace}/MUTE_AUDIO`
export const UNMUTE_AUDIO = `${namespace}/UNMUTE_AUDIO`

export const SHOW_NOW_PLAYING = `${namespace}/SHOW_NOW_PLAYING`
export const HIDE_NOW_PLAYING = `${namespace}/HIDE_NOW_PLAYING`

export const FETCH_AUDIO_DID_START = `${namespace}/FETCH_AUDIO_DID_START`
export const FETCH_AUDIO_DID_END = `${namespace}/FETCH_AUDIO_DID_END`

export const SHOW_PLAYLIST = `${namespace}/SHOW_PLAYLIST`
export const HIDE_PLAYLIST = `${namespace}/HIDE_PLAYLIST`
export const JUMP_TO_AUDIO = `${namespace}/JUMP_TO_AUDIO`

export const TOGGLE_BALLOONS = `${namespace}/TOGGLE_BALLOONS`

export const toggleBalloons = createAction(
    TOGGLE_BALLOONS
)

export const showPlaylist = createAction(
    SHOW_PLAYLIST
)

export const hidePlaylist = createAction(
    HIDE_PLAYLIST
)

export const jumpToAudio = createAction(
    JUMP_TO_AUDIO,
    (index) => ({
        payload: { index }
    })
)

export const fetchAudio = createAction(
    FETCH_AUDIO_DID_START
)

export const fetchAudioDone = createAction(
    FETCH_AUDIO_DID_END
)

export const showNowPlaying = createAction(
    SHOW_NOW_PLAYING
)

export const hideNowPlaying = createAction(
    HIDE_NOW_PLAYING
)

export const muteAudio = createAction(
    MUTE_AUDIO
)

export const unmuteAudio = createAction(
    UNMUTE_AUDIO
)

export const playAudio = createAction(
    PLAY_AUDIO
)

export const pauseAudio = createAction(
    PAUSE_AUDIO
)

export const showProjectionHelp = createAction(
    SHOW_PROJECTION_HELP
)

export const hideProjectionHelp = createAction(
    CLOSE_PROJECTION_HELP
)

export const fetchComments = (refId) => (dispatch, getState) => {
    const fetchUrl = refId
        ? `${config.API_BASE_URL}/projected_comments?ref_id=${refId}`
        : `${config.API_BASE_URL}/projected_comments`

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_COMMENTS_DID_SUCCEED,
                payload: {
                    refId,
                    comments: json.data.projectedComments
                }
            })
        } else {
            console.error('fetch comments errors', json)

            dispatch({
                type: FETCH_COMMENTS_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
}

export const getNextImage = createAction(
    CYCLE_TO_NEXT_IMAGE
)

export const getNextAudio = createAction(
    CYCLE_TO_NEXT_AUDIO
)
