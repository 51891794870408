import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectHasRepliedRsvp } from './ducks/selectors'
import BannerImage from './BannerImage'
import RsvpButton from './RsvpButton'
import ViewWeddingPhotographsButton from './ViewWeddingPhotographsButton'

const Post_1 = ({ hasRepliedRsvp }) => {
    return (
        <div>
            <BannerImage
                highResUrl='/wedding_photographs_original/KOU_0199.jpg'
                lowResUrl='/wedding_photographs_previews/KOU_0199.jpg'>
                <div className="absolute bottom-[75px] w-full">
                    <p className="text-center text-[50px] md:text-[75px] lg:text-[100px] lg:mb-20">Welcome to our wedding</p>
                </div>
            </BannerImage>

            <div className="px-8 mt-8 text-lg leading-loose md:text-2xl md:leading-loose text-center text-primary">
                <p>感謝有您</p>
                <p>陪伴我們走過人生不同階段</p>
                <p>因爲有您</p>
                <p>那些共渡的美好時光</p>
                <p>那些溫暖體貼的關懷</p>
                <p>都讓我們成長及喜樂</p>
                <p className="mt-8">在我們人生中重要的時刻</p>
                <p>期待有您一起參與</p>
                <p>2022年11月25日晚上</p>
                <p>期盼與您相見</p>
                <div className="mt-12 w-full max-w-[400px] mx-auto relative">
                    {
                        hasRepliedRsvp
                            ? <ViewWeddingPhotographsButton />
                            : <RsvpButton />
                    }
                </div>
            </div>
        </div>
    )
}

Post_1.propTypes = {
    hasRepliedRsvp: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    hasRepliedRsvp: selectHasRepliedRsvp(state),
})

export default connect(mapStateToProps)(Post_1)
