import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectImageSources, selectCurrentImageIndex } from './ducks/selectors'
import { updateCurrentImageIndex } from './ducks/actions'
import PhotographCounter from './PhotographCounter'

const PhotographSelector = ({ images, currentImageIndex, onImageClick }) => {
    const handleImageClick = (index, refId) => {
        if (index === currentImageIndex) return
        onImageClick(index, refId)
    }

    return (
        <div>
            <div className="flex items-center justify-center flex-wrap">
                {
                    images.map((img, i) =>
                        <div key={i} className={`w-1/4 h-24 cursor-pointer
                            overflow-hidden relative`}
                            onClick={() => handleImageClick(i, img.refId)}>
                            <div style={{
                                backgroundImage: `url(${img.thumbnailUrl})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }} className={`w-full h-full object-cover select-none
                                border-white hover:border-2 flex items-end justify-end hover:opacity-100
                                ${currentImageIndex === i
                                    ? 'border-2 opacity-100'
                                    : 'opacity-60'
                                }`}>
                                <span className="text-white bg-black/40 px-1">{i + 1}</span>
                            </div>

                        </div>
                    )
                }
            </div>
            <div className="text-white text-center mt-1 bg-black/40">
                <PhotographCounter />
            </div>
        </div>
    )
}

PhotographSelector.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        thumbnailUrl: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })),
    currentImageIndex: PropTypes.number.isRequired,
    onImageClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    images: selectImageSources(),
    currentImageIndex: selectCurrentImageIndex(state)
})

const mapDispatchToProps = (dispatch) => ({
    onImageClick: (i, refId) => dispatch(updateCurrentImageIndex(i, refId))
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotographSelector)
