import { connect } from 'react-redux'
import pinIcon from './map-pin.svg'
import RegentHotelGoogleMap from '../google_maps/RegentHotelGoogleMap'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const VenueDetails = () => (
    <div>
        <div className="w-full mb-3 h-[100vh] max-h-[150px] lg:max-h-[250px] hidden h-lg:block">
            <RegentHotelGoogleMap />
        </div>
        <a target="new"
            className="group block relative"
            href="https://maps.google.com/maps?ll=25.054213,121.524187&z=15&t=m&hl=en&gl=TW&mapclient=embed&cid=7944188266752151210">
            <span className="block ml-2">
                <img src={ pinIcon } alt="pin-icon" className="inline-block w-6 h-auto mr-1" />
                { Translations.get('home_page.event_details.address.line_1') }
            </span>
            <span className="block ml-9">{ Translations.get('home_page.event_details.address.line_2') }</span>
            <span className="block ml-9">{ Translations.get('home_page.event_details.address.line_3') }</span>
            <span className="block ml-9">{ Translations.get('home_page.event_details.address.line_4') }</span>
            <span className="h-0 group-hover:h-[95px] absolute left-0 top-0 transition-[height] duration-300 ease-in-out block border-l border-white"></span>
        </a>
    </div>
)

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(VenueDetails)
