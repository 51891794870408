import Link from './Link'
import Translations from '../translations'

const AddToCalendarButton = () => (
    <Link text={ Translations.get('home_page.event_details.add_to_calendar') }
        download={{ url: '/rita-eddy.ics', name: 'Rita & Eddy婚宴.ics',  }}
        onClick={() => {}}/>
)

export default AddToCalendarButton
