import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { redirectToHomePage } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const HomeButton = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault()
        onClick()
    }

    return (
        <div className="group inline">
            <button type="button"
                className="text-md md:text-xl text-secondary group-hover:bg-secondary group-hover:text-white"
                onClick={ handleClick }>
                <span className="border-2 border-secondary block py-2 px-4">
                    { Translations.get('router.home_button') }
                </span>
                <span className="w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b-2 border-white"></span>
            </button>
        </div>
    )
}

HomeButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch(redirectToHomePage())
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeButton)
