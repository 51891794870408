import { useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectAttendees, selectContacts, selectWeddingGuests,
    selectAbsentGuestsVisible } from './ducks/selectors'
import MainGuestTableRow from './MainGuestTableRow'
import AdditionalGuestTableRow from './AdditionalGuestTableRow'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import AbsentGuestsFilter from './AbsentGuestsFilter'
import { fetchAttendees } from './ducks/actions'

const mainGuestVisible = (guest, shouldShowAbsentGuests) => {
    return (shouldShowAbsentGuests && !guest.isAttending) || guest.isAttending
}

const AttendeesTable = ({ attendees, contacts, weddingGuests, absentGuestsVisible,
    onFetchAttendees }) => {
    const [fetchedOnce, setFetchedOnce] = useState(false)

    if (!fetchedOnce) {
        setFetchedOnce(true)
        onFetchAttendees()
    }

    // print row numbers
    let rowNumber = 1

    return (
        <div className="w-full">
            <div className="pt-12 p-6 md:p-20 bg-white w-full whitespace-nowrap">
                <h1 className="text-primary text-3xl mb-4">
                    { Translations.get('attendees_page.table.title') }
                </h1>
                <div className="text-secondary">
                    <p>
                        { Translations.get('attendees_page.table.details.total_attending',
                            _.size(attendees)) }
                    </p>
                    <p>
                        { Translations.get('attendees_page.table.details.total_child_seats',
                            _.size(_.pickBy(attendees, (a) => a.childSeatRequired))) }
                    </p>
                </div>
                <div className="text-right mb-4">
                    <AbsentGuestsFilter />
                </div>
                <div className="max-w-[100vw] overflow-auto">
                    <div className="table table-auto border-collapse w-full">
                        <div className="table-header-group">
                            <div className="table-row text-primary border-b-2 border-secondary">
                                <div className="table-cell p-4">#</div>
                                <div className="table-cell p-4">
                                    { Translations.get('attendees_page.table.header.name') }
                                </div>
                                <div className="table-cell p-4">
                                    { Translations.get('attendees_page.table.header.is_attending') }
                                </div>
                                <div className="table-cell p-4">
                                    { Translations.get('attendees_page.table.header.is_child_seat_required') }
                                </div>
                                <div className="table-cell p-4">
                                    { Translations.get('attendees_page.table.header.contact') }
                                </div>
                                <div className="table-cell p-4">
                                    { Translations.get('attendees_page.table.header.can_edit_rsvp') }
                                </div>
                            </div>
                        </div>
                        <div className="table-row-group">
                            {
                                _.map(weddingGuests, (wg, i) => {
                                    return [
                                        mainGuestVisible(wg, absentGuestsVisible) &&
                                        <MainGuestTableRow key={ wg.id }
                                            guest={ wg }
                                            rowNumber={ (wg.isAttending && rowNumber++) || null } />,
                                        ...wg.additionalGuests.map((ag, j) =>
                                            <AdditionalGuestTableRow key={ ag.name }
                                                guest={ ag }
                                                rowNumber={ rowNumber++ } />
                                        )
                                    ]
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AttendeesTable.propTypes = {
    attendees: PropTypes.array.isRequired,
    contacts: PropTypes.object.isRequired,
    weddingGuests: PropTypes.array.isRequired,
    absentGuestsVisible: PropTypes.bool.isRequired,
    onFetchAttendees: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    attendees: selectAttendees(state),
    contacts: selectContacts(state),
    weddingGuests: selectWeddingGuests(state),
    currentLanguageCode: selectCurrentLanguageCode(state),
    absentGuestsVisible: selectAbsentGuestsVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFetchAttendees: () => dispatch(fetchAttendees())
})

export default connect(mapStateToProps, mapDispatchToProps)(AttendeesTable)
