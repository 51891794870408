import { useEffect } from 'react'
import { connect } from 'react-redux'
import Posts from '../posts/Posts'
import UsefulLinksSection from './UsefulLinksSection'
import VenueDetails from './VenueDetails'
import DateTimeDetails from './DateTimeDetails'
import MainBanner from './MainBanner'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import LanguageToggle from '../languages/LanguageToggle'

const HomePage = () => {
    useEffect(() => {
        document.title = 'Welcome | Rita & Eddy 2022'
    })

    return (
        <div className="app bg-primary min-h-[100vh]">
            <MainBanner />
            <div className="lg:flex text-white">
                <div className="w-full lg:w-1/4 p-4 md:px-6 lg:px-4">
                    <div className="lg:top-4 lg:sticky lg:max-h-[100vh] md:overflow-y-auto">
                        <div className="md:flex lg:block">
                            <div className="w-full md:w-1/2 lg:w-full md:pr-4 lg:pr-0">
                                <div className="mb-4 m-auto">
                                    <LanguageToggle
                                        light={true} />
                                </div>
                                <DateTimeDetails />
                                <div className="lg:hidden">
                                    <UsefulLinksSection />
                                </div>
                            </div>
                            <div className="w-full md:my-0 lg:my-3 md:w-1/2 lg:w-full md:pl-4 lg:pl-0">
                                <VenueDetails />
                            </div>
                        </div>
                        <div className="hidden lg:block">
                            <hr className="mt-12 mb-6" />
                            <p className="mb-6 text-xl text-center">
                                { Translations.get('home_page.links.header') }
                            </p>
                            <UsefulLinksSection />
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-3/4 bg-white text-black">
                    <Posts />
                </div>
                <div className="w-full px-8 mt-6 lg:hidden">
                    <p className="mb-6 text-xl text-center">
                        { Translations.get('home_page.links.header') }
                    </p>
                    <UsefulLinksSection />
                </div>
            </div>
            <div className="lg:relative lg:left-1/4 lg:w-3/4 pt-1 pb-2 bg-texture-[asfalt-light]">
                <p className="text-white text-center lg:pr-2">
                    { Translations.get('home_page.footer') }
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(HomePage)
