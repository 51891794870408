import _ from 'lodash'
import AnimatedBalloon from './AnimatedBalloon'

const MAX_BALLOONS_COUNT = 12

const getRandomDelay = () => {
    const delay = Math.floor(Math.random() * 10000)
    return delay
}

const getRandomRightPosition = () => {
    return Math.floor(Math.random() * 200)
}

const AnimatedBalloons = () => (
    _.range(1, MAX_BALLOONS_COUNT).map((_, i) =>
        <div key={i} className="absolute"
            style={{
                right: `${i * getRandomRightPosition()}px`,
            }}>
            <AnimatedBalloon balloonType={i % 2 === 1 ? 'rgb' : 'champagne'}
                animationDelay={
                `-${i * getRandomDelay() + 2000}ms`}/>
        </div>
    )
)

export default AnimatedBalloons
