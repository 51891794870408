import { clearExtendedSession } from '../../invitation_screening/ducks/actions'

const namespace = 'router'

export const INVITATION_PAGE = `${namespace}/INVITATION_PAGE`
export const HOME_PAGE = `${namespace}/HOME_PAGE`
export const RSVP_PAGE = `${namespace}/RSVP_PAGE`
export const ATTENDEES_PAGE = `${namespace}/ATTENDEES_PAGE`
export const WEDDING_PHOTOGRAPHS_PAGE = `${namespace}/WEDDING_PHOTOGRAPHS_PAGE`
export const LOGIN_PAGE = `${namespace}/LOGIN_PAGE`
export const PROJECTION_PAGE = `${namespace}/PROJECTION_PAGE`
export const NEW_PROJECTED_COMMENT_PAGE = `${namespace}/NEW_PROJECTED_COMMENT_PAGE`
export const PROJECTED_COMMENTS_ADMIN_PAGE = `${namespace}/PROJECTED_COMMENTS_ADMIN_PAGE`
export const WEDDING_PHOTOGRAPHS_ADMIN_PAGE = `${namespace}/WEDDING_PHOTOGRAPHS_ADMIN_PAGE`
export const RESET_SESSION = `${namespace}/RESET_SESSION`

export const NAVIGATE_PAGE_TO = `${namespace}/NAVIGATE_PAGE_TO`

export const navigateTo = (pageId) => ({
    type:  NAVIGATE_PAGE_TO,
    payload: {
        pageId
    }
})

export const redirectToHomePage = () => navigateTo(HOME_PAGE)

export const redirectToRsvpPage = () => navigateTo(RSVP_PAGE)

export const redirectToWeddingPhotographsPage = () => navigateTo(WEDDING_PHOTOGRAPHS_PAGE)

export const redirectToAttendeesPage = () => navigateTo(ATTENDEES_PAGE)

export const redirectToProjectionPage = () => navigateTo(PROJECTION_PAGE)

export const redirectToProjectedCommentsAdminPage = () => navigateTo(PROJECTED_COMMENTS_ADMIN_PAGE)

export const redirectToWeddingPhotographsAdminPage = () => navigateTo(WEDDING_PHOTOGRAPHS_ADMIN_PAGE)

export const resetCurrentSession = () => (dispatch, getState) => {
    clearExtendedSession()
    dispatch({ type: RESET_SESSION })
}

// auto refresh for changes
// const pageLoadedMoment = m(Date.now())
// const periodicPageReload = setInterval(() => {
//     if (m().diff(pageLoadedMoment, 'hours') > 1)
//        window.location.reload()
// }, 5000)
