import PropTypes from 'prop-types'
import Translations from '../translations'

const RemoveAttendeeFieldButton = ({ onClick }) => (
    <div className="group">
        <button type="button"
            className="text-red-400 border border-red-400 group-hover:bg-red-400 group-hover:text-white"
            onClick={ onClick }>
            <span className="block py-2 px-4">{ Translations.get('rsvp_form.guests.remove') }</span>
            <span className="w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b border-white"></span>
        </button>
    </div>
)

RemoveAttendeeFieldButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default RemoveAttendeeFieldButton
