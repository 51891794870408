import { createSelector } from 'reselect'

export const selectWeddingGuests = (state) => state.attendees.weddingGuests || []

export const selectAttendees = (state) => state.attendees.attendeesDataArray

export const selectContacts = (state) => state.attendees.contactsDataset

export const selectFetchAttendeesErrors = createSelector(
    state => state.attendees.fetchAttendeesErrors,
    fetchAttendeesErrors => fetchAttendeesErrors
)

export const selectRsvpHistoryVisible = createSelector(
    state => state.attendees.rsvpHistoryVisible,
    rsvpHistoryVisible => rsvpHistoryVisible
)

export const selectRsvpHistories = createSelector(
    state => state.attendees.rsvpHistories,
    rsvpHistories => rsvpHistories
)

export const selectAbsentGuestsVisible = createSelector(
    state => state.attendees.absentGuestsVisible,
    isVisible => isVisible
)

export const selectEditRsvpFormVisible = createSelector(
    state => state.attendees.editRsvpFormVisible,
    isVisible => isVisible
)
