import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../loaders/Spinner'
import { cancelSurvey, respondToSurvey }
    from './ducks/actions'
import { selectSurveyIsProcessing,
    selectUserResponseToEddySurvey,
    selectUserResponseToRitaSurvey,
    selectEddySurveyPositiveResponses,
    selectEddySurveyNegativeResponses,
    selectRitaSurveyPositiveResponses,
    selectRitaSurveyNegativeResponses } from './ducks/selectors'
import HoverUnderlineButton from '../buttons/HoverUnderlineButton'

const Post_3_Survey = ({ targetSurvey, isProcessing, onCancel, onPositiveClick,
    onNegativeClick, userResponseToEddySurvey, userResponseToRitaSurvey,
    eddySurveyPositiveResponses, eddySurveyNegativeResponses,
    ritaSurveyPositiveResponses, ritaSurveyNegativeResponses }) => {

    const handlePositiveClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onPositiveClick(targetSurvey)
    }

    const handleNegativeClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onNegativeClick(targetSurvey)
    }

    const alreadyResponded = () => {
        switch(targetSurvey) {
            case 'eddySurvey': {
                return typeof userResponseToEddySurvey !== 'undefined'
            }
            case 'ritaSurvey': {
                return typeof userResponseToRitaSurvey !== 'undefined'
            }
            default:
                return false
        }
    }

    const getUserResponse = () => {
        switch(targetSurvey) {
            case 'eddySurvey': {
                return userResponseToEddySurvey &&
                    userResponseToEddySurvey.isHumble
            }
            case 'ritaSurvey': {
                return userResponseToRitaSurvey &&
                    userResponseToRitaSurvey.isGentle
            }
            default:
                return null
        }
    }

    const getUserResponseText = () => {
        switch(targetSurvey) {
            case 'eddySurvey': {
                return (userResponseToEddySurvey &&
                    userResponseToEddySurvey.isHumble && '謙虛 😍') || '不謙虛 😭'
            }
            case 'ritaSurvey': {
                return (userResponseToRitaSurvey &&
                    userResponseToRitaSurvey.isGentle && '溫柔 😍') || '不溫柔 😭'
            }
            default:
                return null
        }
    }

    return (
        <div className={`fixed z-50 top-0 left-0 right-0 bottom-0 bg-black/80
            w-full h-[100vh] flex items-center justify-center touch-none`}
            onClick={onCancel}>
            <div className="w-[90vw] max-w-[500px] min-w-[300px] p-8 bg-white/90">
                <div className="text-center mb-8">
                    {
                        alreadyResponded()
                            ?
                            <div>
                                <p className="text-lg">感謝您的投票！</p>
                                <p className="mt-4">
                                    您選擇了 — &nbsp;
                                    <span className={ getUserResponse()
                                        ? 'text-green-600'
                                        : 'text-red-400' }>
                                        { getUserResponseText() }
                                    </span>
                                </p>
                                <div className="flex mt-4">
                                    <div className="w-1/2">
                                        <div>
                                            <span className="text-green-600 mb-2 block">
                                                { targetSurvey === 'eddySurvey'
                                                    ? '謙虛'
                                                    : '溫柔'
                                                }
                                            </span>
                                            <div className="max-h-[60vh] overflow-y-auto">
                                                {
                                                    targetSurvey === 'eddySurvey'
                                                        ? eddySurveyPositiveResponses.map(r =>
                                                            <span key={r.id} className="block">{r.voter.name}</span>)
                                                        : ritaSurveyPositiveResponses.map(r =>
                                                            <span key={r.id} className="block">{r.voter.name}</span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/2 border-l-2 border-neutral-600">
                                        <div>
                                            <span className="text-red-400 mb-2 block">
                                                { targetSurvey === 'eddySurvey'
                                                    ? '不謙虛'
                                                    : '不溫柔'
                                                }
                                            </span>
                                            <div className="max-h-[60vh] overflow-y-auto">
                                            {
                                                targetSurvey === 'eddySurvey'
                                                    ? eddySurveyNegativeResponses.map(r =>
                                                        <span key={r.id} className="block">{r.voter.name}</span>)
                                                    : ritaSurveyNegativeResponses.map(r =>
                                                        <span key={r.id} className="block">{r.voter.name}</span>)
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <span>
                                { targetSurvey === 'eddySurvey'
                                    ? '他.. 謙虛哦？'
                                    : '她真的.. 溫柔嗎？'
                                }
                            </span>
                    }
                </div>
                {
                    alreadyResponded()
                        ?
                        <div className="flex justify-center">
                            <HoverUnderlineButton onClick={ onCancel }
                                text="關閉"
                                buttonClassNames="border-2 border-neutral-600"
                                underlineClassNames="border-neutral-400"
                                textClassNames="text-black px-4 py-2" />
                        </div>
                        :
                        <div className="flex justify-center">
                            <div className="w-1/2 mr-2">
                                <HoverUnderlineButton onClick={ handlePositiveClick }
                                    buttonClassNames="border-2 border-green-500"
                                    textClassNames="px-4 py-2 text-center"
                                    underlineClassNames="border-green-600">
                                    { isProcessing
                                        ?
                                        <span className="flex justify-center">
                                            <Spinner />
                                        </span>
                                        :
                                        <span>是這樣沒錯</span>
                                    }
                                </HoverUnderlineButton>
                            </div>
                            <div className="w-1/2 mr-2">
                                <HoverUnderlineButton onClick={ handleNegativeClick }
                                    buttonClassNames="border-2 border-red-400"
                                    textClassNames="px-4 py-2 text-center"
                                    underlineClassNames="border-red-600">
                                    { isProcessing
                                        ?
                                        <span className="flex justify-center">
                                            <Spinner />
                                        </span>
                                        :
                                        <span>不是這樣的..</span>
                                    }
                                </HoverUnderlineButton>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

const surveyResponsePropType = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired,
    voter: PropTypes.shape({
        name: PropTypes.string.isRequired
    })
}))

Post_3_Survey.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    targetSurvey: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onPositiveClick: PropTypes.func.isRequired,
    onNegativeClick: PropTypes.func.isRequired,
    userResponseToEddySurvey: PropTypes.object,
    userResponseToRitaSurvey: PropTypes.object,
    eddySurveyPositiveResponses: surveyResponsePropType,
    eddySurveyNegativeResponses: surveyResponsePropType,
    ritaSurveyPositiveResponses: surveyResponsePropType,
    ritaSurveyNegativeResponses: surveyResponsePropType
}

const mapStateToProps = (state) => ({
    isProcessing: selectSurveyIsProcessing(state),
    userResponseToEddySurvey: selectUserResponseToEddySurvey(state),
    userResponseToRitaSurvey: selectUserResponseToRitaSurvey(state),
    eddySurveyPositiveResponses: selectEddySurveyPositiveResponses(state),
    eddySurveyNegativeResponses: selectEddySurveyNegativeResponses(state),
    ritaSurveyPositiveResponses: selectRitaSurveyPositiveResponses(state),
    ritaSurveyNegativeResponses: selectRitaSurveyNegativeResponses(state)
})

const mapDispatchToProps = (dispatch) => ({
    onCancel: () => dispatch(cancelSurvey()),
    onPositiveClick: (targetSurvey) => { dispatch(respondToSurvey(targetSurvey, true)) },
    onNegativeClick: (targetSurvey) => { dispatch(respondToSurvey(targetSurvey, false)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(Post_3_Survey)
