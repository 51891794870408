import PropTypes from 'prop-types'

const Link = ({ text, onClick, download }) => (
    <div className="group inline">
        <button type="button"
            className={`text-md md:text-xl text-secondary
                group-hover:bg-secondary group-hover:text-white`}
            onClick={ onClick }>
            <span className="border-2 border-secondary block py-2 px-4">
                { text }
            </span>
            <span className="w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b-2 border-white"></span>
        </button>
    </div>
)

Link.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    download: PropTypes.object
}

export default Link
