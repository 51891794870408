const audioSources = Array.from([
    { fileName: 'The_Verve.Bitter_Sweet_Symphony', repeat: false, artist: 'The Verve', title: 'Bitter Sweet Symphony', duration: { minutes: 4, seconds: 36 } },
    { fileName: 'Coldplay.Yellow', repeat: false, artist: 'Coldplay', title: 'Yellow', duration: { minutes: 4, seconds: 32 } },
    { fileName: 'Bésame_Mucho.Andrea_Bocelli', repeat: false, artist: 'Andrea Bocelli', title: 'Bésame Mucho', duration: { minutes: 4, seconds: 1 } },
    { fileName: 'LaurenAquilina_King', repeat: false, artist: 'Lauren Aquilina', title: 'King', duration: { minutes: 3, seconds: 59 } },
    { fileName: 'Gabrielle_Aplin.Nothing_Really_Matters', repeat: false, artist: 'Gabrielle Aplin', title: 'Nothing Really Matters', duration: { minutes: 3, seconds: 8 } },
    { fileName: 'Gabrielle_Aplin_&_Nina_Nesbitt.Miss_You_2', repeat: false, artist: 'Gabrielle Aplin & Nina Nesbitt', title: 'Miss You 2', duration: { minutes: 3, seconds: 30 } },
    { fileName: 'Birdy_WildHorses', repeat: false, artist: 'Birdy', title: 'Wild Horses', duration: { minutes: 3, seconds: 17 } },
    { fileName: '40_Fingers.Africa', repeat: true, artist: '40 Fingers', title: 'Africa', duration: { minutes: 4, seconds: 8 } },
    { fileName: '2Cellos.With_or_Without_You', repeat: true, artist: '2CELLOS', title: 'With or Without You', duration: { minutes: 4, seconds: 52 } },
    { fileName: 'The_Piano_Guys.A_Thousand_Years.Instrumental', repeat: true, artist: 'The Piano Guys', title: 'A Thousand Years', duration: { minutes: 4, seconds: 30 } },
    { fileName: '2Cellos.Hallelujah', repeat: true, artist: '2CELLOS', title: 'Hallelujah', duration: { minutes: 3, seconds: 48 }  },
    { fileName: '40_Fingers.With_or_Without_You', repeat: true, artist: '40 Fingers', title: 'With or Without You', duration: { minutes: 3, seconds: 48 } },
    { fileName: 'You_Raise_Me_Up.Instrumental', repeat: true, artist: 'Tritonia Music', title: 'You Raise Me Up', duration: { minutes: 4, seconds: 6 } },
    { fileName: '40_Fingers.Hallelujah', repeat: true, artist: '40 Fingers', title: 'Hallelujah', duration: { minutes: 2, seconds: 57 } },
]).map((src, i) => {
    const data = {
        playOrder: i + 1,
        normalisedMp3Url: `/wedding_audio/normalised/mp3/${src.fileName}.mp3`,
        ...src
    }

    return {
        ...data,
        url: data.normalisedMp3Url
    }
})

const getTotalPlaybackDurationInSeconds = (sources) => sources.reduce((t, src) => {
    return t + (src.duration.minutes * 60) + src.duration.seconds
}, 0)

export const getTotalPlaybackDuration = (sources = audioSources) => {
    const inSeconds = getTotalPlaybackDurationInSeconds(sources)
    return {
        minutes: Math.floor(inSeconds / 60),
        seconds: inSeconds % 60
    }
}

export const getRepeatablePlaybackDuration = () => {
    const repeatableSources = audioSources.filter(src => src.repeat)

    // perform check of at least 1 repeatable file
    if (repeatableSources.length === 0)
        throw new Error('audioSources must have at least 1 repeatable source')

    return getTotalPlaybackDuration(repeatableSources)
}

// calc total playback duration
// const printTotalPlaybackDuration = () => {
//     const { minutes, seconds } = getTotalPlaybackDuration(audioSources)
//     console.log('Total playback duration: ',
//         `${minutes} minutes ${seconds.toString().padStart(2, '0')} seconds`)
// }

// // calc total repeatable playback duration
// const printTotalRepeatablePlaybackDuration = () => {
//     const { minutes, seconds } = getRepeatablePlaybackDuration()
//     console.log('Repeatable playback duration: ',
//         `${minutes} minutes ${seconds.toString().padStart(2, '0')} seconds`)
// }

// printTotalPlaybackDuration()
// printTotalRepeatablePlaybackDuration()

export default audioSources
