import {
    FETCH_COMMENTS_DID_SUCCEED,
    FETCH_COMMENTS_DID_FAIL,
    DELETE_COMMENT,
    CANCEL_DELETE_COMMENT,
    DELETE_COMMENT_DID_SUCCEED,
    DELETE_COMMENT_DID_FAIL,
    DELETE_COMMENT_DID_START,
    DELETE_COMMENT_DID_END,
    DELETE_COMMENT_DID_ERROR
} from './actions'

const getInitialDeleteStates = () => ({
    deleteErrors: {},
    deleteUncaughtError: null,
    deleteComment: {}
})

const initialState = {
    comments: {},
    deleteConfirmationVisible: false,
    commentIsDeleting: false,
    ...getInitialDeleteStates()
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case DELETE_COMMENT_DID_ERROR: {
            return {
                ...state,
                deleteUncaughtError: action.payload.error
            }
        }
        case DELETE_COMMENT_DID_START: {
            return {
                ...state,
                commentIsDeleting: true,
                ...getInitialDeleteStates(),
                deleteComment: state.deleteComment
            }
        }
        case DELETE_COMMENT_DID_END: {
            return {
                ...state,
                commentIsDeleting: false
            }
        }
        case DELETE_COMMENT_DID_SUCCEED: {
            const { comment } = action.payload

            return {
                ...state,
                comments: {
                    ...state.comments,
                    [comment.refId]: state.comments[comment.refId].filter(
                        c => c.id !== comment.id)
                },
                deleteConfirmationVisible: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT_DID_FAIL: {
            return {
                ...state,
                deleteErrors: action.payload.errors
            }
        }
        case FETCH_COMMENTS_DID_SUCCEED: {
            const { comments } = action.payload

            return {
                ...state,
                comments: comments.reduce((obj, c) => ({
                    ...obj,
                    [c.refId]: [
                        ...(obj[c.refId] || []),
                        c
                    ]
                }), {})
            }
        }
        case FETCH_COMMENTS_DID_FAIL: {
            console.error('fetch error', action.payload.errors)
            return {
                ...state
            }
        }
        case DELETE_COMMENT: {
            return {
                ...state,
                deleteConfirmationVisible: true,
                ...getInitialDeleteStates(),
                deleteComment: action.payload.comment
            }
        }
        case CANCEL_DELETE_COMMENT: {
            return {
                ...state,
                deleteConfirmationVisible: false,
                ...getInitialDeleteStates()
            }
        }
        default:
            return state
    }
}

export default reducer
