import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProjectedQRCode from './ProjectedQRCode'
import { selectCurrentImage, selectImageSources, selectCurrentImageIndex,
    selectAnimatedBalloonsVisible } from './ducks/selectors'
import { getNextImage, toggleBalloons } from './ducks/actions'
import ProjectedImage from './ProjectedImage'
import { selectSearchParams } from '../router/ducks/selectors'
import AnimatedBalloons from './AnimatedBalloons'

// 15 seconds by default
const CYCLE_INTERVAL = (selectSearchParams().get(
    'slideDuration') || 15) * 1000

// only allow one timeout instance per projection
const cycler = {
    image: null,
    audio: null
}

// preload all images
const ProjectedGallery = ({ currentImage, onImageEnd,
    imageSources, currentImageIndex, onToggleBalloons, animatedBalloonsVisible }) => {

    useEffect(() => {
        cycler.image = setTimeout(onImageEnd, CYCLE_INTERVAL)

        document.title = `Projection #${ currentImageIndex + 1 } | Rita & Eddy`

        const animationToggle = (e) => {
            if (e.key === 'b' || e.code === 'KeyB')
                onToggleBalloons()
        }

        window.addEventListener('keydown', animationToggle)

        return () => {
            clearTimeout(cycler.image)
            window.removeEventListener('keydown', animationToggle)
        }
    })

    const qrCodeText = `${window.origin}/projected_comments/new?ref_id=${currentImage.refId}`

    return (
        <div className="relative h-[100vh] overflow-hidden bg-texture-[asfalt-light]">
            <div className="w-full h-full bg-black/80">
                { animatedBalloonsVisible &&
                    <div className="absolute z-10 top-0 right-[85px] bottom-0 h-full">
                        <AnimatedBalloons />
                    </div>
                }
                <div className="relative z-20 flex w-full h-full justify-center">
                    { imageSources.map((image, i) =>
                        <ProjectedImage
                            key={i}
                            image={ image }
                            visible={ currentImageIndex === i } />
                    )}
                </div>
                <div className="absolute top-3 left-6 bg-black/50 text-white text-center z-20">
                    <p className="w-full text-sm sm:text-lg">掃描 QR 留言</p>
                    <div className="flex justify-center">
                        <div className="hidden sm:block">
                            <ProjectedQRCode text={ qrCodeText } />
                        </div>
                        <div className="sm:hidden">
                            <ProjectedQRCode text={ qrCodeText } scale={2} />
                        </div>
                    </div>
                    <p className="w-full text-xs sm:text-sm">Scan to Comment</p>
                </div>
            </div>
        </div>
    )
}

ProjectedGallery.propTypes = {
    currentImage: PropTypes.object.isRequired,
    currentImageIndex: PropTypes.number.isRequired,
    onImageEnd: PropTypes.func.isRequired,
    imageSources: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        highResolutionUrl: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })).isRequired,
    onToggleBalloons: PropTypes.func.isRequired,
    animatedBalloonsVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    currentImage: selectCurrentImage(state),
    currentImageIndex: selectCurrentImageIndex(state),
    imageSources: selectImageSources(),
    animatedBalloonsVisible: selectAnimatedBalloonsVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onImageEnd: () => dispatch(getNextImage()),
    onToggleBalloons: () => dispatch(toggleBalloons())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectedGallery)
