import Translations from '../../translations'

const namespace = 'LANGUAGES'

export const UPDATE_PREFERRED_LANGUAGE = `${namespace}/UPDATE_PREFERRED_LANGUAGE`

// code : Name
export const AVAILABLE_LANGUAGES = {
    'en': 'English',
    'zh': '繁體中文'
}

export const updatePreferredLanguage = (code) => {
    Translations.setPreferredLanguage(code)

    return {
        type: UPDATE_PREFERRED_LANGUAGE,
        payload: code
    }
}
