import { createSelector } from 'reselect'
import imageSources from '../../on_the_day_projection/ducks/image_sources'

export const selectImageSources = () => imageSources

export const selectTotalImageSources = () => selectImageSources().length

export const selectCurrentImageIndex = createSelector(
    state => state.onTheDayUsers.currentImageIndex,
    i => i
)

export const selectCurrentImage = createSelector(
    state => state.onTheDayUsers.currentImageIndex,
    i => imageSources[i]
)

export const selectNewComment = createSelector(
    state => state.onTheDayUsers.newComment,
    comment => comment
)

export const selectRefId = createSelector(
    state => state.onTheDayUsers.refId,
    refId => refId
)

export const selectComments = createSelector(
    state => state.onTheDayUsers.comments,
    comments => comments
)

export const selectSubmitCommentUncaughtError = createSelector(
    state => state.onTheDayUsers.submitCommentUncaughtError,
    error => error
)

export const selectSubmitCommentErrors = createSelector(
    state => state.onTheDayUsers.submitCommentErrors,
    errors => errors
)

export const selectCommentIsSubmitting = createSelector(
    state => state.onTheDayUsers.commentIsSubmitting,
    isSubmitting => isSubmitting
)

export const selectCommentIsSubmitted = createSelector(
    state => state.onTheDayUsers.commentIsSubmitted,
    isSubmitted => isSubmitted
)

export const selectCommenterOnceOffId = createSelector(
    state => state.onTheDayUsers.commenterOnceOffId,
    uuid => uuid
)

export const selectDeleteCommentErrors = createSelector(
    state => state.onTheDayUsers.deleteCommentErrors,
    errors => errors
)

export const selectDeleteCommentUncaughtError = createSelector(
    state => state.onTheDayUsers.deleteCommentUncaughtError,
    error => error
)

export const selectShowDeleteCommentConfirmation = createSelector(
    state => state.onTheDayUsers.showDeleteCommentConfirmation,
    showConfirmation => showConfirmation
)

export const selectDeleteComment = createSelector(
    state => state.onTheDayUsers.deleteComment,
    comment => comment
)

export const selectCommentIsDeleting = createSelector(
    state => state.onTheDayUsers.commentIsDeleting,
    isDeleting => isDeleting
)
