import m from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DeleteCommentButton from './DeleteCommentButton'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { selectCommenterOnceOffId } from './ducks/selectors'
import { selectIsAdmin } from '../invitation_screening/ducks/selectors'

const Comment = ({ comment: c, commenterOnceOffId, deleteComment, isAdmin }) => (
    <div className={`text-white mb-2 p-4 ${
        c.commenterOnceOffId === commenterOnceOffId
            ? 'bg-secondary/90'
            : 'bg-secondary/60'
    }`}>
        <pre className="whitespace-pre-wrap font-sans">{ c.text }</pre>
        <p className="text-right mt-2">
            — { c.commenterName }
            <span className="block">{ m(c.createdAt).format('Y.M.D | h:mm a') }</span>
        </p>
        {
            isAdmin || (c.commenterOnceOffId === commenterOnceOffId)
                ?
                <div className="text-right mt-4">
                    <DeleteCommentButton comment={c} />
                </div>
                : null
        }
    </div>
)

Comment.propTypes = {
    comment: PropTypes.shape({
        text: PropTypes.string.isRequired,
        commenterName: PropTypes.string.isRequired,
        commenterOnceOffId: PropTypes.string.isRequired,
    }),
    commenterOnceOffId: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state),
    commenterOnceOffId: selectCommenterOnceOffId(state),
    isAdmin: selectIsAdmin(state)
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
