import BannerImage from './BannerImage'
import RegentHotel from '../google_maps/RegentHotelGoogleMap'

const Post_2 = () => (
    <div>
        <BannerImage
            highResUrl="/wedding_photographs_original/KOU_0218.jpg"
            lowResUrl="/wedding_photographs_previews/KOU_0218.jpg">
            <div className="text-white absolute top-[25px] left-[10px] md:left-[25px]">
                <p className="text-[50px] md:text-[75px] lg:text-[100px]">About our wedding</p>
            </div>
        </BannerImage>

        <div className="px-8 mt-8 text-md md:text-2xl text-primary leading-loose md:leading-loose">
            <p className="text-2xl md:text-4xl text-center">今天, 我想來點<span className="animate-pulse">...</span></p>
            <p className="text-2xl md:text-4xl text-center md:mt-4">幸福蔬食盛宴</p>
            <p className="mt-12">
                <a href="/rita-eddy.ics" download="Rita & Eddy婚宴.ics">
                    <span className="text-secondary">日期 — </span>
                    2022.11.25（星期五）
                </a>
            </p>
            <p className="mt-6">
                <a href="/rita-eddy.ics" download="Rita & Eddy婚宴.ics">
                    <span className="text-secondary">時間 — </span>
                    18:00入席； 18:30開席
                </a>
            </p>
            <div className="mt-6">
                <a target="new"
                    href="https://maps.google.com/maps?ll=25.054213,121.524187&z=15&t=m&hl=en&gl=TW&mapclient=embed&cid=7944188266752151210">
                    <span className="text-secondary">地點 — </span>
                    <span>台北晶華酒店</span><br />
                    <span className="ml-14 md:ml-20">4樓 萬象廳</span><br />
                    <span className="ml-14 md:ml-20">台北市 中山區</span><br/>
                    <span className="ml-14 md:ml-20">中山北路二段39巷3號</span>
                </a>
            </div>
            <div className="w-full my-12 border border-secondary h-[100vh] max-h-[300px] lg:max-h-[500px]">
                <RegentHotel />
            </div>
        </div>
    </div>
)

export default Post_2
