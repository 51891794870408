import PropTypes from 'prop-types'
import Link from './Link'
import { redirectToWeddingPhotographsPage } from './ducks/actions'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const ViewWeddingPhotographsButton = ({ onViewWeddingPhotographsClick }) => (
    <Link
        text={ Translations.get('posts.view_gallery_button') }
        onClick={ onViewWeddingPhotographsClick } />
)

ViewWeddingPhotographsButton.propTypes = {
    onViewWeddingPhotographsClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onViewWeddingPhotographsClick: () =>
        dispatch(redirectToWeddingPhotographsPage())
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewWeddingPhotographsButton)
