import { createAction } from '@reduxjs/toolkit'
import config from '../../config'

const namespace = 'my-portfolio/reducer/portfolio'

export const INCREMENT_MEDIA_INDEX = `${namespace}/INCREMENT_MEDIA_INDEX`
export const DECREMENT_MEDIA_INDEX = `${namespace}/DECREMENT_MEDIA_INDEX`
export const UPDATE_MEDIA_INDEX = `${namespace}/UPDATE_MEDIA_INDEX`
export const SHOW_ORIGINAL_MEDIA = `${namespace}/SHOW_ORIGINAL_MEDIA`
export const CLOSE_ORIGINAL_MEDIA = `${namespace}/CLOSE_ORIGINAL_MEDIA`
export const SUBMIT_COMMENT = `${namespace}/SUBMIT_COMMENT`
export const UPDATE_COMMENT = `${namespace}/UPDATE_COMMENT`
export const UPDATE_MEDIA_INDEX_BY_ITEM = `${namespace}/UPDATE_MEDIA_INDEX_BY_ITEM`

// post comment
export const SUBMIT_COMMENT_DID_START = `${namespace}/SUBMIT_COMMENT_DID_START`
export const SUBMIT_COMMENT_DID_END = `${namespace}/SUBMIT_COMMENT_DID_START`
export const SUBMIT_COMMENT_DID_ERROR = `${namespace}/SUBMIT_COMMENT_DID_ERROR`
export const SUBMIT_COMMENT_DID_SUCCEED = `${namespace}/SUBMIT_COMMENT_DID_SUCCEED`
export const SUBMIT_COMMENT_DID_FAIL = `${namespace}/SUBMIT_COMMENT_DID_FAIL`

// fetch comments
export const FETCH_COMMENTS_DID_START = `${namespace}/FETCH_COMMENTS_DID_START`
export const FETCH_COMMENTS_DID_END = `${namespace}/FETCH_COMMENTS_DID_END`
export const FETCH_COMMENTS_DID_ERROR = `${namespace}/FETCH_COMMENTS_DID_ERROR`
export const FETCH_COMMENTS_DID_SUCCEED = `${namespace}/FETCH_COMMENTS_DID_SUCCEED`
export const FETCH_COMMENTS_DID_FAIL = `${namespace}/FETCH_COMMENTS_DID_FAIL`

// delete comment
export const CANCEL_DELETE_COMMENT = `${namespace}/CANCEL_DELETE_COMMENT`
export const DELETE_COMMENT = `${namespace}/DELETE_COMMENT`
export const DELETE_COMMENT_DID_SUCCEED = `${namespace}/DELETE_COMMENT_DID_SUCCEED`
export const DELETE_COMMENT_DID_FAIL = `${namespace}/DELETE_COMMENT_DID_FAIL`
export const DELETE_COMMENT_DID_START = `${namespace}/DELETE_COMMENT_DID_START`
export const DELETE_COMMENT_DID_END = `${namespace}/DELETE_COMMENT_DID_END`
export const DELETE_COMMENT_DID_ERROR = `${namespace}/DELETE_COMMENT_DID_ERROR`

export const confirmDeleteComment = createAction(
    DELETE_COMMENT,
    (comment) => ({
        payload: {
            deleteComment: comment
        }
    })
)

export const deleteCommentCancelled = createAction(
    CANCEL_DELETE_COMMENT
)

export const deleteCommentConfirmed = () => (dispatch, getState) => {
    const { deleteComment } = getState().weddingPhotographs
    const { invitationCode } = getState().invitationScreening

    if (deleteComment) {
        dispatch({
            type: DELETE_COMMENT_DID_START
        })

        fetch(`${config.API_BASE_URL}/comments/${
            deleteComment.id}?invitationCode=${invitationCode}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.status === 0) {
                dispatch({
                    type: DELETE_COMMENT_DID_SUCCEED
                })
            } else {
                dispatch({
                    type: DELETE_COMMENT_DID_FAIL,
                    payload: {
                        errors: json.data.errors
                    }
                })
            }
        })
        .catch(err => {
            dispatch({
                type: DELETE_COMMENT_DID_ERROR,
                payload: {
                    error: err
                }
            })
        })
        .finally(() => {
            dispatch({
                type: DELETE_COMMENT_DID_END
            })
        })
    }
}

export const incrementGalleryMediaIndex = createAction(
    INCREMENT_MEDIA_INDEX
)

export const decrementGalleryMediaIndex = createAction(
    DECREMENT_MEDIA_INDEX
)

export const setNextMediaIndex = createAction(
    UPDATE_MEDIA_INDEX,
    (index) => ({
        payload: {index}
    })
)

// alternative way to set next media index
export const setNextMediaItem = createAction(
    UPDATE_MEDIA_INDEX_BY_ITEM,
    (mediaItem) => ({
        payload: {mediaItem}
    })
)

export const viewOriginalMedia = createAction(
    SHOW_ORIGINAL_MEDIA
)

export const closeOriginalMedia = createAction(
    CLOSE_ORIGINAL_MEDIA
)

export const fetchComments = (refId) => (dispatch, getState) => {
    dispatch({ type: FETCH_COMMENTS_DID_START })

    fetch(`${config.API_BASE_URL}/comments?${(refId && `refId=${encodeURI(refId)}`) || ''}`, {
        method: 'GET',
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_COMMENTS_DID_SUCCEED,
                payload: {
                    refId,
                    comments: json.data.comments
                }
            })
        } else {
            console.error('fetch error', json)
        }
    })
}

export const submitComment = () => (dispatch, getState) => {
    dispatch({ type: SUBMIT_COMMENT_DID_START })

    const state = getState()
    const comment = state.weddingPhotographs.newComment
    const invitationCode = state.invitationScreening.invitationCode

    fetch(`${config.API_BASE_URL}/comments`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            comment: {
                text: comment.text.trim(),
                refId: comment.refId
            },
            invitationCode: invitationCode
        })
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: SUBMIT_COMMENT_DID_SUCCEED,
                payload: {
                    comment: json.data.comment
                }
            })
        } else {
            dispatch({
                type: SUBMIT_COMMENT_DID_FAIL,
                payload: {
                    errors: json.errors
                }
            })
        }
    })
    .catch(err => dispatch({
        type: SUBMIT_COMMENT_DID_ERROR,
        payload: {
            error: err
        }
    }))
}

export const updateComment = createAction(
    UPDATE_COMMENT,
    (refId, text) => ({
        payload: { refId, text }
    })
)
