import { createSelector } from 'reselect'
import imageSources from './image_sources'
import { getTotalPlaybackDuration, getRepeatablePlaybackDuration, default as audioSources} from './audio_sources'

export const selectImageSources = () => imageSources

export const selectAudioSources = () => audioSources

export const selectCurrentImageIndex = createSelector(
    state => state.onTheDayProjection.currentImageIndex,
    i => i
)

export const selectCurrentImage = createSelector(
    state => state.onTheDayProjection.currentImageIndex,
    i => imageSources[i]
)

export const selectCurrentAudio = createSelector(
    state => state.onTheDayProjection.currentAudioIndex,
    i => audioSources[i]
)

export const selectComments = createSelector(
    state => state.onTheDayProjection.comments,
    comments => comments
)

export const selectProjectionHelpVisible = createSelector(
    state => state.onTheDayProjection.projectionHelpVisible,
    visible => visible
)

export const selectAudioIsPaused = createSelector(
    state => state.onTheDayProjection.audioIsPaused,
    isPaused => isPaused
)

export const selectAudioIsMuted = createSelector(
    state => state.onTheDayProjection.audioIsMuted,
    isMuted => isMuted
)

export const selectNowPlayingIsVisible = createSelector(
    state => state.onTheDayProjection.nowPlayingIsVisible,
    isVisible => isVisible
)

export const selectAudioIsFetching = createSelector(
    state => state.onTheDayProjection.audioIsFetching,
    isFetching => isFetching
)

export const selectPlaylistIsVisible = createSelector(
    state => state.onTheDayProjection.playlistIsVisible,
    isVisible => isVisible
)

export const selectTotalPlaylistDuration = () => {
    return getTotalPlaybackDuration()
}

export const selectRepeatablePlaylistDuration = () => {
    return getRepeatablePlaybackDuration()
}

export const selectAnimatedBalloonsVisible = createSelector(
    state => state.onTheDayProjection.animatedBalloonsVisible,
    isVisible => isVisible
)
