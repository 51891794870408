import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLeftArrowVisibility } from './ducks/selectors'
import { decrementGalleryMediaIndex } from './ducks/actions'

const LeftArrow = ({visible, onClick}) => (
    <div className={`w-[30px] md:w-[50px] cursor-pointer ${visible ? '' : 'invisible'}`}
        onClick={onClick}>
        <svg version="1.1"
            className="stroke-secondary-500 hover:stroke-secondary-300 animate-pulse hover:animate-none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 59.414 59.414">
            <polygon
                fill="#FFFFFF"
                strokeWidth="2"
                points="45.268,1.414 43.854,0 14.146,29.707 43.854,59.414 45.268,58 16.975,29.707 "/>
        </svg>
    </div>
)

LeftArrow.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    visible: getLeftArrowVisibility(state)
})

const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch(decrementGalleryMediaIndex())
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftArrow)
