import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { editAttendee, endEditAttendee } from './ducks/actions'
import { selectEditRsvpFormVisible } from './ducks/selectors'
import HoverUnderlineButton from '../buttons/HoverUnderlineButton'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const EditAttendeeButton = ({ editRsvpFormVisible, onEdit, onClose }) => {
    const handleClick = () => {
        if (editRsvpFormVisible) {
            onClose()
        } else {
            onEdit()
        }
    }

    const buttonText = editRsvpFormVisible
        ? Translations.get('attendees_page.edit_attendee_button.close')
        : Translations.get('attendees_page.edit_attendee_button.edit')

    return (
        <HoverUnderlineButton text={ buttonText }
            onClick={ handleClick }
            buttonClassNames="border-0 bg-secondary text-center"
            underlineClassNames="border-red-400"
            textClassNames="py-2 px-4 text-white select-none" />
    )
}

EditAttendeeButton.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    editRsvpFormVisible: selectEditRsvpFormVisible(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onEdit: () => dispatch(editAttendee()),
    onClose: () => dispatch(endEditAttendee())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAttendeeButton)
