import PropTypes from 'prop-types'
import QRCode from 'qrcode'
import { selectSearchParams } from '../router/ducks/selectors'

const buildQr = (htmlNode, text, scale = 3) => {
    // allow qr scaling via query
    const QR_CODE_SCALE = selectSearchParams().get('qrCodeScale') || scale

    if (htmlNode) {
        QRCode.toCanvas(htmlNode, text,
            { scale: QR_CODE_SCALE },
            (err) => {
                if (err) console.error(err)
            }
        )
    }
}

const ProjectedQRCode = ({ text, scale }) => {
    return (
        <canvas ref={ (el) => buildQr(el, text, scale) } />
    )
}

ProjectedQRCode.propTypes = {
    text: PropTypes.string.isRequired,
    scale: PropTypes.number // optional
}

export default ProjectedQRCode
