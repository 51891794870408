import PropTypes from 'prop-types'

const Link = ({ text, onClick, children }) => (
    <div className="group">
        <button type="button"
            className="mb-4 w-full bg-secondary text-white group-hover:bg-primary"
            onClick={ onClick }>
            <span className="block px-4 py-2">{children || text}</span>
            <span className="block w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b-4 border-secondary"></span>
        </button>
    </div>
)

Link.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired
}

export default Link
