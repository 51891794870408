import { HIDE_ARROW_DOWN, SHOW_ARROW_DOWN } from './actions'

const initialState = {
    displayArrowDown: true
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case HIDE_ARROW_DOWN: {
            return {
                ...state,
                displayArrowDown: false
            }
        }
        case SHOW_ARROW_DOWN: {
            return {
                ...state,
                displayArrowDown: true
            }
        }
        default:
            return state
    }
}

export default reducer
