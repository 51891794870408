import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectContacts } from './ducks/selectors'
import { fetchRsvpHistory } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const AdditionalGuestTableRow = ({ guest, rowNumber, contacts, onViewRsvpHistory }) => (
    <div className="table-row">
        <div className="table-cell p-4">{ rowNumber }</div>
        <div className="table-cell p-4">{ guest.name }</div>
        <div className="table-cell p-4">{ Translations.get('attendees_page.table.row.is_attending.yes') }</div>
        <div className="table-cell p-4">
            { guest.childSeatRequired ?
                Translations.get('attendees_page.table.row.is_child_seat_required.yes')
                : Translations.get('attendees_page.table.row.is_child_seat_required.no')
            }
        </div>
        <div className="table-cell p-4">{ contacts[guest.contactId].name} — { contacts[guest.contactId].contactNumber }</div>
        <div className="table-cell p-4">-</div>
    </div>
)

AdditionalGuestTableRow.propTypes = {
    contacts: PropTypes.object.isRequired,
    rowNumber: PropTypes.number.isRequired,
    onViewRsvpHistory: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    contacts: selectContacts(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onViewRsvpHistory: (guestId) => dispatch(fetchRsvpHistory(guestId))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalGuestTableRow)
