import LanguageToggle from './LanguageToggle'

const TranslatablePage = ({ children }) => (
    <div>
        <div className="absolute z-30 top-4 right-3">
            <LanguageToggle />
        </div>
        { children }
    </div>
)

export default TranslatablePage
