import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AttendeeFormField from './AttendeeFormField'
import {
    addAttendeeField,
    removeAttendeeField,
    modifyAttendee,
    saveRsvpForm,
    modifyWeddingGuest
} from './ducks/actions'
import { selectAttendees, selectEditWeddingGuest, selectFormErrors,
    selectRsvpSubmitConfirmationVisible } from './ducks/selectors'
import AddAttendeeButton from './AddAttendeeButton'
import RsvpFormErrorMessages from './RsvpFormErrorMessages'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import RsvpSubmitConfirmation from './RsvpSubmitConfirmation'

const RsvpForm = ({ weddingGuest, onModifyWeddingGuest, attendees, onSubmit,
    onAddAttendeeClick, onRemoveAttendeeClick, onModifyAttendee, formErrors,
    rsvpSubmitConfirmationVisible, onSubmitSuccess }) => {

    const handleAddAttendeeClick = (e) => {
        e.preventDefault()
        onAddAttendeeClick()
    }

    const handleRemoveAttendeeClick = (e, i) => {
        e.preventDefault()
        onRemoveAttendeeClick(i)
    }

    const handleAttendeeChange = (i, prop) => {
        onModifyAttendee(i, prop)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(onSubmitSuccess)
    }

    const handleWeddingGuestChange = (e, key) => {
        e.preventDefault()
        onModifyWeddingGuest(key, e.target.value)
    }

    const hasErrorKey = (key) =>
        typeof (formErrors && formErrors[key]) !== 'undefined'

    const shouldScrollIntoView = (el, key) => {
        if (!el) return

        let shouldScroll = false

        switch(key) {
            case 'name': {
                shouldScroll = hasErrorKey(key)
                break
            }
            case 'email': {
                shouldScroll = !hasErrorKey('name') && hasErrorKey(key)
                break
            }
            case 'contactNumber': {
                shouldScroll = !hasErrorKey('name') && !hasErrorKey('email') &&
                    hasErrorKey(key)
                break
            }
            case 'isAttending': {
                shouldScroll = !hasErrorKey('name') && !hasErrorKey('email') &&
                    !hasErrorKey('contactNumber') && hasErrorKey(key)
                break
            }
            default:
                break
        }

        shouldScroll && el.scrollIntoView()
    }

    const inputFieldErrorClassNames = 'border-4 border-red-400 focus:border-red-400 focus:ring-0'

    return (
        <div>
            { rsvpSubmitConfirmationVisible &&
                <RsvpSubmitConfirmation onSubmitSuccess={ onSubmitSuccess } />
            }
            <form onSubmit={ handleSubmit }>
                <div className="relative" ref={(el) => shouldScrollIntoView(el, 'name')}>
                    <label className="text-secondary block mb-1 pl-2">
                        { Translations.get('rsvp_form.labels.name') }
                    </label>
                    <input className={`text-primary w-full py-2 px-4 peer focus:ring-sky-400 ${
                        hasErrorKey('name') && inputFieldErrorClassNames}`}
                        type="text"
                        placeholder={ Translations.get('rsvp_form.placeholders.name') }
                        autoComplete="name"
                        required={ true }
                        value={ weddingGuest.name }
                        onChange={(e) => handleWeddingGuestChange(e, 'name')} />
                    <span className="h-0 peer-focus:h-[22px] transition-[height] duration-300 ease-in-out absolute left-0 top-0 border-l-2 border-secondary"></span>
                    { <RsvpFormErrorMessages errorKey={ 'name' } /> }
                </div>
                <div className="relative mt-2" ref={(el) => shouldScrollIntoView(el, 'email')}>
                    <label className="text-secondary block mb-1 pl-2">
                        { Translations.get('rsvp_form.labels.email') }
                    </label>
                    <input className={`text-primary w-full py-2 px-4 peer focus:ring-sky-400 ${
                        hasErrorKey('email') && inputFieldErrorClassNames}`}
                        type="email"
                        placeholder={ Translations.get('rsvp_form.placeholders.email') }
                        autoComplete="email"
                        value={ weddingGuest.email }
                        onChange={(e) => handleWeddingGuestChange(e, 'email')}/>
                    <span className="h-0 peer-focus:h-[22px] transition-[height] duration-300 ease-in-out absolute left-0 top-0 border-l-2 border-secondary"></span>
                    { <RsvpFormErrorMessages errorKey={ 'email' } /> }
                </div>
                <div className="relative mt-2" ref={(el) => shouldScrollIntoView(el, 'contactNumber')}>
                    <label className="text-secondary block mb-1 pl-2">
                        { Translations.get('rsvp_form.labels.mobile') }
                    </label>
                    <input className={`text-primary w-full py-2 px-4 peer focus:ring-sky-400 ${
                        hasErrorKey('contactNumber') && inputFieldErrorClassNames}`}
                        type="tel"
                        placeholder={ Translations.get('rsvp_form.placeholders.mobile') }
                        autoComplete="tel"
                        required={true}
                        value={ weddingGuest.contactNumber }
                        onChange={(e) => handleWeddingGuestChange(e, 'contactNumber')}/>
                    <span className="h-0 peer-focus:h-[22px] transition-[height] duration-300 ease-in-out absolute left-0 top-0 border-l-2 border-secondary"></span>
                    { <RsvpFormErrorMessages errorKey={ 'contactNumber' } /> }
                </div>
                {
                    weddingGuest.name
                        ?
                        <div className="relative mt-2" ref={(el) => shouldScrollIntoView(el, 'isAttending')}>
                            <label className="text-secondary block mb-1 pl-2">
                                { Translations.get('rsvp_form.labels.is_attending') }
                            </label>
                            <select value={ weddingGuest.isAttending } required={ true }
                                className={`cursor-pointer text-center w-full focus:ring-sky-400
                                    text-white px-6 py-2 bg-secondary border-0
                                    hover:bg-secondary-700 appearance-none ${
                                    hasErrorKey('isAttending') && 'bg-red-400 focus:ring-red-300 focus:border-0'}`}
                                onChange={(e) => handleWeddingGuestChange(e, 'isAttending') }>
                                <option value="" disabled>
                                    { Translations.get('rsvp_form.placeholders.is_attending') }
                                </option>
                                <option value="true">
                                    { Translations.get('rsvp_form.is_attending.options.yes') }
                                </option>
                                <option value="false">
                                    { Translations.get('rsvp_form.is_attending.options.no') }
                                </option>
                            </select>
                            { <RsvpFormErrorMessages errorKey={ 'isAttending' } /> }
                        </div>
                        : null
                }
                <hr className="border-white my-8" />
                <p className="text-white">
                    { Translations.get('rsvp_form.guests.description') }
                </p>
                { attendees.map((a,i) =>
                    <div key={ a.uuid } className="mt-2">
                        <AttendeeFormField order={i + 1}
                            attendee={a}
                            onChange={ (prop) => handleAttendeeChange(i, prop) }
                            onRemoveClick={ (e) => handleRemoveAttendeeClick(e,i) } />
                    </div>
                )}
                <div className="mt-6">
                    <AddAttendeeButton onClick={ handleAddAttendeeClick } />
                </div>
                <div className="mt-16">
                    <button type="button"
                        onClick={ handleSubmit }
                        className="text-white bg-secondary hover:bg-secondary-700 px-8 py-2 w-full">
                        { Translations.get('rsvp_form.submit') }
                    </button>
                </div>
            </form>
        </div>
    )
}

RsvpForm.propTypes = {
    attendees: PropTypes.arrayOf(PropTypes.object),
    onAddAttendeeClick: PropTypes.func.isRequired,
    onRemoveAttendeeClick: PropTypes.func.isRequired,
    onModifyAttendee: PropTypes.func.isRequired,
    weddingGuest: PropTypes.object.isRequired,
    onModifyWeddingGuest: PropTypes.func.isRequired,
    rsvpSubmitConfirmationVisible: PropTypes.bool.isRequired,
    formErrors: PropTypes.object.isRequired,
    onSubmitSuccess: PropTypes.func, // optional
}

const mapStateToProps = (state) => ({
    attendees: selectAttendees(state),
    weddingGuest: selectEditWeddingGuest(state),
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state),
    rsvpSubmitConfirmationVisible: selectRsvpSubmitConfirmationVisible(state),
    formErrors: selectFormErrors(state)
})

const mapDispatchToProps = (dispatch) => ({
    onAddAttendeeClick: () => dispatch(addAttendeeField()),
    onRemoveAttendeeClick: (i) => dispatch(removeAttendeeField(i)),
    onModifyAttendee: (i, prop) => dispatch(modifyAttendee(i, prop)),
    onSubmit: () => dispatch(saveRsvpForm()),
    onModifyWeddingGuest: (key, value) => dispatch(modifyWeddingGuest(key, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(RsvpForm)
