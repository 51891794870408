import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCurrentMediaIndex, getTotalMediaItems } from './ducks/selectors'

const Pagination = ({currentMediaIndex, totalPages}) => (
    <div className="font-cursive text-3xl text-white select-none">
        <span>{currentMediaIndex + 1}</span> / <span>{totalPages}</span>
    </div>
)

Pagination.propTypes = {
    currentMediaIndex: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    currentMediaIndex: getCurrentMediaIndex(state),
    totalPages: getTotalMediaItems(state)
})

export default connect(mapStateToProps)(Pagination)
