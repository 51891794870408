import config from '../../config'

import {
    // HOME_PAGE,
    INVITATION_PAGE,
    NAVIGATE_PAGE_TO,
    // ATTENDEES_PAGE,
    // WEDDING_PHOTOGRAPHS_PAGE,
    // RSVP_PAGE,
    PROJECTION_PAGE,
    NEW_PROJECTED_COMMENT_PAGE,
    // PROJECTED_COMMENTS_ADMIN_PAGE
} from './actions'

const initialPage = (() => {
    const initialPathname = window.location.pathname

    switch(initialPathname) {
        case '/projection': {
            return PROJECTION_PAGE
        }
        case '/projected_comments/new': {
            return NEW_PROJECTED_COMMENT_PAGE
        }
        case '/':
        default: {
            return INVITATION_PAGE
        }
    }
})()

const initialState = {
    currentPage: (config.DEBUG_MODE && config.INITIAL_PAGE) || initialPage
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case NAVIGATE_PAGE_TO: {
            return {
                ...state,
                currentPage: action.payload.pageId
            }
        }
        default:
            return state
    }
}

export default reducer
