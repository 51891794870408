import PropTypes from 'prop-types'
import BannerDate from './BannerDate'

const BannerImage = ({ highResUrl, lowResUrl, children }) => (
    <div className="relative text-white font-cursive-banner select-none border-b-8 border-secondary">
        <div className="w-full h-[100vh] max-h-[700px]" style={{
            backgroundImage: `url(${highResUrl}), url(${lowResUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }} />
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-primary/10" />
        { children }
        <BannerDate />
    </div>
)

BannerImage.propTypes = {
    highResUrl: PropTypes.string.isRequired,
    lowResUrl: PropTypes.string.isRequired
}

export default BannerImage
