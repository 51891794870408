import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentMedia, getCurrentMediaIndex, getTotalMediaItems } from './ducks/selectors'
import { viewOriginalMedia, incrementGalleryMediaIndex,
    decrementGalleryMediaIndex } from './ducks/actions'
import DescriptionPanel from './DescriptionPanel'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'

const Gallery = ({ currentMedia, currentMediaIndex, onImageClick,
    onSwipeLeft, onSwipeRight, totalMediaItems }) => {
    // NOTE: may need tweaking if commenting allowed
    let listenToKeyboardNav = false // true

    const handleKeyDown = (e) => {
        if (e.keyCode === 37 || e.keyCode === 38) {
            // left or up
            onSwipeLeft()
        } else if (e.keyCode === 39 || e.keyCode === 40) {
            // right or down
            onSwipeRight()
        }
    }

    useEffect(() => {
        if (listenToKeyboardNav) {
            const eventListener = handleKeyDown
            window.addEventListener('keydown', eventListener)

            return () => {
                window.removeEventListener('keydown', eventListener)
            }
        }
    })

    return (
        <div className="w-full lg:flex justify-center">
            <div className="relative self-center select-none">
                { currentMediaIndex > 0 &&
                    <div className={`p-2 md:w-20 md:h-20 bg-primary/90
                        absolute top-1/2 left-5
                        lg:hidden lg:pl-12
                        flex items-center justify-center`}>
                        <LeftArrow />
                    </div>
                }

                <div className="w-full bg-black">
                    <img className="md:max-h-[600px] m-auto object-contain shadow-lg cursor-pointer"
                        src={currentMedia.url}
                        alt={currentMedia.title}
                        draggable={false}
                        onClick={() => onImageClick(currentMedia)} />
                </div>

                { currentMediaIndex < (totalMediaItems - 1) &&
                    <div className={`p-2 md:w-20 md:h-20 bg-primary/90
                        absolute top-1/2 right-5
                        lg:hidden lg:pr-12
                        flex items-center justify-center`}>
                        <RightArrow />
                    </div>
                }
            </div>

            <DescriptionPanel title={ currentMedia.title }
                refId={ currentMedia.refId } />
        </div>
    )
}

Gallery.propTypes = {
    currentMedia: PropTypes.object.isRequired,
    currentMediaIndex: PropTypes.number.isRequired,
    onImageClick: PropTypes.func.isRequired,
    onSwipeLeft: PropTypes.func.isRequired,
    onSwipeRight: PropTypes.func.isRequired,
    totalMediaItems: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    currentMedia: getCurrentMedia(state),
    currentMediaIndex: getCurrentMediaIndex(state),
    totalMediaItems: getTotalMediaItems(state)
})

const mapDispatchToProps = (dispatch) => ({
    onImageClick: (mediaObject) => dispatch(viewOriginalMedia(mediaObject)),
    onSwipeRight: () => dispatch(incrementGalleryMediaIndex()),
    onSwipeLeft: () => dispatch(decrementGalleryMediaIndex())
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
