import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { submitComment, updateComment } from './ducks/actions'
import { selectComments, selectDeleteCommentConfirmationVisible } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import DeleteCommentsConfirmationModal from './DeleteCommentsConfirmationModal'
import Comment from './Comment'

// TODO create modal to confirm comment delete
const CommentsSection = ({ commentsDataset, newComment, refId, deleteCommentConfirmationVisible,
    onCommentSubmit, onCommentChange }) => {

    const comments = commentsDataset[refId] || []

    const handleCommentSubmit = (e) => {
        e.preventDefault()
        onCommentSubmit()
    }

    const handleCommentChange = (e) => {
        onCommentChange(refId, e.target.value)
    }

    return (
        <div>
            <p className="mt-8 text-sm pb-4 select-none">
                {comments.length} { Translations.get('gallery.comments.count') }
            </p>
            <textarea className="w-full text-black p-2 resize-none focus:ring-sky-400"
                onChange={handleCommentChange}
                placeholder={ Translations.get('gallery.comments.placeholder') }
                value={newComment.text || ''}/>
            <div className="flex justify-end">
                <button className="bg-sky-400 hover:bg-sky-600 py-2 px-4 text-sm"
                    onClick={handleCommentSubmit}>
                        { Translations.get('gallery.comments.submit_button') }
                    </button>
            </div>
            <div className="mt-10">
            { comments.map(c =>
                <Comment key={c.id} comment={c} />
            )}
            </div>
            { deleteCommentConfirmationVisible &&
                <DeleteCommentsConfirmationModal />
            }
        </div>
    )
}

CommentsSection.propTypes = {
    commentsDataset: PropTypes.object.isRequired,
    refId: PropTypes.string.isRequired,
    onCommentSubmit: PropTypes.func.isRequired,
    onCommentChange: PropTypes.func.isRequired,
    newComment: PropTypes.object.isRequired,
    deleteCommentConfirmationVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    commentsDataset: selectComments(state),
    newComment: state.weddingPhotographs.newComment,
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state),
    deleteCommentConfirmationVisible: selectDeleteCommentConfirmationVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onCommentSubmit: () => dispatch(submitComment()),
    onCommentChange: (refId, text) => dispatch(updateComment(refId, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentsSection)
