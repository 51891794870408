import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BannerImage from './BannerImage'
import RsvpButton from './RsvpButton'
import ViewWeddingPhotographsButton from './ViewWeddingPhotographsButton'
import { selectHasRepliedRsvp } from './ducks/selectors'

const Post_4 = ({ hasRepliedRsvp }) => (
    <div>
        <BannerImage
            highResUrl="/invitation_cards.jpg"
            lowResUrl="/invitation_cards.jpg">
        </BannerImage>

        <div className="px-8 mt-8 text-lg leading-loose md:text-2xl md:leading-loose text-center text-primary">
            <p>❤ 喜訊送達 ❤</p>
            <p>我們相約這天見</p>
            <div className="mt-12 w-full max-w-[400px] mx-auto relative">
                {
                    hasRepliedRsvp
                        ? <ViewWeddingPhotographsButton />
                        : <RsvpButton />
                }
            </div>
        </div>
    </div>
)

Post_4.propTypes = {
    hasRepliedRsvp: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    hasRepliedRsvp: selectHasRepliedRsvp(state),
})

export default connect(mapStateToProps)(Post_4)
