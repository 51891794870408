import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfirmationModal from '../modals/ConfirmationModal'
import { selectAttendeesCount, selectRsvpIsSubmitting } from './ducks/selectors'
import { confirmRsvpSave, cancelRsvpSave } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const RsvpSubmitConfirmation = ({ attendeesCount, onConfirm, onCancel,
    isSubmitting, onSubmitSuccess }) => (
    <ConfirmationModal onConfirm={ () => onConfirm(onSubmitSuccess) }
        onCancel={ onCancel } isProcessing={ isSubmitting }>
        <p>{ Translations.get('rsvp_form.submit_confirmation.line_1', attendeesCount) }</p>
        <p>{ Translations.get('rsvp_form.submit_confirmation.line_2') }</p>
    </ConfirmationModal>
)

RsvpSubmitConfirmation.propTypes = {
    attendeesCount: PropTypes.number.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onSubmitSuccess: PropTypes.func, // optional
}

const mapStateToProps = (state) => ({
    attendeesCount: selectAttendeesCount(state),
    currentLanguageCode: selectCurrentLanguageCode(state),
    isSubmitting: selectRsvpIsSubmitting(state)
})

const mapDispatchToProps = (dispatch) => ({
    onConfirm: (successCallback) => dispatch(confirmRsvpSave(successCallback)),
    onCancel: () => dispatch(cancelRsvpSave())
})

export default connect(mapStateToProps, mapDispatchToProps)(RsvpSubmitConfirmation)
