import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeRsvpHistory, endEditAttendee } from './ducks/actions'
import { selectRsvpHistories, selectEditRsvpFormVisible } from './ducks/selectors'
import BackButton from '../router/BackButton'
import HistoricRsvpDetails from './HistoricRsvpDetails'
import CurrentRsvpDetails from './CurrentRsvpDetails'
import RsvpForm from '../rsvp/RsvpForm'
import { editRsvp } from '../rsvp/ducks/actions'
import { fetchRsvpHistory } from './ducks/actions'

const RsvpHistory = ({ records, onBackClick, onLoadRsvpEditForm,
    onReloadRsvpHistory, editRsvpFormVisible }) => {
    const [currentRsvp, ...others] = records

    useEffect(() => {
        onLoadRsvpEditForm(currentRsvp.data)
    })

    const handleFormSubmitSuccess = () => {
        const weddingGuest = currentRsvp.data
        onReloadRsvpHistory(weddingGuest.id)
    }

    return (
        <div>
            <div className="pt-4 pl-4">
                <BackButton onClick={onBackClick} />
            </div>
            {
                editRsvpFormVisible &&
                <div className="bg-primary mt-4">
                    <div className="p-6 md:w-1/2 max-w-[500px] m-auto">
                        <RsvpForm onSubmitSuccess={ handleFormSubmitSuccess } />
                    </div>
                </div>
            }
            <div className="px-6 py-16 md:px-16">
                <CurrentRsvpDetails rsvpData={ currentRsvp } />
                <hr className="my-12 border-secondary" />
                <div className="w-full flex justify-between flex-wrap">
                    {
                        others.map((r, i) =>
                            <HistoricRsvpDetails key={r.id} rsvpData={r}
                                order={i + 1}
                                reverseOrder={others.length - i} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

RsvpHistory.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    onBackClick: PropTypes.func.isRequired,
    onLoadRsvpEditForm: PropTypes.func.isRequired,
    onReloadRsvpHistory: PropTypes.func.isRequired,
    editRsvpFormVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    records: selectRsvpHistories(state),
    editRsvpFormVisible: selectEditRsvpFormVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    onBackClick: () => {
        dispatch(endEditAttendee())
        dispatch(closeRsvpHistory())
    },
    onLoadRsvpEditForm: (guest) => dispatch(editRsvp(guest)),
    onReloadRsvpHistory: (guestId) => {
        dispatch(fetchRsvpHistory(guestId))
        dispatch(endEditAttendee())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RsvpHistory)
