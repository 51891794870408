import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BannerImage from './BannerImage'
import POST_3_SURVEY from './Post_3_Survey'
import { selectEddySurveyVisible, selectRitaSurveyVisible,
    selectEddySurveyResponses, selectRitaSurveyResponses,
    selectEddySurveyPositiveResponses, selectEddySurveyNegativeResponses,
    selectRitaSurveyPositiveResponses, selectRitaSurveyNegativeResponses,
    selectUserResponseToEddySurvey, selectUserResponseToRitaSurvey
} from './ducks/selectors'
import { showSurvey, fetchSurveyResponses } from './ducks/actions'

const Post_3 = ({ eddySurveyVisible, ritaSurveyVisible, ritaSurveyResponses,
    onSurveyClick, onFetchSurveyResponses, eddySurveyResponses,
    eddySurveyPositiveResponses, eddySurveyNegativeResponses,
    ritaSurveyPositiveResponses, ritaSurveyNegativeResponses,
    userResponseToEddySurvey, userResponseToRitaSurvey }) => {

    let [fetchedOnce, setFetchedOnce] = useState(false)

    if (!fetchedOnce) {
        setFetchedOnce(true)
        onFetchSurveyResponses()
    }

    const eddyTotalPositive = eddySurveyPositiveResponses.length
    const eddyTotalNegative = eddySurveyNegativeResponses.length
    const ritaTotalPositive = ritaSurveyPositiveResponses.length
    const ritaTotalNegative = ritaSurveyNegativeResponses.length

    return (
        <div>
            <BannerImage
                highResUrl="/wedding_photographs_original/KOU_0353.jpg"
                lowResUrl="/wedding_photographs_previews/KOU_0353.jpg">
                <div className="absolute top-[25px] left-[25px]">
                    <p className="text-[50px] md:text-[75px] lg:text-[100px] lg:mb-20">
                        About Us
                    </p>
                </div>
            </BannerImage>

            <div className="text-primary text-center mt-12">
                <p className="text-2xl md:text-3xl mb-2">跨越COVID</p>
                <p className="text-3xl md:text-4xl">我們相遇</p>
            </div>

            <div className="mt-10 flex justify-around">
                <div className="w-1/3 lg:w-1/4 xl:w-1/5">
                    <div className="m-auto rounded-full w-full overflow-hidden">
                        <img className="object-cover object-center"
                            src="/wedding_photographs_previews/KOU_0145.jpg"
                            alt="Eddy" />
                    </div>
                    <div className="mt-8 text-center text-lg md:text-2xl leading-loose md:leading-loose">
                        <p className="text-xl md:text-3xl text-secondary">軒懌 | Eddy</p>
                        <p>
                            <span className="inline-block">雙子座</span>
                            <img alt="gemini" src="/zodiac-gemini.svg"
                                className="ml-2 w-6 h-6 inline-block" />
                        </p>
                        <span className="block m-auto w-24 mb-4 border-secondary border-b-2" />
                        <p>陽光開朗</p>
                        <p>率直大方</p>
                        <p>體貼細膩</p>
                        <div className="cursor-pointer" onClick={() => onSurveyClick('eddySurvey')}>
                            <p className="text-secondary cursor-pointer" >
                                <span className="text-sm">
                                    { eddyTotalNegative > eddyTotalPositive && '不' }
                                </span>
                                <span>謙虛..</span>
                                {
                                    !userResponseToEddySurvey &&
                                        <span className="animate-ping text-sm">嗎?</span>
                                }
                            </p>
                            <p className="text-xs text-secondary">
                                <span>是 ({ eddySurveyPositiveResponses.length })</span>
                                <span className="mx-2">|</span>
                                <span>不是 ({ eddySurveyNegativeResponses.length })</span>
                            </p>
                        </div>
                    </div>

                    { eddySurveyVisible &&
                        <POST_3_SURVEY targetSurvey="eddySurvey" /> }
                </div>

                <div className="w-1/3 lg:w-1/4 xl:w-1/5">
                    <div className="m-auto rounded-full w-full overflow-hidden">
                        <img className="object-cover object-center"
                            src="/wedding_photographs_previews/KOU_0154.jpg"
                            alt="Rita" />
                    </div>

                    <div className="mt-8 text-center text-lg md:text-2xl leading-loose md:leading-loose">
                        <p className="text-xl md:text-3xl text-secondary">佳蓉 | Rita</p>
                        <p>
                            <span className="inline-block">射手座</span>
                            <img alt="gemini" src="/zodiac-sagittarius.svg"
                                className="ml-2 w-6 h-6 inline-block" />
                        </p>
                        <span className="block m-auto w-24 mb-4 border-secondary border-b-2" />
                        <p>氣質典雅</p>
                        <p>細心入微</p>
                        <p>知性獨立</p>
                        <div className="cursor-pointer" onClick={() => onSurveyClick('ritaSurvey')}>
                            <p className="text-secondary">
                                <span className="text-sm">
                                    { ritaTotalNegative > ritaTotalPositive && '不' }
                                </span>
                                <span>溫柔..</span>
                                {
                                    !userResponseToRitaSurvey &&
                                        <span className="animate-ping text-sm">吧?</span>
                                }
                            </p>
                            <p className="text-xs text-secondary">
                                <span>是 ({ ritaSurveyPositiveResponses.length })</span>
                                <span className="mx-2">|</span>
                                <span>不是 ({ ritaSurveyNegativeResponses.length })</span>
                            </p>
                        </div>
                    </div>
                </div>

                { ritaSurveyVisible &&
                    <POST_3_SURVEY targetSurvey="ritaSurvey" /> }
            </div>


        </div>
    )
}

Post_3.propTypes = {
    eddySurveyVisible: PropTypes.bool.isRequired,
    ritaSurveyVisible: PropTypes.bool.isRequired,
    onFetchSurveyResponses: PropTypes.func.isRequired,
    eddySurveyResponses: PropTypes.object.isRequired,
    ritaSurveyResponses: PropTypes.object.isRequired,
    eddySurveyPositiveResponses: PropTypes.array.isRequired,
    eddySurveyNegativeResponses: PropTypes.array.isRequired,
    ritaSurveyPositiveResponses: PropTypes.array.isRequired,
    ritaSurveyNegativeResponses: PropTypes.array.isRequired,
    userResponseToEddySurvey: PropTypes.object,
    userResponseToRitaSurvey: PropTypes.object
}

const mapStateToProps = (state) => ({
    eddySurveyVisible: selectEddySurveyVisible(state),
    ritaSurveyVisible: selectRitaSurveyVisible(state),
    eddySurveyResponses: selectEddySurveyResponses(state),
    ritaSurveyResponses: selectRitaSurveyResponses(state),
    eddySurveyPositiveResponses: selectEddySurveyPositiveResponses(state),
    eddySurveyNegativeResponses: selectEddySurveyNegativeResponses(state),
    ritaSurveyPositiveResponses: selectRitaSurveyPositiveResponses(state),
    ritaSurveyNegativeResponses: selectRitaSurveyNegativeResponses(state),
    userResponseToEddySurvey: selectUserResponseToEddySurvey(state),
    userResponseToRitaSurvey: selectUserResponseToRitaSurvey(state)
})

const mapDispatchToProps = (dispatch) => ({
    onSurveyClick: (targetSurvey) => dispatch(showSurvey(targetSurvey)),
    onFetchSurveyResponses: () => dispatch(fetchSurveyResponses())
})

export default connect(mapStateToProps, mapDispatchToProps)(Post_3)
