import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectGalleryThumbnails, selectCurrentMedia, selectCurrentMediaIndex } from './ducks/selectors'
import { setNextMediaItem } from './ducks/actions'

const mediaItemWidth = 96
const mediaItemsOnLeft = 1 // pad x number of items on left of current item

const GalleryThumbnails = ({ mediaItems, currentMedia, currentMediaIndex, onClick }) => {
    const handleClick = (mediaItem) => {
        if (mediaItem.refId !== currentMedia.refId)
            onClick(mediaItem)
    }

    const scrollToCurrentMedia = (el) => {
        // 96 px width per thumbnail
        if (el)
            el.scrollTo((currentMediaIndex * mediaItemWidth) - (mediaItemsOnLeft * mediaItemWidth), 0)
    }

    return (
        <div className={`flex align-stretch scroll-smooth overflow-x-scroll
            lg:max-w-[83vw] xl:max-w-[80vw]`}
            ref={ el => scrollToCurrentMedia(el) }>
            {
                mediaItems.map((m, i) =>
                    <div key={m.url} className={`w-24 h-24 bg-black mr-2
                        last:mr-0 cursor-pointer relative group shrink-0 select-none
                        ${m.url === currentMedia.url
                            ? 'border-2 border-white'
                            : ''
                        }`}
                        onClick={ () => handleClick(m) }>

                        <img src={m.thumbnailUrl} alt={m.title}
                            className="w-full h-full object-cover"
                            draggable={false}
                            loading="lazy" />

                        { m.url !== currentMedia.url &&
                            <div className={`absolute left-0 right-0 top-0 bottom-0
                                bg-black/25 hidden can-hover:group-hover:block`} />
                        }
                    </div>
                )
            }
        </div>
    )
}

GalleryThumbnails.propTypes = {
    currentMediaIndex: PropTypes.number.isRequired,
    mediaItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentMedia: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentMediaIndex: selectCurrentMediaIndex(state),
    mediaItems: selectGalleryThumbnails(state),
    currentMedia: selectCurrentMedia(state)
})

const mapDispatchToProps = (dispatch) => ({
    onClick: (m) => dispatch(setNextMediaItem(m))
})

export default connect(mapStateToProps, mapDispatchToProps)(GalleryThumbnails)
