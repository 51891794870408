import m from 'moment'
import { HOME_PAGE, navigateTo } from '../../router/ducks/actions'
import { fetchRsvpEntry } from '../../rsvp/ducks/actions'
import config from '../../config'
import store from '../../redux/store'

const namespace = 'invitation_screening'

export const UPDATE_INVITATION_CODE = `${namespace}/UPDATE_INVITATION_CODE`

export const VERIFY_INVITATION_CODE_DID_BEGIN = `${namespace}/VERIFY_INVITATION_CODE_DID_BEGIN`
export const VERIFY_INVITATION_CODE_DID_END= `${namespace}/VERIFY_INVITATION_CODE_DID_END`
export const VERIFY_INVITATION_CODE_DID_SUCCEED = `${namespace}/VERIFY_INVITATION_CODE_DID_SUCCEED`
export const VERIFY_INVITATION_CODE_DID_FAIL = `${namespace}/VERIFY_INVITATION_CODE_DID_FAIL`
export const VERIFY_INVITATION_CODE_DID_ERROR = `${namespace}/VERIFY_INVITATION_CODE_DID_ERROR`

const EXTENDED_SESSION_DURATION_IN_HOURS = 24

const setExtendedSession = ({ invitationCode: code, invitationCodeId: codeId, isAdmin }) => {
    localStorage.setItem('sessionCommencedAt', Date.now())
    localStorage.setItem('invitationCode', code)
    localStorage.setItem('invitationCodeId', codeId)
    localStorage.setItem('isAdmin', isAdmin)
}

export const clearExtendedSession = () => {
    localStorage.removeItem('sessionCommencedAt')
    localStorage.removeItem('invitationCode')
    localStorage.removeItem('invitationCodeId')
    localStorage.removeItem('isAdmin')
}

export const getExtendedSession = () => {
    if (!localStorage.getItem('sessionCommencedAt'))
        return

    try {
        const sessionCommencedAt = parseInt(localStorage.getItem('sessionCommencedAt'))
        const invitationCode = localStorage.getItem('invitationCode')
        const invitationCodeId = parseInt(localStorage.getItem('invitationCodeId'))
        const isAdmin = localStorage.getItem('isAdmin') === 'true'

        // 24 hour limit
        const sessionExpiryMoment = m(sessionCommencedAt)

        if ( m().diff(sessionExpiryMoment, 'hours') < EXTENDED_SESSION_DURATION_IN_HOURS ) {
            store.dispatch({
                type: UPDATE_INVITATION_CODE,
                payload: { code: invitationCode }
            })

            store.dispatch({
                type: VERIFY_INVITATION_CODE_DID_SUCCEED,
                payload: {
                    isAdmin,
                    invitationCodeId
                }
            })

            store.dispatch(fetchRsvpEntry())
            store.dispatch(acceptInvitationCode())
        } else {
            localStorage.removeItem('invitationCode')
            localStorage.removeItem('invitationCodeId')
            localStorage.removeItem('isAdmin')
        }
    } catch(err) {
        console.error(err)
    }
}

export const validateInvitationCode = (code) => (dispatch) => {
    // do validation stuff ...
    dispatch({
        type: UPDATE_INVITATION_CODE,
        payload: { code }
    })

    dispatch({
        type: VERIFY_INVITATION_CODE_DID_BEGIN
    })

    // minimise query times
    if (code.length < 6) {
        dispatch({
            type: VERIFY_INVITATION_CODE_DID_FAIL,
            payload: {
                errors: { code: ['invalid'] }
            }
        })
        return
    }

    fetch(`${config.API_BASE_URL}/verify`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code })
        })
        .then(res => res.json())
        .then(json => {
            if (json.status === 0) {
                if (json.data.isAdmin) {
                    setExtendedSession({
                        invitationCode: code,
                        invitationCodeId: json.data.invitationCodeId,
                        isAdmin: json.data.isAdmin
                    })
                }

                dispatch({
                    type: VERIFY_INVITATION_CODE_DID_SUCCEED,
                    payload: {
                        isAdmin: json.data.isAdmin,
                        invitationCodeId: json.data.invitationCodeId
                    }
                })
                dispatch(acceptInvitationCode())
                dispatch(fetchRsvpEntry())
            } else {
                dispatch({
                    type: VERIFY_INVITATION_CODE_DID_FAIL,
                    payload: { errors: json.data.errors }
                })
            }
        })
        .catch(err =>
            dispatch({
                type: VERIFY_INVITATION_CODE_DID_ERROR,
                payload: { errors: [err] }
            })
        )
        .finally(() =>
            dispatch({
                type: VERIFY_INVITATION_CODE_DID_END
            })
        )
}

export const acceptInvitationCode = () => navigateTo(HOME_PAGE)
