import { useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AttendeesTable from './AttendeesTable'
import HomeButton from '../router/HomeButton'
import { selectFetchAttendeesErrors, selectRsvpHistoryVisible } from './ducks/selectors'
// import { fetchAttendees } from './ducks/actions'
import RsvpHistory from './RsvpHistory'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const AttendeesPage = ({ fetchAttendeesErrors, rsvpHistoryVisible }) => {
    useEffect(() => {
        document.title = 'Attendees | Rita & Eddy 2022'
    })

    return rsvpHistoryVisible
        ?
        <RsvpHistory />
        :
        <div>
            <div className="pt-4 pl-4">
                <HomeButton />
            </div>

            { !_.isEmpty(fetchAttendeesErrors)
                ?
                <div className="w-4/5 max-w-[800px] h-[calc(100vh-66px)] p-4 m-auto text-center flex justify-center items-center">
                    <div>
                        { Object.keys(fetchAttendeesErrors).map((k, i) =>
                            fetchAttendeesErrors[k].map((v, j) =>
                                <p key={j} className="text-red-400">
                                    { Translations.get(`attendees_page.fetch_attendees.error.${k}.${v.error}`) }
                                </p>
                            )
                        )}
                    </div>
                </div>
                :
                <AttendeesTable />
            }
        </div>
}

AttendeesPage.propTypes = {
    fetchAttendeesErrors: PropTypes.object.isRequired,
    rsvpHistoryVisible: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    fetchAttendeesErrors: selectFetchAttendeesErrors(state),
    rsvpHistoryVisible: selectRsvpHistoryVisible(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(AttendeesPage)
