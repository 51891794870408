import PropTypes from 'prop-types'
import imageSources from '../on_the_day_projection/ducks/image_sources'

const selectImage = (refId) => imageSources.find(
    img => img.refId === refId)

const CommentImageThumbnail = ({ refId }) => {
    const image = selectImage(refId)

    return (
        <img className="w-12 h-12 object-cover" alt={ image.title }
            src={ image.thumbnailUrl } />
    )
}

CommentImageThumbnail.propTypes = {
    refId: PropTypes.string.isRequired
}

export default CommentImageThumbnail
