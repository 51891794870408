import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getRightArrowVisibility } from './ducks/selectors'
import { incrementGalleryMediaIndex } from './ducks/actions'

const RightArrow = ({visible, onClick}) => (
    <div className={`w-[30px] md:w-[50px] cursor-pointer ${visible ? '' : 'invisible'}`}
        onClick={onClick}>
        <svg version="1.1"
            className="stroke-secondary-500 hover:stroke-secondary-300 animate-pulse hover:animate-none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 59.414 59.414">
            <polygon
                fill="#FFFFFF"
                strokeWidth="2"
                points="15.561,0 14.146,1.414 42.439,29.707 14.146,58 15.561,59.414 45.268,29.707 "/>
        </svg>
    </div>
)

RightArrow.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    visible: getRightArrowVisibility(state)
})

const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch(incrementGalleryMediaIndex())
})

export default connect(mapStateToProps, mapDispatchToProps)(RightArrow)
