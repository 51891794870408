import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchComments } from './ducks/actions'
import { selectComments, selectRefId, selectShowDeleteCommentConfirmation } from './ducks/selectors'
import CommentEditor from './CommentEditor'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import Comment from './Comment'
import DeleteCommentConfirmation from './DeleteCommentConfirmation'

const CommentsSection = ({ comments, onFetch, refId, showDeleteCommentConfirmation }) => {

    let [lastRefId, setLastRefId] = useState(null)

    // only update when changing to another image
    if (lastRefId !== refId) {
        setLastRefId(refId)
        onFetch()
    }

    const selectedComments = comments[refId] || []

    return (
        <div>
            {
                selectedComments.length === 0
                    ?
                    <div>
                        <div className="text-white text-lg mb-4 px-3">
                            <p>
                                { Translations.get('on_the_day.users.comments_section.empty_comments.line_1') }
                            </p>
                            <p>
                                { Translations.get('on_the_day.users.comments_section.empty_comments.line_2') }
                            </p>
                        </div>
                        <CommentEditor />
                    </div>
                    :
                    <div>
                        <CommentEditor />
                        <hr className="border-secondary my-6" />
                        <div className="mt-4">
                            <p className="text-white mb-4 px-3 text-lg">
                                { Translations.get('on_the_day.users.comments_section.comments.header') }
                            </p>
                            { selectedComments.map(c =>
                                <Comment key={c.id} comment={c} />
                            )}
                        </div>
                    </div>
            }
            {
                showDeleteCommentConfirmation &&
                    <DeleteCommentConfirmation />
            }
        </div>
    )
}

CommentsSection.propTypes = {
    refId: PropTypes.string.isRequired,
    comments: PropTypes.shape({
        [PropTypes.string.isRequired]: PropTypes.shape({
            text: PropTypes.string.isRequired,
            commenterName: PropTypes.string.isRequired,
            commenterOnceOffId: PropTypes.string.isRequired
        })
    }),
    onFetch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    refId: selectRefId(state),
    comments: selectComments(state),
    currentLanguageCode: selectCurrentLanguageCode(state),
    showDeleteCommentConfirmation: selectShowDeleteCommentConfirmation(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFetch: () => dispatch(fetchComments())
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentsSection)
