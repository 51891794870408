import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { selectHasRepliedRsvp, selectDisplayArrownDown }
    from './ducks/selectors'
import {
    redirectToRsvpPage,
    redirectToWeddingPhotographsPage,
    hideArrowDown,
    showArrowDown
} from './ducks/actions'
import Link from './Link'
import ArrowDownImage from './ArrowDown'

const MainBanner = ({ hasRepliedRsvp, displayArrowDown, onHideArrowDown,
    onShowArrowDown, onRsvpClick, onViewWeddingPhotographsClick }) => {

    const handleScrollEvent = (e) => {
        window.scrollY < (window.innerHeight * 0.55)
            ? displayArrowDown === false && onShowArrowDown()
            : displayArrowDown === true && onHideArrowDown()
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScrollEvent)

        return () => {
            window.removeEventListener('scroll', handleScrollEvent)
        }
    })

    const handleRsvpClick = (e) => {
        e.preventDefault()
        onRsvpClick()
    }

    const handleViewWeddingPhotographsClick = (e) => {
        e.preventDefault()
        onViewWeddingPhotographsClick()
    }

    const handleArrowDownClick = (e) => {
        e.target.scrollIntoView()
    }

    return (
        <div className="w-full bg-secondary h-[95vh]">
            <div className="bg-primary/25 absolute w-full h-[95vh] flex justify-center items-center">
                <div className="text-white">
                    <p className="font-cursive text-[75px] md:text-[100px] select-none">Rita & Eddy</p>
                    <div className="text-2xl relative">
                        <Link text={
                            hasRepliedRsvp
                                ? Translations.get('home_page.banner.button.view_gallery')
                                : Translations.get('home_page.banner.button.rsvp')
                            }
                            onClick={ hasRepliedRsvp
                                ? handleViewWeddingPhotographsClick
                                : handleRsvpClick } />
                        {
                            !hasRepliedRsvp &&
                            <div>
                                <div className="w-5 h-5 rounded-full bg-rose-400 animate-ping absolute -top-2 -right-2 group-hover:hidden" />
                                <div className="w-5 h-5 rounded-full bg-rose-400 absolute -top-2 -right-2 group-hover:hidden" />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="splash-cover w-full h-full flex items-end justify-center">
                <div className="w-[100px] h-[100px] z-10 animate-bounce" onClick={handleArrowDownClick}>
                    { displayArrowDown
                        ? <ArrowDownImage />
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

MainBanner.propTypes = {
    hasRepliedRsvp: PropTypes.bool.isRequired,
    onHideArrowDown: PropTypes.func.isRequired,
    onShowArrowDown: PropTypes.func.isRequired,
    onRsvpClick: PropTypes.func.isRequired,
    onViewWeddingPhotographsClick: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    hasRepliedRsvp: selectHasRepliedRsvp(state),
    displayArrowDown: selectDisplayArrownDown(state),
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onHideArrowDown: () => dispatch(hideArrowDown()),
    onShowArrowDown: () => dispatch(showArrowDown()),
    onRsvpClick: () => dispatch(redirectToRsvpPage()),
    onViewWeddingPhotographsClick: () => dispatch(redirectToWeddingPhotographsPage())
})

export default connect(mapStateToProps, mapDispatchToProps)(MainBanner)
