import _ from 'lodash'
import config from '../../config'
import { createAction } from '@reduxjs/toolkit'

const namespace = 'rsvp'

export const ADD_ATTENDEE_FIELD = `${namespace}/ADD_ATTENDEE_FIELD`
export const REMOVE_ATTENDEE_FIELD = `${namespace}/REMOVE_ATTENDEE_FIELD`
export const MODIFY_ATTENDEE = `${namespace}/MODIFY_ATTENDEE`

export const CANCEL_SAVE_RSVP = `${namespace}/CANCEL_SAVE_RSVP`
export const SAVE_RSVP = `${namespace}/SAVE_RSVP`
export const SAVE_RSVP_DID_START = `${namespace}/SAVE_RSVP_DID_START`
export const SAVE_RSVP_DID_END = `${namespace}/SAVE_RSVP_DID_END`
export const SAVE_RSVP_DID_SUCCEED = `${namespace}/SAVE_RSVP_DID_SUCCEED`
export const SAVE_RSVP_DID_ERROR = `${namespace}/SAVE_RSVP_DID_ERROR`
export const SAVE_RSVP_DID_FAIL = `${namespace}/SAVE_RSVP_DID_FAIL`

export const MODIFY_WEDDING_GUEST = `${namespace}/MODIFY_WEDDING_GUEST`

export const FETCH_RSVP_DID_START = `${namespace}/FETCH_RSVP_DID_START`
export const FETCH_RSVP_DID_END = `${namespace}/FETCH_RSVP_DID_END`
export const FETCH_RSVP_DID_SUCCEED = `${namespace}/FETCH_RSVP_DID_SUCCEED`
export const FETCH_RSVP_DID_ERROR = `${namespace}/FETCH_RSVP_DID_ERROR`
export const FETCH_RSVP_DID_FAIL = `${namespace}/FETCH_RSVP_DID_FAIL`

// admin edit
export const EDIT_RSVP = FETCH_RSVP_DID_SUCCEED

export const editRsvp = createAction(
    EDIT_RSVP,
    (guest) => ({
        payload: { weddingGuest: guest }
    })
)

export const saveRsvpForm = createAction(
    SAVE_RSVP
)

export const cancelRsvpSave = createAction(
    CANCEL_SAVE_RSVP
)

export const confirmRsvpSave = (successCallback) => (dispatch, getState) => {
    dispatch({
        type: SAVE_RSVP_DID_START
    })

    const weddingGuest = {
        ...getState().rsvp.editWeddingGuest,
        additionalGuests: getState().rsvp.attendees.filter(a => a.name)
    }
    const isUpdate = weddingGuest.id

    const url = isUpdate
        ? `${config.API_BASE_URL}/wedding_guests/${weddingGuest.id}`
        : `${config.API_BASE_URL}/wedding_guests`
    const invitationCode = getState().invitationScreening.invitationCode

    return fetch(url, {
        method: isUpdate ? 'PATCH' : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ weddingGuest, invitationCode })
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: SAVE_RSVP_DID_SUCCEED,
                payload: { weddingGuest: json.data.weddingGuest }
            })

            if (_.isFunction(successCallback))
                successCallback()
        } else {
            dispatch({
                type: SAVE_RSVP_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
    .catch(err => {
        console.error('SAVE_RSVP_DID_ERROR', err)
        dispatch({
            type: SAVE_RSVP_DID_ERROR,
            payload: {
                errors: {
                    base: [err]
                }
            }
        })
    })
    .finally(() => {
        dispatch({
            type: SAVE_RSVP_DID_END
        })
    })
}

// fetch prev rsvp details submitted
export const fetchRsvpEntry = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_RSVP_DID_START
    })

    const code = getState().invitationScreening.invitationCode

    fetch(`${config.API_BASE_URL}/invitation_codes/${code}/show_rsvp`, {
            method: 'get',
            headers: {
                'ACCEPT': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.status === 0) {
                dispatch({
                    type: FETCH_RSVP_DID_SUCCEED,
                    payload: {
                        weddingGuest: json.data.weddingGuest
                    }
                })
            } else {
                dispatch({
                    type: FETCH_RSVP_DID_FAIL,
                    payload: {
                        errors: json.data.errors
                    }
                })
            }
        })
        .catch(err =>
            dispatch({
                type: FETCH_RSVP_DID_ERROR,
                payload: {
                    errors: { base: [err] }
                }
            })
        )
        .finally(() =>
            dispatch({ type: FETCH_RSVP_DID_END })
        )
}

export const addAttendeeField = () => ({
    type: ADD_ATTENDEE_FIELD
})

export const removeAttendeeField = (index) => ({
    type: REMOVE_ATTENDEE_FIELD,
    payload: {
        index
    }
})

export const modifyAttendee = (index, prop) => ({
    type: MODIFY_ATTENDEE,
    payload: {
        index,
        key: prop.key,
        value: prop.value
    }
})

export const modifyWeddingGuest = (key, value) => ({
    type: MODIFY_WEDDING_GUEST,
    payload: { key, value }
})
