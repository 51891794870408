import { createAction } from '@reduxjs/toolkit'
import config from '../../config'

const namespace = 'attendees'

export const FETCH_ATTENDEES_DID_BEGIN = `${namespace}/FETCH_ATTENDEES_DID_BEGIN`
export const FETCH_ATTENDEES_DID_END = `${namespace}/FETCH_ATTENDEES_DID_END`
export const FETCH_ATTENDEES_DID_SUCCEED = `${namespace}/FETCH_ATTENDEES_DID_SUCCEED`
export const FETCH_ATTENDEES_DID_FAIL = `${namespace}/FETCH_ATTENDEES_DID_FAIL`
export const FETCH_ATTENDEES_DID_ERROR = `${namespace}/FETCH_ATTENDEES_DID_ERROR`

export const FETCH_RSVP_HISTORY_DID_SUCCEED = `${namespace}/FETCH_RSVP_HISTORY_DID_SUCCEED`
export const CLOSE_RSVP_HISTORY = `${namespace}/CLOSE_RSVP_HISTORY`

export const FILTER_ABSENT_GUESTS = `${namespace}/FILTER_ABSENT_GUESTS`
export const REMOVE_FILTER_ABSENT_GUESTS = `${namespace}/REMOVE_FILTER_ABSENT_GUESTS`

export const EDIT_ATTENDEE = `${namespace}/EDIT_ATTENDEE`
export const END_EDIT_ATTENDEE = `${namespace}/END_EDIT_ATTENDEE`

export const editAttendee = createAction(
    EDIT_ATTENDEE
)

export const endEditAttendee = createAction(
    END_EDIT_ATTENDEE
)

export const filterAbsentGuests = createAction(
    FILTER_ABSENT_GUESTS
)

export const removeFilterAbsentGuests = createAction(
    REMOVE_FILTER_ABSENT_GUESTS
)

export const fetchRsvpHistory = (guestId) => (dispatch, getState) => {
    fetch(`${config.API_BASE_URL}/wedding_guests/${guestId}/rsvp_histories`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_RSVP_HISTORY_DID_SUCCEED,
                payload: {
                    rsvpHistories: json.data.rsvpHistories
                }
            })
        }
    })
    .catch(console.error)
}

export const closeRsvpHistory = () => ({
    type: CLOSE_RSVP_HISTORY
})

export const fetchAttendees = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_ATTENDEES_DID_BEGIN
    })

    const fetchParams = new URLSearchParams({
        code: getState().invitationScreening.invitationCode
    })

    fetch(`${config.API_BASE_URL}/wedding_guests?${fetchParams}`, {
            method: 'get'
        })
        .then(res => res.json())
        .then(json => {
            if (json.status === 0) {
                dispatch({
                    type: FETCH_ATTENDEES_DID_SUCCEED,
                    payload: { weddingGuests: json.data.weddingGuests }
                })
            } else {
                dispatch({
                    type: FETCH_ATTENDEES_DID_FAIL,
                    payload: { errors: json.data.errors }
                })
            }
        })
        .catch(err =>
            dispatch({
                type: FETCH_ATTENDEES_DID_ERROR,
                payload: { errors: { base: [err] } }
            })
        )
}
