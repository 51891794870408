import { v4 as uuidv4 } from 'uuid'
import {
    ADD_ATTENDEE_FIELD,
    REMOVE_ATTENDEE_FIELD,
    MODIFY_ATTENDEE,
    MODIFY_WEDDING_GUEST,
    SAVE_RSVP_DID_START,
    SAVE_RSVP_DID_END,
    SAVE_RSVP_DID_SUCCEED,
    // SAVE_RSVP_DID_ERROR,
    SAVE_RSVP_DID_FAIL,
    FETCH_RSVP_DID_SUCCEED,
    // FETCH_RSVP_DID_FAIL,
    // FETCH_RSVP_DID_START,
    SAVE_RSVP,
    CANCEL_SAVE_RSVP
} from './actions'

const createNewAttendee = (data = {}) => ({
    uuid: uuidv4(),
    name: '',
    childSeatRequired: false,
    ...data
})

const createNewWeddingGuest = () => ({
    name: '',
    email: '',
    contactNumber: '',
    isAttending: ''
})

const getInitialAttendees = () => [
    createNewAttendee(),
    createNewAttendee()
]

const getInitialSubmitStates = () => ({
    formErrors: {},
    formUncaughtError: null
})

// default to 2 empty attendees field
const initialState = {
    attendees: getInitialAttendees(),
    editWeddingGuest: createNewWeddingGuest(),
    showRsvpConfirmation: false,
    rsvpSubmitConfirmationVisible: false,
    isSubmitting: false,
    ...getInitialSubmitStates()
}

const sanitiseAttendeeData = (key, value) => {
    switch(key) {
        case 'childSeatRequired': {
            return {
                [key]: value === 'true'
            }
        }
        default:
            return {
                [key]: value
            }
    }
}

const sanitiseWeddingGuestData = (key, value) => {
    switch(key) {
        case 'isAttending': {
            return {
                [key]: value === 'true'
            }
        }
        default:
            return {
                [key]: value
            }
    }
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SAVE_RSVP_DID_START: {
            return {
                ...state,
                isSubmitting: true
            }
        }
        case SAVE_RSVP_DID_END: {
            return {
                ...state,
                isSubmitting: false,
                rsvpSubmitConfirmationVisible: false
            }
        }
        case SAVE_RSVP: {
            return {
                ...state,
                rsvpSubmitConfirmationVisible: true
            }
        }
        case CANCEL_SAVE_RSVP: {
            return {
                ...state,
                rsvpSubmitConfirmationVisible: false
            }
        }
        case FETCH_RSVP_DID_SUCCEED: {
            return {
                ...state,
                editWeddingGuest: action.payload.weddingGuest,
                showRsvpConfirmation: !action.payload.weddingGuest.canEditRsvp,
                attendees: action.payload.weddingGuest.additionalGuests.map(createNewAttendee)
            }
        }
        case SAVE_RSVP_DID_SUCCEED: {
            return {
                ...state,
                editWeddingGuest: action.payload.weddingGuest,
                attendees: action.payload.weddingGuest.additionalGuests.map(createNewAttendee),
                showRsvpConfirmation: true,
                rsvpSubmitConfirmationVisible: false,
                ...getInitialSubmitStates()
            }
        }
        case SAVE_RSVP_DID_FAIL: {
            return {
                ...state,
                formErrors: action.payload.errors
            }
        }
        case MODIFY_WEDDING_GUEST: {
            return {
                ...state,
                editWeddingGuest: {
                    ...state.editWeddingGuest,
                    ...sanitiseWeddingGuestData(
                        action.payload.key,
                        action.payload.value
                    )
                }

            }
        }
        case MODIFY_ATTENDEE: {
            const { index, key, value } = action.payload

            return {
                ...state,
                attendees: state.attendees.map((a,i) => {
                    return i === index
                        ? { ...a, ...sanitiseAttendeeData(key, value) }
                        : a
                })
            }
        }
        case REMOVE_ATTENDEE_FIELD: {
            return {
                ...state,
                attendees: state.attendees.filter(
                    (a,i) => i !== action.payload.index)
            }
        }
        case ADD_ATTENDEE_FIELD: {
            return {
                ...state,
                attendees: [
                    ...state.attendees,
                    createNewAttendee()
                ]
            }
        }
        default:
            return state
    }
}

export default reducer
