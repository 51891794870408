import PropTypes from 'prop-types'
import RemoveAttendeeFieldButton from './RemoveAttendeeFieldButton'
import Translations from '../translations'

const AttendeeFormField = ({ attendee, order, onRemoveClick, onChange }) => {
    const handleChange = (e, key) => {
        onChange({ key, value: e.target.value })
    }

    return (
        <div className="flex items-end">
            <div className="w-4/5 flex grow">
                <div className="w-4/5">
                    <label className="text-secondary block mb-1">{ order }</label>
                    <input className="text-primary w-full py-2 px-4 focus:ring-sky-400" type="text"
                        placeholder={ Translations.get('rsvp_form.guests.placeholders.name') }
                        onChange={ (e) => handleChange(e, 'name') }
                        value={ attendee.name }
                        autoComplete="off" />
                </div>
                <div className="w-1/5 min-w-[95px]">
                    <label className="text-secondary text-center block mb-1">
                        { Translations.get('rsvp_form.guests.labels.child_seat') }
                    </label>
                    <select value={attendee.childSeatRequired}
                        onChange={ (e) => handleChange(e, 'childSeatRequired') }
                        className={`cursor-pointer text-center w-full text-white
                            px-6 py-2 bg-secondary hover:bg-secondary-700
                            appearance-none focus:ring-sky-400`}>
                        <option value="true">
                            { Translations.get('rsvp_form.guests.child_seat.options.yes') }
                        </option>
                        <option value="false">
                            { Translations.get('rsvp_form.guests.child_seat.options.no') }
                        </option>
                    </select>
                </div>
            </div>
            <div className="min-w-[66px] ml-2 leading-snug">
                <RemoveAttendeeFieldButton onClick={ onRemoveClick } />
            </div>
        </div>
    )
}

AttendeeFormField.propTypes = {
    order: PropTypes.number.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    attendee: PropTypes.object.isRequired
}

export default AttendeeFormField
