import config from '../../config'
import { createAction } from '@reduxjs/toolkit'

const namespace = 'on_the_day_admin'

export const DELETE_COMMENT = `${namespace}/DELETE_COMMENT`
export const DELETE_COMMENT_DID_SUCCEED = `${namespace}/DELETE_COMMENT_DID_SUCCEED`
export const DELETE_COMMENT_DID_FAIL = `${namespace}/DELETE_COMMENT_DID_FAIL`
export const DELETE_COMMENT_DID_ERROR = `${namespace}/DELETE_COMMENT_DID_ERROR`
export const CANCEL_DELETE_COMMENT = `${namespace}/CANCEL_DELETE_COMMENT`
export const DELETE_COMMENT_DID_START = `${namespace}/DELETE_COMMENT_DID_START`
export const DELETE_COMMENT_DID_END = `${namespace}/DELETE_COMMENT_DID_END`

export const FETCH_COMMENTS_DID_SUCCEED = `${namespace}/FETCH_COMMENTS_DID_SUCCEED`
export const FETCH_COMMENTS_DID_FAIL = `${namespace}/FETCH_COMMENTS_DID_FAIL`

export const fetchComments = () => (dispatch, getState) => {
    fetch(`${config.API_BASE_URL}/projected_comments`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_COMMENTS_DID_SUCCEED,
                payload: {
                    comments: json.data.projectedComments
                }
            })
        } else {
            dispatch({
                type: FETCH_COMMENTS_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
}

export const deleteComment = createAction(
    DELETE_COMMENT,
    (comment) => ({
        payload: {
            comment
        }
    })
)

export const cancelDeleteComment = createAction(
    CANCEL_DELETE_COMMENT
)

export const confirmDeleteComment = () => (dispatch, getState) => {
    const { invitationCode } = getState().invitationScreening
    const { deleteComment: comment } = getState().onTheDayAdmin

    dispatch({
        type: DELETE_COMMENT_DID_START
    })

    fetch(`${config.API_BASE_URL}/projected_comments/${
        comment.id}?invitationCode=${invitationCode}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: DELETE_COMMENT_DID_SUCCEED,
                payload: {
                    comment
                }
            })
        } else {
            dispatch({
                type: DELETE_COMMENT_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
    .catch(err => {
        dispatch({
            type: DELETE_COMMENT_DID_ERROR,
            payload: {
                error: err
            }
        })
    })
    .finally(() => {
        dispatch({
            type: DELETE_COMMENT_DID_END
        })
    })
}
