import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateCommentText, updateCommenterName, submitComment } from './ducks/actions'
import { selectNewComment, selectSubmitCommentErrors, selectCommentIsSubmitting,
    selectCommentIsSubmitted, selectSubmitCommentUncaughtError } from './ducks/selectors'
import Spinner from '../loaders/Spinner'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import CommentErrorMessages from './CommentErrorMessages'
import PostCommentMessage from './PostCommentMessage'
import HoverUnderlineButton from '../buttons/HoverUnderlineButton'

const CommentEditor = ({ newComment, onCommentTextChange, onCommenterNameChange,
    onSubmit, submitCommentErrors, commentIsSubmitting, commentIsSubmitted, submitCommentUncaughtError }) => {

    const handleNameChange = (e) => {
        onCommenterNameChange(e.target.value)
    }

    const handleTextChange = (e) => {
        e.preventDefault()
        onCommentTextChange(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }

    const conditionalErrorClassNames = (key) => {
        return submitCommentErrors && (key in submitCommentErrors)
            ? 'border-2 border-red-500'
            : ''
    }

    return (
        <div>
            { commentIsSubmitted && <PostCommentMessage /> }
            {
                submitCommentUncaughtError
                    ?
                    <div className="my-3 p-3 bg-red-400 text-white">
                        <p className="mb-6">
                            <span className="pb-2 border-b border-white">
                                { Translations.get('on_the_day.users.new_comment.submit_uncaught_error') }
                            </span>
                        </p>
                        {
                            submitCommentUncaughtError &&
                            <p className="">{ submitCommentUncaughtError.message }</p>
                        }
                    </div>
                    : null
            }
            <div>
                <textarea className={`w-full h-[150px] rounded-none p-3 resize-none -mb-[9px]
                    ${ conditionalErrorClassNames('text') } border-0 focus:ring-sky-400`}
                    placeholder={ Translations.get('on_the_day.users.new_comment.placeholders.text') }
                    value={ newComment.text }
                    onChange={ handleTextChange } />
                <CommentErrorMessages
                    camelCaseFieldName={ 'text' } />
            </div>
            <div className="mt-[5px]">
                <div className="flex">
                    <input className={`w-full grow mr-1 p-3 text-center border-0 focus:ring-sky-400
                        ${ conditionalErrorClassNames('commenterName') }`}
                        type="text"
                        placeholder={ Translations.get('on_the_day.users.new_comment.placeholders.commenter_name') }
                        value={ newComment.commenterName }
                        onChange={ handleNameChange }/>

                    <HoverUnderlineButton onClick={ handleSubmit }
                        buttonClassNames="bg-secondary w-36"
                        textClassNames="px-8 py-3 text-white"
                        underlineClassNames="border-white">
                        <div className="flex justify-center">
                            { commentIsSubmitting
                                ?
                                <span className="py-2"><Spinner /></span>
                                :
                                <span>
                                    { Translations.get(
                                        'on_the_day.users.new_comment.submit_button') }
                                </span>
                            }
                        </div>
                    </HoverUnderlineButton>
                </div>
                { commentIsSubmitted && <PostCommentMessage /> }
                <CommentErrorMessages
                    camelCaseFieldName={ 'commenterName' } />
            </div>
        </div>
    )
}

CommentEditor.propTypes = {
    newComment: PropTypes.shape({
        text: PropTypes.string,
        commenterName: PropTypes.string
    }).isRequired,
    onCommentTextChange: PropTypes.func.isRequired,
    onCommenterNameChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitCommentErrors: PropTypes.object.isRequired,
    commentIsSubmitting: PropTypes.bool.isRequired,
    commentIsSubmitted: PropTypes.bool.isRequired,
    submitCommentUncaughtError: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => ({
    newComment: selectNewComment(state),
    submitCommentErrors: selectSubmitCommentErrors(state),
    commentIsSubmitting: selectCommentIsSubmitting(state),
    commentIsSubmitted: selectCommentIsSubmitted(state),
    currentLanguageCode: selectCurrentLanguageCode(state),
    submitCommentUncaughtError: selectSubmitCommentUncaughtError(state)
})

const mapDispatchToProps = (dispatch) => ({
    onCommenterNameChange: (name) => dispatch(updateCommenterName(name)),
    onCommentTextChange: (text) => dispatch(updateCommentText(text)),
    onSubmit: () => dispatch(submitComment())
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditor)
