import config from '../../config'

const imageSources = Array.from([
    'KOU_0007.jpg',
    'KOU_0014.jpg',
    'KOU_0047.jpg',
    'KOU_0062.jpg',
    'KOU_0090.jpg',
    'KOU_0137.jpg',
    'KOU_0145.jpg',
    'KOU_0154.jpg',
    'KOU_0174.jpg',
    'KOU_0199.jpg',
    'KOU_0218.jpg',
    'KOU_0257.jpg',
    'KOU_0267.jpg',
    'KOU_0289.jpg',
    'KOU_0300.jpg',
    'KOU_0312.jpg',
    'KOU_0316.jpg',
    'KOU_0353.jpg',
    'KOU_0366.jpg',
    'KOU_0390.jpg',
    'KOU_0406.jpg',
    'KOU_0432.jpg',
    'KOU_0441.jpg',
    'KOU_0460.jpg',
    'KOU_0468.jpg',
    'KOU_0496.jpg',
    'KOU_0506.jpg',
    'KOU_0523.jpg',
    'KOU_0529.jpg',
    'KOU_0557.jpg',
    'KOU_0562.jpg',
    'KOU_0582.jpg'
]).map((fileName, i) => ({
    title: `#${(i + 1).toString().padStart(3, '0')}`,//fileName.split('.')[0],
    refId: fileName,
    fileName,
    url: `/wedding_photographs_previews/${fileName}`,
    thumbnailUrl: `/wedding_photographs_thumbnails/${fileName}`,
    highResolutionUrl: `/wedding_photographs_original/${fileName}`,
    projectedCommentsUrl: `${config.API_BASE_URL}/on_the_day/projected_comments`,
}))

export default imageSources
