import PropTypes from 'prop-types'
import Link from './Link'
import { connect } from 'react-redux'
import { redirectToRsvpPage } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const RsvpButton = ({ onRsvpClick }) => (
    <Link onClick={ onRsvpClick }>
        <div className="group">
            <p>{ Translations.get('posts.rsvp_button') }</p>
            <div className="w-5 h-5 rounded-full bg-primary animate-ping absolute -top-2 -right-2 group-hover:hidden" />
            <div className="w-5 h-5 rounded-full bg-primary absolute -top-2 -right-2 group-hover:hidden" />
        </div>
    </Link>
)

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onRsvpClick: () => dispatch(redirectToRsvpPage())
})

RsvpButton.propTypes = {
    onRsvpClick: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RsvpButton)
