import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectAudioSources, selectCurrentAudio, selectAudioIsPaused,
    selectTotalPlaylistDuration, selectRepeatablePlaylistDuration } from './ducks/selectors'
import { jumpToAudio } from './ducks/actions'
import SoundBars from '../loaders/SoundBars'

const Playlist = ({ audioSources, onClick, currentAudio, isPaused,
    totalDuration, repeatableDuration }) => {
    const isCurrentAudio = (src) => currentAudio.playOrder === src.playOrder

    const padDuration = (number) => number.toString().padStart(2, '0')

    return (
        <div className="bg-texture-[asfalt-light]">
            <div className="bg-rose-600/40 text-neutral-300 p-2 text-xs flex flex-wrap justify-between">
                <div className="mb-1 mr-8 border-l border-rose-400 pl-2">
                    <span className="inline-block w-[80px] text-white">Total Time</span>
                    <span className="inline-block">{ padDuration(totalDuration.minutes) }:</span>
                    <span className="inline-block">{ padDuration(totalDuration.seconds) }</span>
                </div>
                <div className="mb-1 border-l border-rose-400 pl-2">
                    <span className="inline-block w-[80px] text-white">Loop Time</span>
                    <span className="inline-block">{ padDuration(repeatableDuration.minutes) }:</span>
                    <span className="inline-block">{ padDuration(repeatableDuration.seconds) }</span>
                </div>
            </div>
            { audioSources.map((src, i) =>
                <div key={i}
                    className={`group p-3 px-2 border-b-2 border-secondary/25 last:border-b-0
                        cursor-pointer hover:bg-white/80 hover:text-black text-white
                        ${ isCurrentAudio(src) ? 'bg-secondary/40' : 'bg-black/10' }`}
                    onClick={() => onClick(i)}>
                    <div className={`border-l-2 pl-2 group-hover:border-neutral-800
                        ${ isCurrentAudio(src) ? 'border-white' : 'border-secondary/75' }`}>
                        <div>
                            <span className="text-sm">{ src.playOrder }.</span>
                            { isCurrentAudio(src) && !isPaused &&
                                <div className="relative inline-block scale-50 top-2">
                                    <SoundBars barClassNames="group-hover:bg-neutral-800" />
                                </div>
                            }
                            <span className="ml-1">{ src.title }</span>
                        </div>
                        <p className="indent-4 text-xs">
                            <span>{ src.artist }</span>
                        </p>
                        <p className="indent-4 text-xs">
                            <span className={`text-neutral-400 group-hover:text-neutral-600
                                ${ isCurrentAudio(src) ? 'text-neutral-300' : '' }`}>
                                { src.duration.minutes }:
                                { src.duration.seconds.toString().padStart(2,'0') }
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

Playlist.propTypes = {
    audioSources: PropTypes.arrayOf(PropTypes.shape({
        playOrder: PropTypes.number.isRequired
    })),
    onClick: PropTypes.func.isRequired,
    currentAudio: PropTypes.shape({
        playOrder: PropTypes.number.isRequired
    }),
    isPaused: PropTypes.bool.isRequired,
    totalDuration: PropTypes.shape({
        minutes: PropTypes.number.isRequired,
        seconds: PropTypes.number.isRequired
    }),
    repeatableDuration: PropTypes.shape({
        minutes: PropTypes.number.isRequired,
        seconds: PropTypes.number.isRequired
    })
}

const mapStateToProps = (state) => ({
    audioSources: selectAudioSources(),
    currentAudio: selectCurrentAudio(state),
    isPaused: selectAudioIsPaused(state),
    totalDuration: selectTotalPlaylistDuration(),
    repeatableDuration: selectRepeatablePlaylistDuration()
})

const mapDispatchToProps = (dispatch) => ({
    onClick: (i) => dispatch(jumpToAudio(i))
})

export default connect(mapStateToProps, mapDispatchToProps)(Playlist)
