import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { deleteComment } from './ducks/actions'

const DeleteCommentButton = ({ comment, onClick }) => {
    const handleClick = (e) => {
        e.preventDefault()
        onClick(comment)
    }

    return (
        <button className="bg-orange-600 text-white px-2" onClick={ handleClick }>
            { Translations.get('on_the_day.users.comments_section.comments.delete_button') }
        </button>
    )
}

DeleteCommentButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    comment: PropTypes.shape({
        commenterOnceOffId: PropTypes.string.isRequired
    }).isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onClick: (comment) => dispatch(deleteComment(comment))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCommentButton)
