const mediaSources = Array.from([
    'KOU_0007.jpg',
    'KOU_0090.jpg',
    'KOU_0137.jpg',
    'KOU_0257.jpg',
    'KOU_0289.jpg',
    'KOU_0300.jpg',
    'KOU_0312.jpg',
    'KOU_0366.jpg',
    'KOU_0390.jpg',
    'KOU_0432.jpg',
    'KOU_0441.jpg',
    'KOU_0460.jpg',
    'KOU_0468.jpg',
    'KOU_0506.jpg',
    'KOU_0523.jpg',
    'KOU_0529.jpg',
]).map((fileName, i) => ({
    title: `#${(i + 1).toString().padStart(3, '0')}`,
    refId: fileName,
    url: `/wedding_photographs_previews/${fileName}`,
    thumbnailUrl: `/wedding_photographs_thumbnails/${fileName}`,
    highResolutionUrl: `/wedding_photographs_original/${fileName}`
}))

export default mediaSources
