import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import Link from './Link'
import {
    redirectToRsvpPage,
    redirectToAttendeesPage,
    redirectToWeddingPhotographsPage,
    redirectToProjectionPage,
    redirectToProjectedCommentsAdminPage,
    redirectToWeddingPhotographsAdminPage,
    resetCurrentSession
} from './ducks/actions'
import { selectIsAdmin, selectHasRepliedRsvp }
    from './ducks/selectors'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const UsefulLinksSection = ({ isAdmin, onRsvpClick, onViewAttendeesClick,
    onViewWeddingPhotographsClick, hasRepliedRsvp, onViewProjectionClick,
    onViewProjectedCommentsClick, onManageWeddingPhotographsCommentsClick,
    onSignOutClick }) => {

    const handleRsvpClick = (e) => {
        e.preventDefault()
        onRsvpClick()
    }

    const handleViewWeddingPhotographsClick = (e) => {
        e.preventDefault()
        onViewWeddingPhotographsClick()
    }

    const handleViewAttendeesClick = (e) => {
        e.preventDefault()
        onViewAttendeesClick()
    }

    const handleViewProjectionClick = (e) => {
        e.preventDefault()
        onViewProjectionClick()
    }

    const handleViewProjectedCommentsClick = (e) => {
        e.preventDefault()
        onViewProjectedCommentsClick()
    }

    const handleManageWeddingPhotographsCommentsClick = (e) => {
        e.preventDefault()
        onManageWeddingPhotographsCommentsClick()
    }

    const handleSignOutClick = (e) => {
        e.preventDefault()
        onSignOutClick()
    }

    return (
        <div>
            <div className="">
                <div className="md:flex lg:block">
                    <div className="w-full md:w-1/2 lg:w-full md:mr-2 lg:mr-0">
                        <Link text={
                            hasRepliedRsvp
                                ? Translations.get('home_page.links.rsvp.view_only')
                                : Translations.get('home_page.links.rsvp')
                            }
                            onClick={ handleRsvpClick } />
                    </div>
                    <div className="w-full md:w-1/2 lg:w-full md:ml-2 lg:ml-0">
                        <Link text={ Translations.get('home_page.links.view_gallery') }
                            onClick={ handleViewWeddingPhotographsClick } />
                    </div>
                </div>
            </div>
            { isAdmin &&
                <div className="pb-4">
                    <hr className="mt-4 mb-6" />
                    <p className="mb-6 text-xl text-center">
                        { Translations.get('home_page.admin_tools.header') }
                    </p>
                    <Link text={ Translations.get('home_page.links.attendees') }
                        onClick={ handleViewAttendeesClick } />
                    <Link text={ Translations.get('home_page.links.projection') }
                        onClick={ handleViewProjectionClick } />
                    <Link text={ Translations.get('home_page.links.manage_wedding_photographs_comments') }
                        onClick={ handleManageWeddingPhotographsCommentsClick } />
                    <Link text={ Translations.get('home_page.links.manage_projected_comments') }
                        onClick={ handleViewProjectedCommentsClick } />
                    <Link text={ Translations.get('sign_out') }
                        onClick={ handleSignOutClick }/>
                </div>
            }
        </div>
    )
}

UsefulLinksSection.propTypes = {
    onRsvpClick: PropTypes.func.isRequired,
    onViewAttendeesClick: PropTypes.func.isRequired,
    onViewWeddingPhotographsClick: PropTypes.func.isRequired,
    hasRepliedRsvp: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onViewProjectedCommentsClick: PropTypes.func.isRequired,
    onManageWeddingPhotographsCommentsClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isAdmin: selectIsAdmin(state),
    hasRepliedRsvp: selectHasRepliedRsvp(state),
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onRsvpClick: () => dispatch(redirectToRsvpPage()),
    onViewAttendeesClick: () => dispatch(redirectToAttendeesPage()),
    onViewWeddingPhotographsClick: () => dispatch(redirectToWeddingPhotographsPage()),
    onViewProjectionClick: () => dispatch(redirectToProjectionPage()),
    onViewProjectedCommentsClick: () => dispatch(redirectToProjectedCommentsAdminPage()),
    onManageWeddingPhotographsCommentsClick: () => dispatch(redirectToWeddingPhotographsAdminPage()),
    onSignOutClick: () => dispatch(resetCurrentSession())
})

export default connect(mapStateToProps, mapDispatchToProps)(UsefulLinksSection)
