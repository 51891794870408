import PropTypes from 'prop-types'

const ProjectedComment = ({ comment }) => (
    <div className="m-auto w-[94%] bg-secondary/60 text-white py-2 px-4 mb-3">
        <pre className="font-sans whitespace-pre-wrap w-full text-lg">
            {comment.text}
        </pre>
        <p className="text-right mt-4">— {comment.commenterName}</p>
    </div>
)

ProjectedComment.propTypes = {
    comment: PropTypes.shape({
        commenterName: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    }).isRequired
}

export default ProjectedComment
