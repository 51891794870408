import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentMedia, getModalVisibility } from './ducks/selectors'
import CloseModal from './CloseModal'
import RightArrow from './RightArrow'
import LeftArrow from './LeftArrow'
import Pagination from './Pagination'
import { incrementGalleryMediaIndex, decrementGalleryMediaIndex } from './ducks/actions'

// <p className={`capitalize hidden h-md:block max-w-[300px]
//     pb-3 border-white border-b m-auto
//     text-md md:text-2xl text-center text-white`}>
//     {mediaObject.title}
// </p>

const Modal = ({ mediaObject, visible, onSwipeRight, onSwipeLeft }) => {
    return visible && mediaObject
        ?
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black/95 select-none z-50">
            <div className="flex justify-end mt-6 mb-2 mr-6">
                <CloseModal />
            </div>
            <div className="flex justify-center content-center items-center">
                <div className="hidden md:block"><LeftArrow /></div>
                <div className="w-full h-[100vh] md:max-w-[90vw] max-h-[65vh] h-md:max-h-[85vh]"
                    style={{
                        backgroundImage: `url(${mediaObject.highResolutionUrl}), url(${mediaObject.url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center'
                    }}
                    draggable={false} />
                <div className="hidden md:block"><RightArrow /></div>
            </div>
            <div className="flex justify-around mt-2 md:mt-0">
                <div className="md:hidden"><LeftArrow /></div>
                <Pagination />
                <div className="md:hidden"><RightArrow /></div>
            </div>
        </div>
        : null
}

Modal.propTypes = {
    mediaObject: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onSwipeLeft: PropTypes.func.isRequired,
    onSwipeRight: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    mediaObject: getCurrentMedia(state),
    visible: getModalVisibility(state)
})

const mapDispatchToProps = (dispatch) => ({
    onSwipeRight: () => dispatch(incrementGalleryMediaIndex()),
    onSwipeLeft: () => dispatch(decrementGalleryMediaIndex())
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
