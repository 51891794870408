import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    HOME_PAGE,
    INVITATION_PAGE,
    RSVP_PAGE,
    ATTENDEES_PAGE,
    WEDDING_PHOTOGRAPHS_PAGE,
    PROJECTION_PAGE,
    NEW_PROJECTED_COMMENT_PAGE,
    PROJECTED_COMMENTS_ADMIN_PAGE,
    WEDDING_PHOTOGRAPHS_ADMIN_PAGE
} from './ducks/actions'
import { selectCurrentPage } from './ducks/selectors'
import InvitationPage from '../invitation_screening/InvitationPage'
import HomePage from '../home/HomePage'
import RsvpPage from '../rsvp/RsvpPage'
import AttendeesPage from '../attendees/AttendeesPage'
import WeddingPhotographsPage from '../wedding_photographs/WeddingPhotographsPage'
import ProjectionPage from '../on_the_day_projection/ProjectionPage'
import TranslatablePage from '../languages/TranslatablePage'
import NewProjectedCommentPage from '../on_the_day_users/NewProjectedCommentPage'
import ProjectedCommentsManagementPage from '../on_the_day_admin/CommentsManagementPage'
import WeddingPhotographsCommentsManagementPage from '../wedding_photographs_admin/CommentsManagementPage'

const withTranslations = (Page) => (
    <TranslatablePage>
        <Page />
    </TranslatablePage>
)

const Router = ({ currentPage }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    switch(currentPage) {
        case HOME_PAGE:
            return withTranslations(HomePage)
        case RSVP_PAGE:
            return withTranslations(RsvpPage)
        case ATTENDEES_PAGE:
            return withTranslations(AttendeesPage)
        case WEDDING_PHOTOGRAPHS_PAGE:
            return withTranslations(WeddingPhotographsPage)
        case PROJECTION_PAGE:
            return <ProjectionPage />
        case NEW_PROJECTED_COMMENT_PAGE:
            return <NewProjectedCommentPage />
        case PROJECTED_COMMENTS_ADMIN_PAGE:
            return withTranslations(ProjectedCommentsManagementPage)
        case WEDDING_PHOTOGRAPHS_ADMIN_PAGE:
            return withTranslations(WeddingPhotographsCommentsManagementPage)
        case INVITATION_PAGE:
        default:
            return withTranslations(InvitationPage)
    }
}

Router.propTypes = {
    currentPage: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    currentPage: selectCurrentPage(state)
})

export default connect(mapStateToProps)(Router)
