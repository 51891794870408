/**
 * Credits to https://codepen.io/jackrugile/pen/nryeoA
*/
import PropTypes from 'prop-types'

const specificBarClassNames = [
    { left: '1px', animationDuration: '474ms' },
    { left: '5px', animationDuration: '433ms' },
    { left: '9px', animationDuration: '407ms' },
    { left: '13px', animationDuration: '458ms' },
    { left: '17px', animationDuration: '400ms' },
    { left: '21px', animationDuration: '427ms' },
    { left: '25px', animationDuration: '441ms' },
    { left: '29px', animationDuration: '419ms' },
    { left: '33px', animationDuration: '487ms' },
    { left: '37px', animationDuration: '442ms' }
]

const SoundBars = ({ color = 'white', barClassNames, containerClassNames }) => (
    <div className="relative h-[30px] w-[40px]">
        { specificBarClassNames.map((bar, i) =>
            <div key={i} className={`h-[3px] w-[3px] absolute bottom-[1px]
                bg-${color} animate-soundBars ${barClassNames || ''}`}
                style={{ ...bar }}/>
        )}
    </div>
)

SoundBars.propTypes = {
    color: PropTypes.string, // optional
    containerClassNames: PropTypes.string,
    barClassNames: PropTypes.string
}

export default SoundBars
