import {
    FETCH_COMMENTS_DID_SUCCEED,
    // FETCH_COMMENTS_DID_FAIL,
    DELETE_COMMENT_DID_SUCCEED,
    DELETE_COMMENT_DID_FAIL,
    DELETE_COMMENT_DID_START,
    DELETE_COMMENT_DID_END,
    DELETE_COMMENT_DID_ERROR,
    DELETE_COMMENT,
    CANCEL_DELETE_COMMENT
} from './actions'

const getInitialDeleteStates = () => ({
    deleteComment: {},
    isDeleting: false,
    deleteErrors: {},
    deleteUncaughtError: null
})

const initialState = {
    comments: {},
    deleteConfirmationVisible: false,
    ...getInitialDeleteStates()
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_COMMENTS_DID_SUCCEED: {
            const { comments } = action.payload

            return {
                ...state,
                comments: comments.reduce((obj, c) => ({
                    ...obj,
                    [c.refId]: [
                        ...(obj[c.refId] || []),
                        c
                    ]
                }), {})
            }
        }
        case DELETE_COMMENT: {
            const { comment } = action.payload

            return {
                ...state,
                ...getInitialDeleteStates(),
                deleteComment: comment,
                deleteConfirmationVisible: true
            }
        }
        case CANCEL_DELETE_COMMENT: {
            return {
                ...state,
                ...getInitialDeleteStates(),
                deleteConfirmationVisible: false
            }
        }
        case DELETE_COMMENT_DID_START: {
            return {
                ...state,
                isDeleting: true
            }
        }
        case DELETE_COMMENT_DID_END: {
            return {
                ...state,
                isDeleting: false
            }
        }
        case DELETE_COMMENT_DID_SUCCEED: {
            const { comment } = action.payload
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [comment.refId]: state.comments[comment.refId].filter(c =>
                        c.id !== comment.id)
                },
                deleteConfirmationVisible: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT_DID_FAIL: {
            return {
                ...state,
                deleteErrors: action.payload.errors
            }
        }
        case DELETE_COMMENT_DID_ERROR: {
            return {
                ...state,
                deleteUncaughtError: action.payload.error
            }
        }
        default:
            return state
    }
}

export default reducer
