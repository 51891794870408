import { connect } from 'react-redux'
import { closeOriginalMedia } from './ducks/actions'

const CloseModal = ({onClick}) => (
    <div onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg"
            className="group w-[25px] h-[25px] xl:w-[40px] xl:h-[40px] cursor-pointer"
            viewBox="-1 -1 11 11"
            version="1.1">
            <path className="group-hover:stroke-secondary" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeWidth="0.3"
                d="m0 0 9,9 M0 9 9,0" />
        </svg>
    </div>
)

const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch(closeOriginalMedia())
})

export default connect(null, mapDispatchToProps)(CloseModal)
