import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HomeButton from '../router/HomeButton'
import RsvpForm from './RsvpForm'
import RsvpConfirmation from './RsvpConfirmation'
import { selectRsvpConfirmationVisibility } from './ducks/selectors'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import Translations from '../translations'
import { fetchRsvpEntry } from '../rsvp/ducks/actions'

const RsvpPage = ({ showRsvpConfirmation, onFetchRsvpEntry }) => {
    let [fetchedOnce, setFetchedOnce] = useState(false)

    if (!fetchedOnce) {
        setFetchedOnce(true)
        onFetchRsvpEntry()
    }

    useEffect(() => {
        document.title = 'RSVP | Rita & Eddy 2022'
    })

    return (
        <div className="rsvp min-h-[100vh]">
            <div className="pt-4 pl-4">
                <HomeButton />
            </div>
            <div className="flex flex-wrap justify-center items-center">
                <div className="w-[96%] max-w-[500px] px-4">
                    <div className="border-b border-red-400 mb-8">
                        <h1 className={`w-full font-cursive text-center
                            text-secondary text-[85px] tracking-widest`}>
                            R.S.V.P
                        </h1>
                        <img className="w-24 h-24 hidden md:block m-auto mb-6 select-none"
                            alt="logo" src="/logo-light.svg" draggable={false}/>
                        <div className="flex justify-center">
                            <p className="text-secondary text-2xl mb-8">
                                <span className="text-red-400">
                                    <span className="mx-4 ml-0">囍</span>
                                    <span className="mx-4">宴</span>
                                    <img className="inline w-12 h-12 md:hidden"
                                        alt="logo" src="/logo-light.svg" draggable={false} />
                                    <span className="mx-4">回</span>
                                    <span className="mx-4 mr-0">函</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    { showRsvpConfirmation
                        ? <RsvpConfirmation />
                        : <RsvpForm />
                    }
                </div>
                <div className="w-full mt-20 mb-8 px-4">
                    <p className="text-sm text-center text-white mt-2">
                        * { Translations.get('rsvp_confirmation.footer') }
                    </p>
                </div>
            </div>
        </div>
    )
}

RsvpPage.propTypes = {
    showRsvpConfirmation: PropTypes.bool.isRequired,
    onFetchRsvpEntry: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    showRsvpConfirmation: selectRsvpConfirmationVisibility(state),
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFetchRsvpEntry: () => dispatch(fetchRsvpEntry())
})

export default connect(mapStateToProps, mapDispatchToProps)(RsvpPage)
