import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfirmationModal from '../modals/ConfirmationModal'
import { selectDeleteComment, selectCommentIsDeleting, selectDeleteErrors,
    selectDeleteUncaughtError } from './ducks/selectors'
import { confirmDeleteComment, cancelDeleteComment } from './ducks/actions'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const DeleteCommentConfirmation = ({ comment, isDeleting, onConfirm, onCancel,
        deleteErrors, deleteUncaughtError }) => (
    <ConfirmationModal isProcessing={ isDeleting }
        onConfirm={ onConfirm } onCancel={ onCancel }>
        <p className="mb-4">
            { Translations.get('on_the_day.admin.comments.delete_confirmation',
                comment.commenterName) }
        </p>
        <p className="mb-4">"{ comment.text }"</p>
        {
            _.isEmpty(deleteErrors) && !deleteUncaughtError
                ? null
                :
                <div className="text-red-500">
                    { deleteUncaughtError &&
                        <p>{ deleteUncaughtError.message }</p>
                    }
                    { Object.keys(deleteErrors).map(k =>
                        deleteErrors[k].map(v =>
                            <p key={`${k}.${v}`}>
                                { Translations.get(`on_the_day.admin.comments.delete_errors.${k}.${v.error}`) }
                            </p>
                        )
                    )}
                </div>
        }
    </ConfirmationModal>
)

DeleteCommentConfirmation.propTypes = {
    comment: PropTypes.shape({
        id: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
        commenterName: PropTypes.string.isRequired,
        commenterOnceOffId: PropTypes.string.isRequired,
    }),
    isDeleting: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    deleteErrors: PropTypes.object.isRequired,
    deleteCommentUncaughtError: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => ({
    comment: selectDeleteComment(state),
    isDeleting: selectCommentIsDeleting(state),
    deleteErrors: selectDeleteErrors(state),
    deleteUncaughtError: selectDeleteUncaughtError(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onConfirm: () => dispatch(confirmDeleteComment()),
    onCancel: () => dispatch(cancelDeleteComment())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCommentConfirmation)
