import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideProjectionHelp } from './ducks/actions'
import HomeButton from '../router/HomeButton'
import { selectIsAdmin } from '../invitation_screening/ducks/selectors'

const QnA = ({ q, a, children }) => (
    <div className="mt-4 mb-8 leading-loose border-b border-secondary pb-2">
        <p className="text-lg border-l-2 pl-2 border-secondary">{ q }</p>
        { children || <p>{ a }</p> }
    </div>
)

const CloseButton = ({ onClick }) => {
    const handleClick = (e) => {
        e.preventDefault()
        onClick()
    }

    return (
        <button type="button"
            className="border-b-2 border-secondary" onClick={handleClick}>
            <span>Close</span>
        </button>
    )
}

const mapDispatchToCloseButtonProps = (dispatch) => ({
    onClick: () => dispatch(hideProjectionHelp())
})

const ConnectedCloseButton = connect(null,
    mapDispatchToCloseButtonProps)(CloseButton)

const ProjectionHelp = ({ isAdmin }) => (
    <div className="text-white p-8 w-full h-full overflow-auto">
        { isAdmin && <HomeButton /> }
        <div className="text-right"><ConnectedCloseButton /></div>
        <h2 className="text-xl mt-4 mb-12">How to ...</h2>

        <QnA q="Pause or play background audio without pausing slideshow"
            a="Press [space] key" />

        <QnA q="Mute or unmute background audio without pausing audio and slideshow"
            a="Press [m] key" />

        <QnA q="Change songs"
            a="Place cursor over audio visualiser, click carat ▼ to expand playlist and click on another song" />

        <QnA q="Hide or show audio visualiser"
            a="Press [h] key" />

        <QnA q="Hide or show animated balloons"
            a="Press [b] key" />

        <QnA q="Display a larger QR Code">
            <p>
                Add a search parameter behind the projection url,
                <span className="text-secondary"> {window.origin}/projection?qrCodeScale=3</span>
            </p>
            <p>Replace default value of [3] with a digit(s) between 1-10</p>
        </QnA>

        <QnA q="Use low resolution images (unstable network)">
            <p>
                Add a search parameter behind the projection url,
                <span className="text-secondary"> {window.origin}/projection?highResolution=false</span>
            </p>
        </QnA>

        <QnA q="Adjust duration of each slide (in seconds)">
            <p>
                Add a search parameter behind the projection url,
                <span className="text-secondary"> {window.origin}/projection?slideDuration=15</span>
            </p>
            <p>Replace default value of [15] seconds any other digit(s)</p>
        </QnA>

        <QnA q="Commence projection with a specific audio track">
            <p>
                Add a search parameter behind the projection url,
                <span className="text-secondary"> {window.origin}/projection?audioIndex=0</span>
            </p>
            <p>Replace default value of [0] with a digit(s) between 0-11</p>
        </QnA>

        <QnA q="Commence projection with a specific photograph">
            <p>qrCodeScale
                Add a search parameter behind the projection url,
                <span className="text-secondary"> {window.origin}/projection?imageIndex=0</span>
            </p>
            <p>Replace default value of [0] with a digit(s) between 0-31</p>
        </QnA>


        <QnA q="Combine multiple options">
            <p>
                Format the url to read multiple paramters,
                <span className="text-secondary"> {window.origin}/projection?qrCodeScale=3&slideDuration=15</span>
            </p>
            <p>Using [&] after the prior option to apply multiple options together</p>
        </QnA>
    </div>
)

ProjectionHelp.propTypes = {
    isAdmin: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    isAdmin: selectIsAdmin(state)
})

export default connect(mapStateToProps)(ProjectionHelp)
