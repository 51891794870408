/**
 * Create 2 background tasks to check for designated time
 * to hide audio visualiser. Adjust time to whenever
 * live band is allocated to perform
*/
import m from 'moment'
import store from '../redux/store'
import { hideNowPlaying } from '../on_the_day_projection/ducks/actions'

const CHECKER_INTERVAL = 15000
const MAX_DELAY = 2147483647
const HIDE_VISUALISER_AT = '2022-11-25 18:30'
const hideVisualiserMoment = m(HIDE_VISUALISER_AT)

console.log(`Audio visualiser is scheduled to hide at ${hideVisualiserMoment.format('llll')}`)

export const getTimeLeftInMilliseconds = () => {
    const now = Date.now()
    const later = hideVisualiserMoment.valueOf()

    if (now > later) {
        return 0 // expired
    } else {
        return later - now
    }
}

const timeoutDelay = getTimeLeftInMilliseconds()

let backgroundCheckerTimeout
let backgroundCheckerInterval

const closeVisualiser = () => {
    console.log(`Reached designated time to hide audio visualiser at ${hideVisualiserMoment.format('llll')}`)
    store.dispatch(hideNowPlaying())
    clearInterval(backgroundCheckerInterval)
    backgroundCheckerInterval = null
    clearTimeout(backgroundCheckerTimeout)
    backgroundCheckerTimeout = null
}

if (timeoutDelay < MAX_DELAY) {
    backgroundCheckerTimeout = setTimeout(
        closeVisualiser,
        timeoutDelay
    )
} else {
    console.log(`Unable to set timeout for hiding audio visualiser as delay time %c[${
        timeoutDelay}] %cexceeds maximum allowed %c[${
        MAX_DELAY}]%c. Fall back to using interval checker.`,
        'color: red',
        'color: black',
        'color: green',
        'color: black'
    )
}

backgroundCheckerInterval = setInterval(() => {
    const now = Date.now()

    if (now > hideVisualiserMoment.valueOf()) {
        closeVisualiser()
    }
}, CHECKER_INTERVAL)
