import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { selectSubmitCommentErrors } from './ducks/selectors'

const CommentErrorMessages = ({ camelCaseFieldName, submitCommentErrors }) => {
    const k = camelCaseFieldName

    return submitCommentErrors[k]
        ?
        <div className="mb-4 px-3 py-1 bg-red-500">
            { submitCommentErrors[k].map(v =>
                <p key={v.error} className="text-white">
                    { Translations.get(`on_the_day.users.new_comment.errors.${
                        _.snakeCase(k)}.${v.error}`) }
                </p>
            )}
        </div>
        : null
}

CommentErrorMessages.propTypes = {
    camelCaseFieldName: PropTypes.string.isRequired,
    submitCommentErrors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state),
    submitCommentErrors: selectSubmitCommentErrors(state)
})

export default connect(mapStateToProps)(CommentErrorMessages)
