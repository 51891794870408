import * as RouterActions from '../../router/ducks/actions'

const namespace = 'home'

export const redirectToRsvpPage = RouterActions.redirectToRsvpPage
export const redirectToWeddingPhotographsPage = RouterActions.redirectToWeddingPhotographsPage
export const redirectToAttendeesPage = RouterActions.redirectToAttendeesPage
export const redirectToProjectionPage = RouterActions.redirectToProjectionPage
export const redirectToProjectedCommentsAdminPage = RouterActions.redirectToProjectedCommentsAdminPage
export const redirectToWeddingPhotographsAdminPage = RouterActions.redirectToWeddingPhotographsAdminPage
export const resetCurrentSession = RouterActions.resetCurrentSession

export const HIDE_ARROW_DOWN = `${namespace}/HIDE_ARROW_DOWN`
export const SHOW_ARROW_DOWN = `${namespace}/SHOW_ARROW_DOWN`

export const hideArrowDown = () => ({
    type: HIDE_ARROW_DOWN
})

export const showArrowDown = () => ({
    type: SHOW_ARROW_DOWN
})
