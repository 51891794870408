import PropTypes from 'prop-types'

export const RsvpDataPropType = PropTypes.shape({
    weddingGuestId: PropTypes.any.isRequired,
    data: PropTypes.shape({
        id: PropTypes.any.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string,
        contactNumber: PropTypes.string.isRequired,
        isAttending: PropTypes.bool.isRequired,
        additionalGuests: PropTypes.arrayOf(PropTypes.object).isRequired,
        canEditRsvp: PropTypes.bool.isRequired,
    })
})
