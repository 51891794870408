import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectRefId, selectCurrentImage, selectCurrentImageIndex } from './ducks/selectors'
import PhotographSelector from './PhotographSelector'
import CommentsSection from './CommentsSection'
import PhotographCounter from './PhotographCounter'
import CommentEditor from './CommentEditor'
import LanguageToggle from '../languages/LanguageToggle'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const NewProjectedCommentPage = ({ refId, currentImage, currentImageIndex }) => {
    useEffect(() => {
        document.title = `Comments #${currentImageIndex + 1} | Rita & Eddy 2022`
    })

    return (
        <div className={`w-[100vw] bg-texture-[asfalt-light]`}>
            <div className="flex justify-end pt-2">
                <LanguageToggle light={ true } />
            </div>
            <div className="w-[100vw] max-w-[600px] m-auto pb-8 leading-8">
                <div className="mt-3 mb-6">
                    <h1 className="text-center text-6xl font-cursive text-secondary select-none">
                        Rita & Eddy
                    </h1>
                </div>
                <div className="w-full h-[60vh] min-h-[400px] max-h-[600px] bg-black">
                    <div className="w-full h-full flex items-end justify-center"
                        style={{
                            backgroundImage: `url(${currentImage.url})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'
                        }}>
                        <div className="text-white bg-black/40 px-4">
                            <PhotographCounter />
                        </div>
                    </div>
                </div>
                <div className="px-2 sm:px-0 mt-2">
                    <CommentEditor />
                    <hr className="border-secondary my-6" />
                    <div className="text-lg">
                        <p className="text-white px-3 mb-2">
                            { Translations.get('on_the_day.users.photograph_selector.header') }
                        </p>
                        <p className="text-white px-3 mb-4">
                            { Translations.get('on_the_day.users.photograph_selector.subheader') }
                        </p>
                        <PhotographSelector />
                    </div>
                    <hr className="border-secondary my-6" />
                    <CommentsSection />
                </div>
            </div>
        </div>
    )
}

NewProjectedCommentPage.propTypes = {
    currentImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
        highResolutionUrl: PropTypes.string.isRequired
    }).isRequired,
    currentImageIndex: PropTypes.number.isRequired,
    refId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    currentImage: selectCurrentImage(state),
    currentImageIndex: selectCurrentImageIndex(state),
    refId: selectRefId(state),
    selectCurrentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(NewProjectedCommentPage)
