const bannerDateClassNames = Array.from([
    'absolute bottom-[25px] left-[15%] w-4/5',
    'text-right text-2xl md:text-3xl lg:text-5xl',
    'font-cursive'
]).join(' ')

const BannerDate = () => (
    <p className={ bannerDateClassNames }>Nov. 25, 2022</p>
)

export default BannerDate
