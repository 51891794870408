import _ from 'lodash'
import PropTypes from 'prop-types'

const Link = ({ text, onClick, download }) => (
    <div className="group">
        <button type="button"
            className="mb-4 w-full group-hover:bg-white/80 group-hover:text-black group-hover:border-0"
            onClick={ onClick }>
            {
                _.isEmpty(download)
                    ? <span className="border-2 block px-4 py-2">{text}</span>
                    : <a href={download.url} download={download.name} target="_blank" rel="noreferrer">
                        <span className="border-2 block px-4 py-2">{text}</span>
                    </a>
            }
            <span className="block w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b-2 border-red-600"></span>
        </button>
    </div>
)

Link.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    download: PropTypes.object
}

export default Link
