import { combineReducers } from 'redux'
import { RESET_SESSION } from '../router/ducks/actions'
import router from '../router'
import rsvp from '../rsvp'
import attendees from '../attendees'
import weddingPhotographs from '../wedding_photographs'
import invitationScreening from '../invitation_screening'
import languages from '../languages'
import home from '../home'
import onTheDayProjection from '../on_the_day_projection'
import onTheDayUsers from '../on_the_day_users'
import onTheDayAdmin from '../on_the_day_admin'
import weddingPhotographsAdmin from '../wedding_photographs_admin'
import posts from '../posts'

const appReducer = combineReducers({
    router,
    rsvp,
    attendees,
    weddingPhotographs,
    invitationScreening,
    languages,
    home,
    onTheDayProjection,
    onTheDayUsers,
    onTheDayAdmin,
    weddingPhotographsAdmin,
    posts
})

const rootReducer = (state, action) => appReducer(
    (action.type === RESET_SESSION ? undefined : state),
    action
)

export default rootReducer
