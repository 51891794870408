import { createSelector } from 'reselect'
import * as RsvpSelectors from '../../rsvp/ducks/selectors'
import * as InvitationScreeningSelectors from '../../invitation_screening/ducks/selectors'

export const selectIsAdmin = InvitationScreeningSelectors.selectIsAdmin

export const selectHasRepliedRsvp = RsvpSelectors.selectHasRepliedRsvp

export const selectDisplayArrownDown = createSelector(
    state => state.home.displayArrowDown,
    displayArrowDown => displayArrowDown
)
