import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import m from 'moment'
import { confirmDeleteComment } from './ducks/actions'
import { selectIsAdmin, selectInvitationCodeId } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const Comment = ({ comment, onConfirmDeleteComment, isAdmin, currentInvitationCodeId }) => {
    const handleConfirmDeleteComment = (c) => {
        onConfirmDeleteComment(c)
    }

    const isCommenter = currentInvitationCodeId &&
        (comment.commenter.invitationCodeId === currentInvitationCodeId)

    return (
        <div className="mt-2 pb-2 border-b border-neutral-400">
            <p className="text-sky-400">{ comment.commenter.name }</p>
            <pre className="font-sans tracking-wide whitespace-pre-wrap">{ comment.text }</pre>
            <div className="flex justify-between my-2 text-xs">
                <p className="text-neutral-400">
                    { m(new Date(comment.createdAt)).format('lll') }
                </p>
                { (isAdmin || isCommenter) &&
                    <button className="text-red-400"
                        onClick={() => handleConfirmDeleteComment(comment)}>
                        { Translations.get('gallery.comments.delete') }
                    </button>
                }
            </div>
        </div>
    )
}

Comment.propTypes = {
    onConfirmDeleteComment: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    currentInvitationCodeId: PropTypes.number
}

const mapStateToProps = (state) => ({
    isAdmin: selectIsAdmin(state),
    currentLanguageCode: selectCurrentLanguageCode(state),
    currentInvitationCodeId: selectInvitationCodeId(state)
})

const mapDispatchToProps = (dispatch) => ({
    onConfirmDeleteComment: (c) => dispatch(confirmDeleteComment(c))
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
