import { connect } from 'react-redux'
import m from 'moment'
import PropTypes from 'prop-types'
import { RsvpDataPropType } from './ducks/prop-types'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import RsvpDetails from './RsvpDetails'

// order refers to index in array (latest to earliest)
// reverseOrder refresh to display order
const HistoricRsvpDetails = ({ rsvpData, order, reverseOrder }) => (
    <div className="mb-12 w-full md:w-1/2 xl:w-1/3 hover:!opacity-100"
        style={{ opacity: 1.5/order }}>
        <div className="mb-6 pl-2 border-l-2 border-secondary">
            <h2 className="text-2xl text-secondary">
                { Translations.get('attendees_page.rsvp_history.historic.header',
                    reverseOrder) }
            </h2>
            <p className="mt-2">{ m(rsvpData.updatedAt).format('lll') }</p>
        </div>
        <RsvpDetails rsvpData={rsvpData} />
    </div>
)

HistoricRsvpDetails.propTypes = {
    rsvpData: RsvpDataPropType.isRequired,
    order: PropTypes.number.isRequired,
    reverseOrder: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(HistoricRsvpDetails)
