import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectTotalImageSources, selectCurrentImageIndex } from './ducks/selectors'

const PhotographCounter = ({ totalImageSources, currentImageIndex }) => (
    <p className="font-cursive text-4xl">
        {currentImageIndex + 1} / {totalImageSources}
    </p>
)

PhotographCounter.propTypes = {
    totalImageSources: PropTypes.number.isRequired,
    currentImageIndex: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    totalImageSources: selectTotalImageSources(),
    currentImageIndex: selectCurrentImageIndex(state)
})

export default connect(mapStateToProps)(PhotographCounter)
