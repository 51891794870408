import { v4 as uuidv4 } from 'uuid'

import {
    FETCH_COMMENTS_DID_SUCCEED,
    FETCH_COMMENTS_DID_FAIL,
    UPDATE_COMMENT_TEXT,
    UPDATE_COMMENTER_NAME,
    UPDATE_CURRENT_IMAGE_INDEX,
    SUBMIT_COMMENT_DID_SUCCEED,
    SUBMIT_COMMENT_DID_FAIL,
    SUBMIT_COMMENT_DID_START,
    SUBMIT_COMMENT_DID_END,
    SUBMIT_COMMENT_DID_ERROR,
    DELETE_COMMENT,
    DELETE_COMMENT_DID_SUCCEED,
    DELETE_COMMENT_DID_FAIL,
    DELETE_COMMENT_DID_START,
    DELETE_COMMENT_DID_END,
    DELETE_COMMENT_DID_ERROR,
    CANCEL_DELETE_COMMENT
} from './actions'

import imageSources from '../../on_the_day_projection/ducks/image_sources'

// const totalImageSources = imageSources.length

// get reference to image file
const getRefIdFromQuery = () => {
    const refId = window.location.search.replace(/\?ref_id=(\w+)/g, '$1')
    return refId.match(/^KOU_\d+\.jpg$/)
        ? refId
        : imageSources[0].refId // use first image if none provided
}

const createBlankUserComment = () => ({
    text: '',
    commenterName: localStorage.getItem('commenterName'),
    commenterOnceOffId: getCommenterOnceOffId()
})

const createUserComment = ({ text, commenterName, refId }) => {
    return {
        ...createBlankUserComment(),
        text: text || '',
        commenterName: commenterName || '',
    }
}

const getCommenterOnceOffId = () => {
    let id = localStorage && localStorage.getItem('commenterOnceOffId')

    if (!id) {
        id = uuidv4()
        localStorage && localStorage.setItem('commenterOnceOffId', id)
    }

    return id
}

// get index from query if possible, else set as first
const initialImageIndex = (() => {
    const i = imageSources.findIndex(img =>
        img.refId === getRefIdFromQuery())

    return i > 0 ? i : 0
})()

const getInitialDeleteStates = () => ({
    deleteComment: {},
    deleteCommentErrors: {},
    deleteCommentUncaughtError: null
})

const initialState = {
    currentImageIndex: initialImageIndex,
    commenterOnceOffId: getCommenterOnceOffId(),
    newComment: createBlankUserComment(),
    refId: getRefIdFromQuery(),
    comments: {},
    submitCommentErrors: {}, // server provided errors
    submitCommentUncaughtError: null, // uncaught error
    // submit comment states
    commentIsSubmitting: false, // flag whether in process of submitting comment
    commentIsSubmitted: false,
    // delete comment states
    commentIsDeleting: false,
    showDeleteCommentConfirmation: false,
    ...getInitialDeleteStates()
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case CANCEL_DELETE_COMMENT: {
            return {
                ...state,
                showDeleteCommentConfirmation: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT: {
            const { comment } = action.payload

            return {
                ...state,
                ...getInitialDeleteStates(),
                deleteComment: comment,
                showDeleteCommentConfirmation: true
            }
        }
        case DELETE_COMMENT_DID_START: {
            return {
                ...state,
                commentIsDeleting: true,
                ...getInitialDeleteStates(),
                deleteComment: state.deleteComment
            }
        }
        case DELETE_COMMENT_DID_END: {
            return {
                ...state,
                commentIsDeleting: false
            }
        }
        case DELETE_COMMENT_DID_ERROR: {
            return {
                ...state,
                deleteCommentUncaughtError: action.payload.error
            }
        }
        case DELETE_COMMENT_DID_SUCCEED: {
            const { comment } = action.payload

            return {
                ...state,
                comments: {
                    ...state.comments,
                    [comment.refId]: state.comments[comment.refId].filter(c =>
                        c.id !== comment.id
                    )
                },
                showDeleteCommentConfirmation: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT_DID_FAIL: {
            return {
                ...state,
                deleteCommentErrors: action.payload.errors
            }
        }
        case SUBMIT_COMMENT_DID_START: {
            return {
                ...state,
                commentIsSubmitting: true,
                commentIsSubmitted: false
            }
        }
        case SUBMIT_COMMENT_DID_END: {
            return {
                ...state,
                commentIsSubmitting: false
            }
        }
        case SUBMIT_COMMENT_DID_ERROR: {
            return {
                ...state,
                submitCommentUncaughtError: action.payload.error
            }
        }
        case SUBMIT_COMMENT_DID_SUCCEED: {
            const { refId, comment } = action.payload

            return {
                ...state,
                comments: {
                    ...state.comments,
                    [refId]: [
                        comment,
                        ...(state.comments[refId] || [])
                    ]
                },
                newComment: createBlankUserComment(),
                commentIsSubmitted: true,
                submitCommentErrors: {},
                submitCommentUncaughtError: null
            }
        }
        case SUBMIT_COMMENT_DID_FAIL: {
            const { errors } = action.payload

            return {
                ...state,
                submitCommentErrors: errors
            }
        }
        case UPDATE_CURRENT_IMAGE_INDEX: {
            const { imageIndex, refId } = action.payload

            return {
                ...state,
                currentImageIndex: imageIndex,
                refId,
                submitCommentErrors: {},
                submitCommentUncaughtError: null,
                commentIsSubmitted: false
            }
        }
        case UPDATE_COMMENTER_NAME: {
            const { commenterName } = action.payload

            if (localStorage)
                localStorage.setItem('commenterName', commenterName)

            const newComment = createUserComment({
                ...state.newComment,
                commenterName
            })

            return { ...state, newComment }
        }
        case UPDATE_COMMENT_TEXT: {
            const { text } = action.payload

            const newComment = createUserComment({
                ...state.newComment,
                text
            })

            return { ...state, newComment }
        }
        case FETCH_COMMENTS_DID_SUCCEED: {
            const { refId, comments } = action.payload

            const nextComments = refId
                ? { ...state.comments, [refId]: comments }
                : comments.reduce((obj, c) => ({
                    ...obj,
                    [c.refId]: [
                        ...(obj[c.refId] || []),
                        c
                    ]
                }), {})

            return {
                ...state,
                comments: nextComments
            }
        }
        case FETCH_COMMENTS_DID_FAIL: {
            return {
                ...state
            }
        }
        default:
            return state
    }
}

export default reducer
