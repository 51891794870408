import Translations from '../../translations'

import {
    UPDATE_PREFERRED_LANGUAGE
} from './actions'

const initialState = {
    currentLanguageCode: Translations.getPreferredLanguage()
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_PREFERRED_LANGUAGE: {
            return {
                ...state,
                currentLanguageCode: action.payload
            }
        }
        default:
            return state
    }
}

export default reducer
