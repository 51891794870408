import config from '../../config'
import { createAction } from '@reduxjs/toolkit'
import * as RouterActions from '../../router/ducks/actions'

const namespace = 'posts'

export const redirectToRsvpPage = RouterActions.redirectToRsvpPage
export const redirectToWeddingPhotographsPage = RouterActions.redirectToWeddingPhotographsPage

export const CANCEL_RESPOND_TO_SURVEY = `${namespace}/CANCEL_RESPOND_TO_SURVEY`
export const RESPOND_TO_SURVEY = `${namespace}/RESPOND_TO_SURVEY`
export const RESPOND_TO_SURVEY_DID_START = `${namespace}/RESPOND_TO_SURVEY_DID_START`
export const RESPOND_TO_SURVEY_DID_END = `${namespace}/RESPOND_TO_SURVEY_DID_END`
export const RESPOND_TO_SURVEY_DID_SUCCEED = `${namespace}/RESPOND_TO_SURVEY_DID_SUCCEED`
export const RESPOND_TO_SURVEY_DID_FAIL = `${namespace}/RESPOND_TO_SURVEY_DID_FAIL`
// export const RESPOND_TO_SURVEY_DID_ERROR = `${namespace}/RESPOND_TO_SURVEY_DID_ERROR`

export const FETCH_SURVEY_RESPONSES_DID_SUCCEED = `${namespace}/FETCH_SURVEY_RESPONSES_DID_SUCCEED`
export const FETCH_SURVEY_RESPONSES_DID_FAIL = `${namespace}/FETCH_SURVEY_RESPONSES_DID_FAIL`

export const fetchSurveyResponses = () => (dispatch, getState) => {
    fetch(`${config.API_BASE_URL}/survey_responses`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: FETCH_SURVEY_RESPONSES_DID_SUCCEED,
                payload: {
                    eddySurveyResponses: json.data.eddySurveyResponses,
                    ritaSurveyResponses: json.data.ritaSurveyResponses
                }
            })
        } else {
            dispatch({
                type: FETCH_SURVEY_RESPONSES_DID_FAIL,
                payload: {
                    errors: json.data.errors
                }
            })
        }
    })
}

export const cancelSurvey = createAction(
    CANCEL_RESPOND_TO_SURVEY
)

export const showSurvey = createAction(
    RESPOND_TO_SURVEY,
    (targetSurvey) => ({
        payload: { targetSurvey }
    })
)

export const respondToSurvey = (targetSurvey, response) => (dispatch, getState) => {
    switch(targetSurvey) {
        case 'eddySurvey': {
            dispatch(respondToEddySurvey(response))
            break
        }
        case 'ritaSurvey': {
            dispatch(respondToRitaSurvey(response))
            break
        }
        default:
            break
    }
}

export const postSurveyResponse = (body) => (dispatch, getState) => {
    const { invitationCode } = getState().invitationScreening

    dispatch({
        type: RESPOND_TO_SURVEY_DID_START
    })

    fetch(`${config.API_BASE_URL}/survey_responses`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ...body,
            invitationCode
        })
    })
    .then(res => res.json())
    .then(json => {
        if (json.status === 0) {
            dispatch({
                type: RESPOND_TO_SURVEY_DID_SUCCEED,
                payload: {
                    targetSurvey: body.survey.targetSurvey,
                    response: json.data.response
                }
            })
        } else {
            dispatch({
                type: RESPOND_TO_SURVEY_DID_FAIL,
                payload: {
                    targetSurvey: body.survey.targetSurvey,
                    errors: json.data.errors
                }
            })
        }
    })
    .catch(err => {
        console.error(err)
    })
    .finally(() => {
        dispatch({
            type: RESPOND_TO_SURVEY_DID_END
        })
    })
}

export const respondToEddySurvey = (response) => (dispatch, getState) => {
    dispatch(postSurveyResponse({
        survey: {
            targetSurvey: 'eddy_survey',
            isHumble: response,
        }
    }))
}

export const respondToRitaSurvey = (response) => (dispatch, getState) => {
    dispatch(postSurveyResponse({
        survey: {
            targetSurvey: 'rita_survey',
            isGentle: response,
        }
    }))
}
