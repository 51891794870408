import PropTypes from 'prop-types'
import LanguageButton from './LanguageButton'

const LanguageToggle = ({ light }) => (
    <div className="flex">
        <LanguageButton language="English" languageCode="en" light={light} />
        <LanguageButton language="中文" languageCode="zh" light={light} />
    </div>
)

LanguageToggle.propTypes = {
    light: PropTypes.bool
}

export default LanguageToggle
