import { createSelector } from 'reselect'
import mediaSources from './media_sources'
import * as InvitationScreeningSelectors from '../../invitation_screening/ducks/selectors'

export const selectIsAdmin = InvitationScreeningSelectors.selectIsAdmin
export const selectInvitationCodeId = InvitationScreeningSelectors.selectInvitationCodeId

export const getCurrentMediaIndex = (state) => state.weddingPhotographs.currentMediaIndex
export const selectCurrentMediaIndex = getCurrentMediaIndex

export const getCurrentMedia = (state) => {
    const currentMediaIndex = getCurrentMediaIndex(state)
    return mediaSources[currentMediaIndex]
}
export const selectCurrentMedia = getCurrentMedia

export const getLeftArrowVisibility = (state) => {
    const currentMediaIndex = getCurrentMediaIndex(state)
    return currentMediaIndex !== 0
}

export const getRightArrowVisibility = (state) => {
    const currentMediaIndex = getCurrentMediaIndex(state)
    return currentMediaIndex < (mediaSources.length - 1)
}

export const getTotalMediaItems = (state) => {
    return mediaSources.length
}

export const getModalVisibility = (state) => state.weddingPhotographs.modalVisible

export const selectComments = (state) => state.weddingPhotographs.commentsDataset

// currently showing all photos, but may be edited
// to show less
export const selectGalleryThumbnails = (state) => {
    const currentMediaIndex = getCurrentMediaIndex(state)
    const currentMedia = getCurrentMedia(state)
    const left = mediaSources.slice(0, currentMediaIndex)
    const right = mediaSources.slice(currentMediaIndex + 1)

    const leftThreshold = 7
    const rightThreshold = 8

    if (right.length < rightThreshold) {
        const numOfLeftToFill = rightThreshold - right.length + leftThreshold
        const fillLeft = left.slice(-numOfLeftToFill)
        return [...fillLeft, currentMedia, ...right]
    } else {
        let fillLeft = []

        if (left.length > 0) {
            fillLeft = left.slice(-leftThreshold)
        }

        const fillRight = fillLeft.length < leftThreshold
            ? right.slice(0, rightThreshold + leftThreshold - fillLeft.length)
            : right.slice(0, rightThreshold)

        return [
            ...fillLeft,
            currentMedia,
            ...fillRight
        ]
    }
}

export const selectDeleteCommentConfirmationVisible = createSelector(
    state => state.weddingPhotographs.deleteCommentConfirmationVisible,
    deleteCommentConfirmationVisible => deleteCommentConfirmationVisible
)

export const selectDeleteCommentErrors = createSelector(
    state => state.weddingPhotographs.deleteCommentErrors,
    deleteCommentErrors => deleteCommentErrors
)

export const selectDeleteComment = createSelector(
    state => state.weddingPhotographs.deleteComment,
    deleteComment => deleteComment
)

export const selectCommentIsDeleting = createSelector(
    state => state.weddingPhotographs.commentIsDeleting,
    isDeleting => isDeleting
)

export const selectDeleteCommentUncaughtError = createSelector(
    state => state.weddingPhotographs.deleteCommentUncaughtError,
    error => error
)
