import { connect } from 'react-redux'
import m from 'moment'
import PropTypes from 'prop-types'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import RsvpDetails from './RsvpDetails'
import EditAttendeeButton from './EditAttendeeButton'

const CurrentRsvpDetails = ({ rsvpData }) => (
    <div className="mb-12">
        <div className="mb-4 w-40">
            <EditAttendeeButton />
        </div>
        <div className="pl-2 border-l-2 border-secondary mb-6">
            <h2 className="text-2xl text-secondary">
                { Translations.get('attendees_page.rsvp_history.current.header') }
            </h2>
            <p>{ m(rsvpData.updatedAt).format('lll') }</p>
        </div>
        <RsvpDetails rsvpData={rsvpData} />
    </div>
)

CurrentRsvpDetails.propTypes = {
    rsvpData: PropTypes.shape({
        data: PropTypes.object.isRequired
    }).isRequired
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(CurrentRsvpDetails)
