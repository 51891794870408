import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectFormErrors } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const RsvpFormErrorMessages = ({ formErrors, errorKey }) => {
    // note translation keys are in snake case
    const errorKeySnakeCase = errorKey.replace(/[A-Z]/g,
        letter => `_${letter.toLowerCase()}`)

    return (
        formErrors && formErrors[errorKey]
            ?
            <div className="text-red-400">
                { formErrors[errorKey].map((errorDetail, i) =>
                    <p key={i}>{ Translations.get(
                        `rsvp_form.errors.${errorKeySnakeCase}.${errorDetail.error}`) }
                    </p>)
                }
            </div>
            : null
    )
}

RsvpFormErrorMessages.propTypes = {
    formErrors: PropTypes.object.isRequired,
    errorKey: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    formErrors: selectFormErrors(state),
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(RsvpFormErrorMessages)
