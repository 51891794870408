import POST_1 from './Post_1'
import POST_2 from './Post_2'
import POST_3 from './Post_3'
import POST_4 from './Post_4'

const Posts = () => (
    <div className="pb-4 lg:pt-2 lg:px-2 m-auto">
        <POST_1 />
        <hr className="my-8 border-secondary" />
        <POST_2 />
        <hr className="my-8 border-secondary" />
        <POST_3 />
        <hr className="my-8 border-secondary" />
        <POST_4 />
    </div>
)

export default Posts
