import _ from 'lodash'
import {
    FETCH_ATTENDEES_DID_SUCCEED,
    FETCH_ATTENDEES_DID_FAIL,
    FETCH_ATTENDEES_DID_ERROR,
    FETCH_RSVP_HISTORY_DID_SUCCEED,
    CLOSE_RSVP_HISTORY,
    FILTER_ABSENT_GUESTS,
    REMOVE_FILTER_ABSENT_GUESTS,
    EDIT_ATTENDEE,
    END_EDIT_ATTENDEE
} from './actions'
// import factory from '../../factory'

//const attendees = factory.attendees.generateSamples()
//const contacts = factory.contacts.generateSamples()

const initialState = {
    attendeesDataArray: [],
    contactsDataset: {},
    fetchAttendeesErrors: {},
    rsvpHistoryVisible: false,
    absentGuestsVisible: true,
    editRsvpFormVisible: false
}

const buildAttendeesList = (weddingGuest, includeMainGuest = false) => {
    const additionalGuests = weddingGuest.additionalGuests.map(a => ({
        ...a,
        contactId: weddingGuest.id
    }) )

    if (includeMainGuest) {
        return weddingGuest.isAttending
            ?
            [
                {
                    id: weddingGuest.id,
                    name: weddingGuest.name,
                    contactId: weddingGuest.id,
                    childSeatRequired: false
                },
                 ...additionalGuests
            ]
            : additionalGuests
    } else {
        return additionalGuests
    }
}

const buildWeddingGuests = (weddingGuests) => {
    return weddingGuests.map(g =>
        ({ ...g, additionalGuests: buildAttendeesList(g) })
    )
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case EDIT_ATTENDEE: {
            return {
                ...state,
                editRsvpFormVisible: true
            }
        }
        case END_EDIT_ATTENDEE: {
            return {
                ...state,
                editRsvpFormVisible: false
            }
        }
        case FILTER_ABSENT_GUESTS: {
            return {
                ...state,
                absentGuestsVisible: false
            }
        }
        case REMOVE_FILTER_ABSENT_GUESTS: {
            return {
                ...state,
                absentGuestsVisible: true
            }
        }
        case FETCH_ATTENDEES_DID_SUCCEED: {
            return {
                ...state,
                weddingGuests: buildWeddingGuests(action.payload.weddingGuests),
                attendeesDataArray: action.payload.weddingGuests
                    .map(wg => buildAttendeesList(wg, true))
                    .flat(),
                contactsDataset: action.payload.weddingGuests
                    .map(wg => _.pickBy(wg, (v, k) => k !== 'additionalGuests'))
                    .reduce((obj, wg) => ({
                        ...obj,
                        [wg.id]: wg
                    }), {}),
                fetchAttendeesErrors: {}
            }
        }
        case FETCH_ATTENDEES_DID_ERROR:
        case FETCH_ATTENDEES_DID_FAIL: {
            return {
                ...state,
                fetchAttendeesErrors: action.payload.errors
            }
        }
        case FETCH_RSVP_HISTORY_DID_SUCCEED: {
            return {
                ...state,
                rsvpHistoryVisible: true,
                rsvpHistories: action.payload.rsvpHistories
            }
        }
        case CLOSE_RSVP_HISTORY: {
            return {
                ...state,
                rsvpHistoryVisible: false
            }
        }
        default:
            return state
    }
}

export default reducer
