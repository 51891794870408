import PropTypes from 'prop-types'
import { useEffect } from 'react'
import Gallery from './Gallery'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import Modal from './Modal'
import HomeButton from '../router/HomeButton'
import { connect } from 'react-redux'
import { getCurrentMedia } from './ducks/selectors'
import Pagination from './Pagination'
import GalleryThumbnails from './GalleryThumbnails'
import { fetchComments } from './ducks/actions'
import store from '../redux/store'

// run initial comments fetching
let commentsFetchedAt = null
const fetchInterval = 10000 // 10 seconds

const WeddingPhotographsPage = ({ currentMedia }) => {
    useEffect(() => {
        document.title = 'Gallery | Rita & Eddy 2022'

        const now = Date.now()

        if (!commentsFetchedAt || (now - commentsFetchedAt) > fetchInterval) {
            store.dispatch(fetchComments())
            commentsFetchedAt = now
        }
    })

    return (
        <div className="bg-texture-[asfalt-light] bg-primary min-h-[100vh]">
            <div className="absolute z-10 top-0 left-0 bottom-0 right-0"
                style={{
                    backgroundImage: `url(${currentMedia.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.15
                }}/>
            <div className="relative z-20">
                <div className="pt-4 pl-4">
                    <HomeButton />
                </div>
                <div className="my-6">
                    <h1 className="text-center text-6xl font-cursive text-secondary select-none">
                        Rita & Eddy
                    </h1>
                </div>
                <div>
                    <div className="w-full">
                        <div className="lg:flex justify-between">
                            <div className="hidden lg:block lg:pl-12">
                                <LeftArrow />
                            </div>
                            <div>
                                <div className="mb-3">
                                    <Gallery />
                                </div>
                                <div className="mb-2">
                                    <GalleryThumbnails />
                                </div>
                                <div className="py-2 text-center bg-neutral-800/50">
                                    <Pagination />
                                </div>
                            </div>
                            <div className="hidden lg:block lg:pr-12">
                                <RightArrow />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal />
        </div>
    )
}

WeddingPhotographsPage.propTypes = {
    currentMedia: PropTypes.shape({
        url: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => ({
    currentMedia: getCurrentMedia(state)
})

export default connect(mapStateToProps)(WeddingPhotographsPage)
