import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updatePreferredLanguage } from './ducks/actions'
import { selectCurrentLanguageCode } from './ducks/selectors'

const LanguageButton = ({ light, language, languageCode, currentLanguageCode, onChange }) => {
    const handleChange = (e) => {
        e.preventDefault()
        onChange(languageCode)
    }

    const isCurrentLanguage = currentLanguageCode === languageCode
    const activeTextColor = 'text-white'
    const inactiveTextColor = light ? 'text-white' : 'text-primary'

    return (
        <button className={`${light ? '' : 'bg-secondary/90'} text-white text-center text-xs
            inline-block px-4 py-2 cursor-pointer
            border-l first:border-none`}
            onClick={ handleChange }
            disabled={ isCurrentLanguage }>
            <span className={ isCurrentLanguage ? activeTextColor : inactiveTextColor }>
                {language}
            </span>
            {
                isCurrentLanguage &&
                    <div className="mt-1 border-b border-white" />
            }
        </button>
    )
}

LanguageButton.propTypes = {
    language: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
    currentLanguageCode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    light: PropTypes.bool
}

const mapStateToProps = (state) => ({
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onChange: (code) => dispatch(updatePreferredLanguage(code)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguageButton)
