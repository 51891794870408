import {
    INCREMENT_MEDIA_INDEX,
    DECREMENT_MEDIA_INDEX,
    UPDATE_MEDIA_INDEX,
    SHOW_ORIGINAL_MEDIA,
    CLOSE_ORIGINAL_MEDIA,
    UPDATE_COMMENT,
    // SUBMIT_COMMENT_DID_START,
    // SUBMIT_COMMENT_DID_END,
    SUBMIT_COMMENT_DID_ERROR,
    SUBMIT_COMMENT_DID_SUCCEED,
    SUBMIT_COMMENT_DID_FAIL,
    FETCH_COMMENTS_DID_SUCCEED,
    UPDATE_MEDIA_INDEX_BY_ITEM,
    DELETE_COMMENT,
    CANCEL_DELETE_COMMENT,
    DELETE_COMMENT_DID_SUCCEED,
    DELETE_COMMENT_DID_FAIL,
    DELETE_COMMENT_DID_START,
    DELETE_COMMENT_DID_END,
    DELETE_COMMENT_DID_ERROR
} from './actions'

import mediaSources from './media_sources'

const getInitialDeleteStates = () => ({
    deleteComment: {},
    deleteCommentErrors: {},
    deleteCommentUncaughtError: null
})

const initialState = {
    currentMediaIndex: 0,
    modalVisible: false,
    newComment: {},
    commentsDataset: {},
    newCommentErrors: {},
    deleteCommentConfirmationVisible: false,
    commentIsDeleting: false,
    ...getInitialDeleteStates()
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case CANCEL_DELETE_COMMENT: {
            return {
                ...state,
                deleteCommentConfirmationVisible: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT: {
            return {
                ...state,
                deleteComment: action.payload.deleteComment,
                deleteCommentConfirmationVisible: true
            }
        }
        case DELETE_COMMENT_DID_START: {
            return {
                ...state,
                commentIsDeleting: true,
                ...getInitialDeleteStates(),
                deleteComment: state.deleteComment
            }
        }
        case DELETE_COMMENT_DID_END: {
            return {
                ...state,
                commentIsDeleting: false
            }
        }
        case DELETE_COMMENT_DID_ERROR: {
            return {
                ...state,
                deleteCommentUncaughtError: action.payload.error
            }
        }
        case DELETE_COMMENT_DID_SUCCEED: {
            const { deleteComment, commentsDataset } = state
            const { refId } = deleteComment
            const groupedComments = commentsDataset[refId]

            return {
                ...state,
                commentsDataset: {
                    ...commentsDataset,
                    [refId]: groupedComments.filter(c =>
                        c.id !== deleteComment.id)
                },
                deleteCommentConfirmationVisible: false,
                ...getInitialDeleteStates()
            }
        }
        case DELETE_COMMENT_DID_FAIL: {
            return {
                ...state,
                deleteCommentErrors: action.payload.errors
            }
        }
        case INCREMENT_MEDIA_INDEX: {
            return {
                ...state,
                currentMediaIndex: Math.min(
                    state.currentMediaIndex + 1,
                    mediaSources.length - 1
                )
            }
        }
        case DECREMENT_MEDIA_INDEX: {
            return {
                ...state,
                currentMediaIndex: Math.max(
                    state.currentMediaIndex - 1,
                    0
                )
            }
        }
        case UPDATE_MEDIA_INDEX: {
            return {
                ...state,
                currentMediaIndex: action.payload.index
            }
        }
        case UPDATE_MEDIA_INDEX_BY_ITEM: {
            return {
                ...state,
                currentMediaIndex: mediaSources.findIndex(m =>
                    m.refId === action.payload.mediaItem.refId
                )
            }
        }
        case SHOW_ORIGINAL_MEDIA: {
            return {
                ...state,
                modalVisible: true
            }
        }
        case CLOSE_ORIGINAL_MEDIA: {
            return {
                ...state,
                modalVisible: false
            }
        }
        case UPDATE_COMMENT: {
            const now = Date.now()
            const { refId, text } = action.payload

            return {
                ...state,
                newComment: {
                    ...state.newComment,
                    id: state.newComment.id || now,
                    refId,
                    text,
                }
            }
        }
        case SUBMIT_COMMENT_DID_SUCCEED: {
            const { comment } = action.payload
            const { refId } = comment
            return {
                ...state,
                commentsDataset: {
                    ...state.commentsDataset,
                    [refId]: [
                        comment,
                        ...(state.commentsDataset[refId] || [])
                    ]
                },
                newComment: {}
            }
        }
        case FETCH_COMMENTS_DID_SUCCEED: {
            const { refId, comments } = action.payload

            if (refId) {
                return {
                    ...state,
                    commentsDataset: {
                        ...state.commentsDataset,
                        [refId]: comments
                    }
                }
            } else {
                return {
                    ...state,
                    commentsDataset: comments.reduce((obj, c) => ({
                        ...obj,
                        [c.refId]: [...(obj[c.refId] || []), c]
                    }), {})
                }
            }
        }
        case SUBMIT_COMMENT_DID_FAIL: {
            return {
                ...state,
                newCommentErrors: action.payload.errors
            }
        }
        case SUBMIT_COMMENT_DID_ERROR: {
            return {
                ...state,
                newCommentErrors: [action.payload.error]
            }
        }
        default:
            return state
    }
}

export default reducer
