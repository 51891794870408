import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { validateInvitationCode, getExtendedSession } from './ducks/actions'
import { selectInvitationCodeErrors } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import { selectSearchParams } from '../router/ducks/selectors'

const InvitationPage = ({ onCodeChange, errors }) => {
    let [fetchSessionOnce, setFetchSessionOnce] = useState(false)
    let [readCodeOnce, setReadCodeOnce] = useState(false)

    if (!fetchSessionOnce) {
        setFetchSessionOnce(true)
        getExtendedSession()
    }

    // initial code
    if (!readCodeOnce) {
        setReadCodeOnce(true)
        const code = new selectSearchParams().get('code')
        if (code) onCodeChange(code)
    }

    useEffect(() => {
        document.title = 'Rita & Eddy 2022'
    })

    const handleCodeChange = (e) => {
        onCodeChange(e.target.value)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13)
            onCodeChange(e.target.value)
    }

    return (
        <div className="invitation min-h-[100vh]">
            <h1 className="p-10 pt-20 leading-none md:leading-normal text-center text-secondary text-[100px] font-cursive">
                We're getting married!
            </h1>
            <div className="p-10 relative">
                <div className="absolute z-10 top-0 left-0 w-full h-full flex justify-center select-none">
                    <img className="opacity-5 pointer-events-none w-96 h-96"
                        alt="logo" src="/logo-light.svg" draggable={false} />
                </div>
                <div className="relative z-20 text-xl text-secondary m-auto mb-10 md:w-[400px]">
                    <p>
                        <span>{ Translations.get('invitation_page.opening_text.dear') } </span>
                        <span className="font-sans">Eddy &amp; Rita </span>
                        <span>{ Translations.get('invitation_page.opening_text.guests') }</span>
                    </p>
                    <p className="mt-6">{ Translations.get('invitation_page.opening_text.text.line_1') }</p>
                    <p>{ Translations.get('invitation_page.opening_text.text.line_2') }</p>
                </div>
                <div className="relative z-20 m-auto md:w-[400px]">
                    <input className="text-primary w-full text-center py-2 border-2 valid:border-green-400 invalid:border-red-400 focus:ring-0"
                        placeholder={ Translations.get('invitation_page.form.code') }
                        onChange={ handleCodeChange }
                        onKeyUp={ handleKeyUp }
                        pattern="^[1-9A-Za-z]+$"
                        maxLength={16}
                        required={true} />
                    <div className="mt-3 text-red-400">
                        { errors && errors.code && errors.code.map((shortCode, i) =>
                            <p key={i}>{ Translations.get(
                                `invitation_page.errors.code.${shortCode}`) }</p>
                        )}
                    </div>
                    <p className="relative mt-8 text-secondary text-center pb-1 border-b-2 border-secondary">
                        <span className="inline-block indent-1">{ Translations.get('invitation_page.reminders.invitation_code_location') }</span>
                        <span className="absolute -top-1 -left-1 block w-4 h-4 rounded-full bg-secondary animate-ping" />
                        <span className="absolute -top-1 -left-1 block w-4 h-4 rounded-full bg-secondary" />
                    </p>
                </div>
            </div>
            <div className="mt-28 pb-10 md:w-[370px] m-auto">
                <p className="text-sm text-white text-center">
                    * { Translations.get('invitation_page.reminders.personal_privacy') }
                </p>
            </div>
        </div>
    )
}

InvitationPage.propTypes = {
    onCodeChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    errors: selectInvitationCodeErrors(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onCodeChange: (code) => dispatch(validateInvitationCode(code))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvitationPage)
