import PropTypes from 'prop-types'

const RsvpConfirmationDetail = ({ label, value, children }) => (
    <div className="mt-4">
        <span className="block md:inline-block md:text-right md:w-[180px] md:pr-2 md:border-r-2 border-secondary">
            { label }
        </span>
        {
            children
                ? children
                :
                <span className="block md:inline-block md:pl-2 text-white">
                    { value }
                </span>
        }
    </div>
)

RsvpConfirmationDetail.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
}

export default RsvpConfirmationDetail
