import { createSelector } from 'reselect'
import * as RsvpSelectors from '../../rsvp/ducks/selectors'

export const selectHasRepliedRsvp = RsvpSelectors.selectHasRepliedRsvp

export const selectSurveyIsProcessing = createSelector(
    state => state.posts.surveyIsProcessing,
    isProcessing => isProcessing
)

export const selectEddySurveyVisible = createSelector(
    state => state.posts.eddySurveyVisible,
    visible => visible
)

export const selectRitaSurveyVisible = createSelector(
    state => state.posts.ritaSurveyVisible,
    visible => visible
)

export const selectSurveyErrors = createSelector(
    state => state.posts.surveyErrors,
    errors => errors
)

export const selectEddySurveyResponses = createSelector(
    state => state.posts.eddySurveyResponses,
    responses => responses
)

export const selectRitaSurveyResponses = createSelector(
    state => state.posts.ritaSurveyResponses,
    responses => responses
)

export const selectEddySurveyPositiveResponses = createSelector(
    selectEddySurveyResponses,
    responses => Object.values(responses).filter(r => r.isHumble)
)

export const selectEddySurveyNegativeResponses = createSelector(
    selectEddySurveyResponses,
    responses => Object.values(responses).filter(r => !r.isHumble)
)

export const selectRitaSurveyPositiveResponses = createSelector(
    selectRitaSurveyResponses,
    responses => Object.values(responses).filter(r => r.isGentle)
)

export const selectRitaSurveyNegativeResponses = createSelector(
    selectRitaSurveyResponses,
    responses => Object.values(responses).filter(r => !r.isGentle)
)

export const selectUserResponseToEddySurvey = createSelector(
    selectEddySurveyResponses,
    state => state.invitationScreening.invitationCodeId,
    (responses, invitationCodeId) => responses[invitationCodeId]
)

export const selectUserResponseToRitaSurvey = createSelector(
    selectRitaSurveyResponses,
    state => state.invitationScreening.invitationCodeId,
    (responses, invitationCodeId) => responses[invitationCodeId]
)
