import { createSelector } from 'reselect'

export const selectAttendees = createSelector(
    state => state.rsvp.attendees,
    attendees => attendees
)

export const selectEditWeddingGuest = createSelector(
    state => state.rsvp.editWeddingGuest,
    editWeddingGuest => editWeddingGuest
)

export const selectFormErrors = createSelector(
    state => state.rsvp.formErrors,
    formErrors => formErrors
)

// refers to a confirmed rsvp without further modifications,
export const selectHasRepliedRsvp = createSelector(
    state => state.rsvp.editWeddingGuest,
    // avoid undefined return value warning
    weddingGuest => (weddingGuest.id && !weddingGuest.canEditRsvp) || false
)

export const selectRsvpConfirmationVisibility = createSelector(
    state => state.rsvp.showRsvpConfirmation,
    visibility => visibility
)

export const selectAttendeesCount = createSelector(
    state => state.rsvp.attendees,
    state => state.rsvp.editWeddingGuest,
    (attendees, weddingGuest) => {
        let count = 0

        const attendeesCount = (attendees || [])
            .filter(a => a.name).length

        count += attendeesCount

        if (weddingGuest.isAttending)
            count += 1

        return count
    }
)

export const selectRsvpSubmitConfirmationVisible = createSelector(
    state => state.rsvp.rsvpSubmitConfirmationVisible,
    visible => visible
)

export const selectRsvpIsSubmitting = createSelector(
    state => state.rsvp.isSubmitting,
    isSubmitting => isSubmitting
)
