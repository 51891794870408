import configOverrides from './config.env'

const host = window.location.origin

const config = {
    DEBUG_MODE: false,
    LOAD_GOOGLE_MAP: true,
    API_BASE_URL: `${host}/api`,
    // TEST_INVITATION_CODE: '',
    // IS_ADMIN: false,
    // INITIAL_PAGE: '',
    PROJECT_HIGH_RESOLUTION_IMAGES: true,
    ...configOverrides
}

export default config
