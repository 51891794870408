import PropTypes from 'prop-types'
import Translations from '../translations'

const AddAttendeeButton = ({ onClick }) => (
    <div className="group inline">
        <button type="button"
            onClick={ onClick }
            className="border border-secondary text-secondary group-hover:text-white group-hover:bg-secondary">
                <span className="block py-2 px-4">+ { Translations.get('rsvp_form.guests.add') }</span>
                <span className="w-0 group-hover:w-full transition-[width] duration-300 ease-in-out block border-b border-white"></span>
        </button>
    </div>
)

AddAttendeeButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default AddAttendeeButton
