import PropTypes from 'prop-types'

const PlayImage = ({ onClick }) => (
    <button type="button"
        className="text-5xl rounded text-white py-4 px-8 border-2 border-white/50 hover:bg-white hover:text-black"
        onClick={ onClick }>
        <span>▶</span>
    </button>
)

PlayImage.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default PlayImage
