import { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectCurrentAudio, selectAudioIsPaused, selectAudioIsMuted
    } from './ducks/selectors'
import { getNextAudio, pauseAudio, playAudio, muteAudio, unmuteAudio,
    fetchAudio, fetchAudioDone } from './ducks/actions'
import PlayImage from './PlayImage'
import { setAudioNode as setVisualiserAudioNode,
    unsetAudioNode as unsetVisualiserAudioNode
} from './ducks/audio_visualiser'

// set limit 0 for unlimited playback time per track
const LIMIT_PER_AUDIO_PLAYBACK_TIME_IN_MS = 0

const ProjectedAudio = ({ currentAudio, onEnded, isPaused, onPlay, onPause,
    isMuted, onMute, onUnmute, onFetchAudio, onFetchAudioDone }) => {
    let audioNode
    let overlayNode
    let audioLimitTimeout = null

    const handleAudioEnded = (e) => {
        clearAudioLimiter()
        onEnded()
    }

    const checkAudioState = () => {
        if (!audioNode) return

        if (audioNode.readyState < 4) {
            onFetchAudio()
        } else {
            onFetchAudioDone()
        }
    }

    const setAudioNode = (el) => {
        audioNode = el
        setVisualiserAudioNode(audioNode)

        // attempt autoplay
        // if (audioNode && !isPaused) play()
        // if (audioNode && isMuted) mute()
    }

    const setOverlayNode = (el) => overlayNode = el

    const clearAudioLimiter = () => {
        clearTimeout(audioLimitTimeout)
        audioLimitTimeout = null
    }

    const mute = () => {
        if (audioNode) {
            audioNode.muted = true
            onMute()
        }
    }

    const unmute = () => {
        if (audioNode) {
            audioNode.muted = false
            onUnmute()
        }
    }

    const handlePrematureEnd = () => {
        audioNode && audioNode.pause()
        onEnded()
    }

    const play = () => {
        if (audioNode) {
            // no need to play if already playing
            if (!audioNode.paused) return

            clearAudioLimiter()

            audioNode.play()
                .then(() => {
                    if (overlayNode)
                        overlayNode.style.display = 'none'

                    if (LIMIT_PER_AUDIO_PLAYBACK_TIME_IN_MS) {
                        audioLimitTimeout = setTimeout(
                            handlePrematureEnd,
                            LIMIT_PER_AUDIO_PLAYBACK_TIME_IN_MS
                        )
                    }
                })
                .then(onPlay)
                .catch(err => {
                    if (err.message.match(/user didn't interact with the document first/)) {
                        // press play button required
                        console.log('Require user to press Play')
                    } else if (err.message.match(/(has no supported sources)|(no supported source was found)/)) {
                        console.error(`Missing audio source [${currentAudio.url}]`)
                        console.log('Skipping to next audio')
                        clearAudioLimiter()
                        onEnded()
                    } else {
                        console.error(err)
                    }
                })
        }
    }

    const pause = () => {
        if (audioNode) {
            // no point pausing an already paused audio
            if (audioNode.paused) return

            audioNode.pause()

            // stop limiter
            clearAudioLimiter()
            onPause()
        }
    }

    useEffect(() => {
        isPaused ? pause() : play()

        const playToggle = (e) => {
            if (e.code === 'Space')
                audioNode.paused ? play() : pause()
        }

        const muteToggle = (e) => {
            if (e.key === 'm' || e.code === 'KeyM')
                audioNode.muted ? unmute() : mute()
        }

        window.addEventListener('keydown', playToggle)
        window.addEventListener('keydown', muteToggle)

        return () => {
            window.removeEventListener('keydown', playToggle)
            window.removeEventListener('keydown', muteToggle)
            unsetVisualiserAudioNode()
        }
    })

    return (
        <div ref={ setOverlayNode } className={`absolute top-0 left-0 right-0
            bottom-0 z-50 bg-black/80`}>
            <div className="w-full h-[100vh] flex justify-center items-center">
                <PlayImage onClick={ play } />
            </div>
            <audio ref={ setAudioNode } autoPlay={ false }
                src={ currentAudio.url } onEnded={ handleAudioEnded }
                onProgress={ checkAudioState } onPlay={ checkAudioState }>
            </audio>
        </div>
    )
}

ProjectedAudio.propTypes = {
    currentAudio: PropTypes.shape({
        url: PropTypes.string.isRequired
    }).isRequired,
    onEnded: PropTypes.func.isRequired,
    isPaused: PropTypes.bool.isRequired,
    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    isMuted: PropTypes.bool.isRequired,
    onMute: PropTypes.func.isRequired,
    onUnmute: PropTypes.func.isRequired,
    onFetchAudio: PropTypes.func.isRequired,
    onFetchAudioDone: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentAudio: selectCurrentAudio(state),
    isPaused: selectAudioIsPaused(state),
    isMuted: selectAudioIsMuted(state)
})

const mapDispatchToProps = (dispatch) => ({
    onEnded: () => dispatch(getNextAudio()),
    onPlay: () => dispatch(playAudio()),
    onPause: () => dispatch(pauseAudio()),
    onMute: () => dispatch(muteAudio()),
    onUnmute: () => dispatch(unmuteAudio()),
    onFetchAudio: () => dispatch(fetchAudio()),
    onFetchAudioDone: () => dispatch(fetchAudioDone())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectedAudio)
