/**
* Credits to https://codepen.io/bennettfeely/pen/DrNgoO
*/
import PropTypes from 'prop-types'
import BalloonsChampagne from '../assets/balloons_champagne.svg'

const AnimatedBalloon = ({ animationDelay }) => (
    <div className="w-48 h-48 animate-balloon"
        style={{
            animationDelay
        }}>
        <img alt="ballons_champagne" src={ BalloonsChampagne } />
    </div>
)

AnimatedBalloon.propTypes = {
    color: PropTypes.string,
    animationDelay: PropTypes.string
}

export default AnimatedBalloon

// const AnimatedBalloon = ({ color = 'rgb(184,153,100)', animationDelay = '0ms' }) => (
//     <div className="relative w-[120px] h-[145px] animate-balloon"
//         style={{
//             animationDelay
//         }}>
//         <div className={`absolute top-[153px] left-[90px] h-[250px]
//             w-[1px] mx-auto my-0 bg-secondary/40`} />
//
//         <div className={`text-[20px] text-center w-full absolute left-[30px]
//             top-[153px]`}
//             style={{ color }}>
//             <span>▲</span>
//         </div>
//
//         <div className="relative rounded-[80%] w-full h-full mx-[30px] my-[20px]" style={{
//             background: color,
//             boxShadow: 'inset -10px -10px 0 rgba(0,0,0,0.07)',
//             transformOrigin: 'bottom center',
//         }}>
//         </div>
//     </div>
// )

// balloonType = 'champagne'
// const balloonTypes = {
//     champagne: BalloonsChampagne
// }
// balloonTypes[balloonType]
