const ArrowDownImage = () => (
    <svg enableBackground="new 0 0 100 100"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg">
        <polygon fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth="2"
            points="23.1,34.1 51.5,61.7 80,34.1 81.5,35 51.5,64.1 21.5,35 23.1,34.1 "/>
    </svg>
)

export default ArrowDownImage
