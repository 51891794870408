import { createSelector } from 'reselect'

export const selectSearchParams = () => {
    const searchQuery = new URLSearchParams(window.location.search)
    return searchQuery
}

export const selectCurrentPage = createSelector(
    state => state.router.currentPage,
    page => page
)
