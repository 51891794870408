import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import AddToCalendarButton from './AddToCalendarButton'

const DateTimeDetails = () => (
    <div>
        <div className="group relative">
            <div className="ml-3">
                <p className="mb-4 text-4xl font-cursive select-none">Rita & Eddy</p>
                <p>{ Translations.get('home_page.event_details.date') }</p>
                <p>{ Translations.get('home_page.event_details.time') }</p>
            </div>
            <span className="h-0 group-hover:h-[95px] absolute left-0 top-0 transition-[height] duration-300 ease-in-out block border-l border-white"></span>
        </div>
        <div className="mt-4">
            <AddToCalendarButton />
        </div>
    </div>
)

const mapStateToProps = (state) => ({
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

export default connect(mapStateToProps)(DateTimeDetails)
