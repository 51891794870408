import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { filterAbsentGuests, removeFilterAbsentGuests } from './ducks/actions'
import { selectAbsentGuestsVisible } from './ducks/selectors'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'

const AbsentGuestsFilter = ({ absentGuestsVisible, onFilterAbsentGuestsClick,
    onRemoveFilterAbsentGuestsClick }) => {

    const handleChange = (e) => {
        const checked = e.target.checked

        if (checked) {
            onRemoveFilterAbsentGuestsClick()
        } else {
            onFilterAbsentGuestsClick()
        }
    }

    return (
        <div>
            <label className="mr-2 text-secondary">
                { Translations.get('attendees_page.filters.show_absent_guests') }
            </label>
            <input type="checkbox" className={`text-secondary focus:ring-offset-0 focus:ring-0
                border-secondary focus:shadow-none checked:bg-secondary cursor-pointer`}
                checked={ absentGuestsVisible }
                onChange={ handleChange } />
        </div>
    )
}

AbsentGuestsFilter.propTypes = {
    absentGuestsVisible: PropTypes.bool.isRequired,
    onFilterAbsentGuestsClick: PropTypes.func.isRequired,
    onRemoveFilterAbsentGuestsClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    absentGuestsVisible: selectAbsentGuestsVisible(state),
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onFilterAbsentGuestsClick: () => dispatch(filterAbsentGuests()),
    onRemoveFilterAbsentGuestsClick: () => dispatch(removeFilterAbsentGuests())
})

export default connect(mapStateToProps, mapDispatchToProps)(AbsentGuestsFilter)
