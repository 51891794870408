// Traditional Chinese

// for translations that depend on input values
export const dynamic = (key) => ({
    'attendees_page.table.details.total_attending': (n) => `來賓 ${n} 位出席`,
    'attendees_page.table.details.total_child_seats': (n) => `其中 ${n} 位需要兒童椅`,
    'attendees_page.rsvp_history.historic.header': (n) => `第 ${n} 編輯`,
    'on_the_day.admin.comments.delete_confirmation': (name) => `確定要刪除 ${name} 的留言嗎？`,
    'gallery.admin.comments.delete_confirmation': (name) => `確定要刪除 ${name} 的留言嗎？`,
    'rsvp_form.submit_confirmation.line_1': (count) => {
        return count > 0
            ? `總共有 ${count} 位貴賓出席`
            : `沒有任何來賓會出席`
    }
})[key]

// for fixed translations
const dict = {
    'invitation_page.errors.code.invalid': '無法驗證此邀請代碼，請提供完整的代碼',
    'invitation_page.errors.code.blank': '請輸入您的邀請代碼',
    'invitation_page.opening_text.dear': '致',
    'invitation_page.opening_text.guests': '的家人及朋友們',
    'invitation_page.opening_text.text.line_1': '關於我們的婚禮，想先一睹為快嗎?',
    'invitation_page.opening_text.text.line_2': '邀請您輸入您的專屬邀請代碼',
    'invitation_page.reminders.invitation_code_location': '您專屬的邀請代碼，在喜帖信封內的小卡上',
    'invitation_page.reminders.personal_privacy':'為保護您的個人資訊，請您保管好您的專屬邀請代碼',
    'invitation_page.form.code': '在此輸入您的邀請代碼',
    'rsvp_form.labels.name': '*姓名（必填）',
    'rsvp_form.labels.email': 'Email',
    'rsvp_form.labels.mobile': '*手機號碼（必填）',
    'rsvp_form.labels.is_attending': '*您本人會參加囍宴嗎？(必填)',
    'rsvp_form.is_attending.options.yes': '會參加！',
    'rsvp_form.is_attending.options.no': '抱歉，當天沒辦法出席',
    'rsvp_form.placeholders.name': '請輸入您完整的姓名',
    'rsvp_form.placeholders.email': '請輸入您的電子郵箱',
    'rsvp_form.placeholders.mobile': '請輸入手機號碼（共10碼）',
    'rsvp_form.placeholders.is_attending': '您本人會參加囍宴嗎？',
    'rsvp_form.guests.description': '請於下方欄位填入除了您以外將出席結婚晚宴的賓客姓名。如需要兒童餐椅，也請點選"需要"兒童餐椅',
    'rsvp_form.guests.placeholders.name': '出席者完整姓名',
    'rsvp_form.guests.labels.child_seat': '兒童餐椅',
    'rsvp_form.guests.child_seat.options.yes': '需要',
    'rsvp_form.guests.child_seat.options.no': '不需要',
    'rsvp_form.guests.remove': '移除',
    'rsvp_form.guests.add': '增加來賓',
    'rsvp_form.submit': '上傳您的回覆',
    'rsvp_form.errors.name.blank': '請填寫您的大名',
    'rsvp_form.errors.email.blank': '請填寫您的電子郵箱',
    'rsvp_form.errors.contact_number.blank': '請填寫您的電話號碼',
    'rsvp_form.errors.is_attending.inclusion': '請選擇您本人是否能參加我們的囍宴',
    'rsvp_confirmation.guest.name': '姓名',
    'rsvp_confirmation.guest.email': '電子郵件',
    'rsvp_confirmation.guest.contact_number': '電話號碼',
    'rsvp_confirmation.guest.attendees': '出席賓客',
    'rsvp_confirmation.guest.requires_child_seat': '（需要兒童座椅）',
    'rsvp_confirmation.header': '感謝您的填寫！',
    'rsvp_confirmation.subheader': '您的回覆如下，請確認資訊是否都填寫正確',
    'rsvp_confirmation.footer': '囍宴回函內容若有更動 請直接與佳蓉 （Rita）或 軒懌（Eddy）聯絡',
    'rsvp_confirmation.button.view_gallery': '觀賞婚紗照',
    'rsvp_confirmation.other_todos': '接着，我們邀請您一起...',
    'rsvp_confirmation.guest.attendees.none': '無出席者',
    'rsvp_form.submit_confirmation.line_2': '這樣正確嗎?',
    'home_page.banner.button.view_gallery': '觀賞婚紗照',
    'home_page.banner.button.rsvp': '囍宴回函填寫 | R.S.V.P',
    'home_page.event_details.date': '日期 — 2022 年 11 月 25 日 (五)',
    'home_page.event_details.time': '時間 — 下午 6 點 30 分',
    'home_page.event_details.add_to_calendar': '請把這天留給我們',
    'home_page.event_details.address.line_1': '台北晶華酒店',
    'home_page.event_details.address.line_2': '4樓 萬象廳',
    'home_page.event_details.address.line_3': '台北市 中山區',
    'home_page.event_details.address.line_4': '中山北路二段39巷3號',
    'home_page.links.header': '關於我們的婚宴',
    'home_page.links.rsvp': '囍宴回函填寫',
    'home_page.links.rsvp.view_only': '查看囍宴回函',
    'home_page.links.view_gallery': '觀賞婚紗照',
    'home_page.links.attendees': '來賓列單',
    'home_page.links.projection': '播放投影',
    'home_page.links.manage_projected_comments': '投影留言',
    'home_page.links.manage_wedding_photographs_comments': '照片留言',
    'home_page.admin_tools.header': '管理工具',
    'home_page.footer': 'Rita & Eddy 用 ❤ 製作',
    'router.home_button': '首頁',
    'router.attendees_button': '貴賓列單',
    'router.back_button': '返回',
    'posts.rsvp_button': '婚宴出席調查 | R.S.V.P',
    'posts.view_gallery_button': '觀賞婚紗照',
    'gallery.description': '點擊圖片放大看！',
    'gallery.comments.count': '則留言',
    'gallery.comments.submit_button': '送出',
    'gallery.comments.placeholder': '請在此留言給我們',
    'gallery.comments.delete': '刪除',
    'gallery.comments.delete_confirmation': '確定要刪除這則留言嗎？',
    'gallery.comments.delete.error.base.unauthorised_access': '您沒有刪除這則留言的權限！',
    'confirmation_modal.confirm': '確定',
    'confirmation_modal.cancel': '取消',
    'attendees_page.fetch_attendees.error.base.unauthorised_access': '您沒有觀看賓客表的權限!',
    'attendees_page.table.title': '賓客表',
    'attendees_page.table.header.name': '名字',
    'attendees_page.table.header.is_attending': '會參加？',
    'attendees_page.table.header.is_child_seat_required': '兒童餐椅？',
    'attendees_page.table.header.contact': '聯絡人',
    'attendees_page.table.header.can_edit_rsvp': '編輯權限?',
    'attendees_page.table.row.is_attending.yes': '參加',
    'attendees_page.table.row.is_attending.no': '無法參加',
    'attendees_page.table.row.is_child_seat_required.yes': '需要',
    'attendees_page.table.row.is_child_seat_required.no': '不需要',
    'attendees_page.table.row.can_edit_rsvp.yes': '可編輯',
    'attendees_page.table.row.can_edit_rsvp.no': '不可編輯',
    'attendees_page.rsvp_history.historic.additional_guest.requires_child_seat': '，需要兒童椅',
    'attendees_page.rsvp_history.historic.labels.name': '名字',
    'attendees_page.rsvp_history.historic.labels.email': 'Email',
    'attendees_page.rsvp_history.historic.labels.contact_number': '聯絡號碼',
    'attendees_page.rsvp_history.historic.labels.is_attending': '本人參加？',
    'attendees_page.rsvp_history.historic.labels.additional_guests': '其他來賓',
    'attendees_page.rsvp_history.historic.labels.is_attending.yes': '會參加',
    'attendees_page.rsvp_history.historic.labels.is_attending.no': '無法參加',
    'attendees_page.rsvp_history.current.header': '目前的回覆',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp': '可編輯回函?',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp.yes': '可以',
    'attendees_page.rsvp_history.historic.labels.can_edit_rsvp.no': '不可以',
    'attendees_page.filters.show_absent_guests': '顯示缺席者',
    'attendees_page.edit_attendee_button.edit': '編輯來賓',
    'attendees_page.edit_attendee_button.close': '關閉編輯',
    'on_the_day.users.new_comment.submitted': '感謝您的留言！',
    'on_the_day.users.new_comment.placeholders.text': '請在此留言 ...',
    'on_the_day.users.new_comment.placeholders.commenter_name': '您的全名',
    'on_the_day.users.new_comment.submit_button': '送出',
    'on_the_day.users.new_comment.submit_uncaught_error': '哎呀！出了一點狀況',
    'on_the_day.users.new_comment.errors.text.blank': '請填寫留言',
    'on_the_day.users.new_comment.errors.commenter_name.blank': '請填寫您的全名',
    'on_the_day.users.photograph_selector.header': '還想對其他照片留言嗎？',
    'on_the_day.users.photograph_selector.subheader': '請任意點選以下婚紗照留言',
    'on_the_day.users.comments_section.empty_comments.line_1': '這張照片在等您留言',
    'on_the_day.users.comments_section.empty_comments.line_2': '我們邀請您以下隨意留幾句話',
    'on_the_day.users.comments_section.comments.header': '其他貴賓說 ...',
    'on_the_day.users.comments.delete_confirmation': '確定要刪除這則留言嗎？',
    'on_the_day.users.comments_section.comments.delete_button': '刪除',
    'on_the_day.users.comments_section.comments.delete_errors.base.unauthorised_access': '您沒有刪除這則留言的權限',
    'on_the_day.admin.comments.header.has_comments': '所有投影留言',
    'on_the_day.admin.comments.header.no_comments': '☹ 沒有留言 ☹',
    'on_the_day.admin.comments.delete_button': '刪除',
    'on_the_day.admin.comments.delete_errors.base.unauthorised_access': '您沒有刪除這則留言的權限',
    'delete_button': '刪除',
    'sign_out': '登出',
    'gallery.admin.comments_header': '照片留言',
    'gallery.admin.comments.delete_errors.base.unauthorised_access': '您沒有刪除這則留言的權限',
}

export default dict
