import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Translations from '../translations'
import { selectCurrentLanguageCode } from '../languages/ducks/selectors'
import Link from './Link'
import { redirectToWeddingPhotographsPage } from '../router/ducks/actions'
import RsvpConfirmationDetail from './RsvpConfirmationDetail'

const RsvpConfirmation = ({ weddingGuest, onViewWeddingPhotoGraphsClick }) => {
    const additionalGuests = weddingGuest.additionalGuests || []

    const attendees = weddingGuest.isAttending
        ? [{ name: weddingGuest.name, childSeatRequired: false }, ...additionalGuests]
        : additionalGuests

    const handleViewWeddingPhotographsClick = (e) => {
        e.preventDefault()
        onViewWeddingPhotoGraphsClick()
    }

    return (
        <div className="flex justify-center">
            <div className="text-secondary">
                <div className="text-xl md:text-center mb-8">
                    <p>{ Translations.get('rsvp_confirmation.header') }</p>
                    <p className="mt-4">{ Translations.get('rsvp_confirmation.subheader') }</p>
                </div>
                <RsvpConfirmationDetail
                    label={ Translations.get('rsvp_confirmation.guest.name') }
                    value={ weddingGuest.name } />
                <RsvpConfirmationDetail
                    label={ Translations.get('rsvp_confirmation.guest.email') }
                    value={ weddingGuest.email } />
                <RsvpConfirmationDetail
                    label={ Translations.get('rsvp_confirmation.guest.contact_number') }
                    value={ weddingGuest.contactNumber } />
                <RsvpConfirmationDetail
                    label={ Translations.get('rsvp_confirmation.guest.attendees') }>
                    <div className="block md:inline-block md:pl-2 align-top">
                        { attendees && attendees.length > 0
                            ?
                            attendees && attendees.map((guest, i) =>
                                <div key={i} className="mb-2 text-white">
                                    <span className="mr-2">{i+1}.</span>
                                    <span>
                                        {guest.name} {guest.childSeatRequired && Translations.get('rsvp_confirmation.guest.requires_child_seat')}
                                    </span>
                                </div>
                            )
                            :
                            <div className="text-white">
                                { Translations.get('rsvp_confirmation.guest.attendees.none') }
                            </div>
                        }
                    </div>
                </RsvpConfirmationDetail>

                <div className="text-center mt-4">
                    <hr className="mt-12 mb-6 border-secondary" />
                    <p className="mb-4">
                        { Translations.get('rsvp_confirmation.other_todos') }
                    </p>
                    <Link text={ Translations.get('rsvp_confirmation.button.view_gallery') }
                        onClick={ handleViewWeddingPhotographsClick } />
                </div>
            </div>
        </div>
    )
}

RsvpConfirmation.propTypes = {
    weddingGuest: PropTypes.object.isRequired,
    onViewWeddingPhotoGraphsClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    weddingGuest: state.rsvp.editWeddingGuest,
    // force app refresh upon language change
    currentLanguageCode: selectCurrentLanguageCode(state)
})

const mapDispatchToProps = (dispatch) => ({
    onViewWeddingPhotoGraphsClick: () => dispatch(redirectToWeddingPhotographsPage())
})

export default connect(mapStateToProps, mapDispatchToProps)(RsvpConfirmation)
